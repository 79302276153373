"use client";

import React from "react";
import {
  Button,
  CustomSelect,
  ProgressBar,
  Ratings,
} from "@/components/core-components";
import Image from "next/image";
import { formatDateTime, IReview, usePagination } from "@/utils";
import moment from "moment";
import parse from "html-react-parser";
import { useAppSelector } from "@/store";

const Comment = ({
  name,
  since,
  rating,
  comment,
  verified = false,
  contents,
  created_at,
  setModal,
}: {
  name: string;
  since: string;
  rating: number;
  comment: string;
  verified: boolean;
  created_at: string;
  contents: {
    media_url: string;
    media_type: string;
  }[];
  setModal: React.Dispatch<
    React.SetStateAction<{
      state: boolean;
      content: {
        media_url: string;
        media_type: string;
      };
    }>
  >;
}) => {
  return (
    <div className="single-comment justify-content-between d-flex">
      <div className="user justify-content-between d-flex">
        <div className="thumb text-left">
          <h6 className="text-primary custom-pointer">{name}</h6>
          {verified && (
            <span className="stock-status out-stock bg-green rounded-2 p-0 px-1 text-white mt-2">
              Verified
            </span>
          )}
          <p className="font-xxs">{`Since ${moment(since).get("year")}`}</p>
        </div>
        <div className="desc">
          <Ratings rating={rating} />
          <p>{comment}</p>
          <div className="d-flex justify-content-between mb-10">
            <div className="d-flex align-items-center">
              <p className="font-xs mr-30">{formatDateTime(created_at)}</p>
            </div>
          </div>

          {contents && contents.length > 0 ? (
            <div className="d-flex flex-wrap">
              {contents.map((content, index) => (
                <div
                  key={index}
                  className="review-media-div mr-10"
                  onClick={() =>
                    setModal({
                      state: true,
                      content: content,
                    })
                  }
                >
                  {content.media_type === "image" ? (
                    <Image
                      width={100}
                      height={100}
                      className="h-100 w-100"
                      src={content.media_url}
                      alt={`Thumbnail ${index}`}
                    />
                  ) : (
                    <video>
                      <source src={content.media_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const ReviewList = ({
  reviews,
  reviewSection,
  setReviewSection,
  setModal,
}: {
  reviews: any;
  reviewSection: boolean;

  setReviewSection: React.Dispatch<React.SetStateAction<boolean>>;
  setModal: React.Dispatch<
    React.SetStateAction<{
      state: boolean;
      content: {
        media_url: string;
        media_type: string;
      };
    }>
  >;
}) => {
  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const is_logged_in = logged_in_user || access_token;

  const { additionalFilter, searchParams } = usePagination();

  return (
    <div className="comments-area pb-0">
      <div className="row">
        <div className="col-lg-8 pb-30">
          {reviews?.length > 0 && (
            <div className="shop-product-fillter">
              <div className="sort-by-product-area">
                <div className="sort-by-cover">
                  <CustomSelect
                    name="filterBy"
                    formValue={searchParams.get("rating") || "all"}
                    options={[
                      { value: "all", label: "All" },
                      { value: "5", label: "5 Star" },
                      { value: "4", label: "4 Star" },
                      { value: "3", label: "3 Star" },
                      { value: "2", label: "2 Star" },
                      { value: "1", label: "1 Star" },
                    ]}
                    inline={{
                      title: "Filter by",
                      icon: "ti-angle-down",
                    }}
                    onChange={(e: any) => {
                      if (e.target.value.toString() === "all") {
                        additionalFilter("rating");
                      } else {
                        additionalFilter("rating", e.target.value.toString());
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="comment-list">
            {reviews &&
              reviews?.length > 0 &&
              reviews?.map((review: IReview, index: number) => (
                <Comment
                  key={index}
                  name={review.customer_details.name?.split(" ")?.[0]}
                  created_at={review.created_at}
                  since={review.customer_details.created_at}
                  rating={review.rating}
                  comment={review.review_comment}
                  contents={review.review_media || []}
                  verified={review.is_verified}
                  setModal={setModal}
                />
              ))}
            {reviews?.length > 10 && (
              <Button
                className="btn btn-sm mr-10"
                loading={false}
                text={"See More Reviews"}
                type="button"
              />
            )}
            {is_logged_in && (
              <Button
                className="btn btn-sm"
                onClick={() => setReviewSection(!reviewSection)}
                loading={false}
                text={reviewSection ? "Exit" : "Write a Review"}
                type="button"
              />
            )}
          </div>
        </div>
        {/* <div className="col-lg-4 pb-30">
          <div className="d-flex align-items-center mb-30">
            <Ratings rating={4} />
            <h6 className="ml-5">4 out of 5</h6>
          </div>
          <ProgressBar label="5 star" width={85} />
          <ProgressBar label="4 star" width={45} />
          <ProgressBar label="3 star" width={25} />
          <ProgressBar label="2 star" width={20} />
          <ProgressBar label="1 star" width={15} />
        </div> */}
      </div>
    </div>
  );
};

export { ReviewList };
