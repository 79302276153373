import React from "react";

const Loader = () => {
  return (
    <span
      data-testid="loader"
      className={`spinner-border spinner-border-sm mr-2`}
    ></span>
  );
};

export { Loader };
