import { combineReducers } from "redux";
import commonSlice from "./commonSlice";
import accountSlice from "./accountSlice";
import cartSlice from "./cartSlice";
import wishlistSlice from "./wishlistSlice";
import authSlice from "./authSlice";
import locationSlice from "./locationSlice";
import orderSlice from "./orderSlice";
import addressSlice from "./addressSlice";

const rootReducer = combineReducers({
  common: commonSlice,
  account: accountSlice,
  cart: cartSlice,
  wishlist: wishlistSlice,
  auth: authSlice,
  location: locationSlice,
  order: orderSlice,
  address: addressSlice,
});

export { rootReducer };
