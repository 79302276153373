import { GET_AddressType } from "@/utils";
import React from "react";
import { AddAddressCard, AddressCard } from "./AddressCard";

const AddressList = ({ addresses }: { addresses: GET_AddressType[] }) => {
  return (
    <div className="tab-pane fade active show">
      <div className="row">
        <AddAddressCard />

        {addresses?.length > 0 &&
          addresses?.map((address, index) => (
            <AddressCard key={index} address={address} />
          ))}
      </div>
    </div>
  );
};

export { AddressList };
