"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";

const BannerSlide = ({
  item,
  index,
  widgetIndex,
  category = false,
}: {
  item: any;
  index: number;
  widgetIndex: number;
  category?: boolean;
}) => {
  return (
    <div
      className={`single-hero-slider ${
        !category && `rectangle slider-content-center`
      } single-animation-wrap`}
    >
      {item.link ? (
        <Link href={`/${item.link}`}>
          <Image
            className="w-100 h-100"
            alt={item?.alt || ""}
            height={600}
            width={1600}
            src={item.img}
            loading="eager"
            priority={widgetIndex <= 2}
          />
        </Link>
      ) : (
        <Image
          className="w-100 h-100"
          alt={item?.alt || ""}
          height={600}
          width={1600}
          src={item.img}
          loading="eager"
          priority={widgetIndex <= 2}
        />
      )}
    </div>
  );
};

export { BannerSlide };
