import {
  apiRequestClient,
  capitalizeFirstLetter,
  constructSlug,
  customRevalidateTag,
  formatProduct,
  IAddReview,
  IDBProduct,
  jsonDataToFormData,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { FormikHelpers } from "formik";

const initialState = {
  quickView: {
    state: false,
    data: null,
    type: "",
  },
  offCanvas: {
    state: false,
    component: null as string | null,
  },
  state: {
    status: STATUSES.IDLE as string,
    type: null as null | string,
  },
  carouselData: {} as any,
  filteredProducts: [] as any,
  filter: {
    sort_by: "all",
    limit: 12,
    is_jain: 0,
    page: 1,
    query: "",
    taste: "",
  },
  listingProducts: {} as any,
  controller: null as any,
  toggleMobileMenu: false,
  header_menu: null,
  footer_menu: null,
  header: null as any,
  footer: null as any,
  maxPageLimit: 5,
  minPageLimit: 1,
  totalPages: 0,
  count: 0,
  placedOrderId: null,
};

export const fetchMenuData = createAsyncThunk(
  "common/fetchMenuData",
  async ({
    header_menu,
    footer_menu,
  }: {
    header_menu?: number;
    footer_menu?: number;
  }) => {
    const response = await fetch(
      `/api/menu?menu=${header_menu || footer_menu}${
        header_menu ? "&category=1" : ""
      }`
    );

    return response.json();
  }
);

export const fetchFilteredProducts = createAsyncThunk(
  "common/fetchFilteredProducts",
  async (
    {
      filters,
      url,
      search = false,
    }: {
      filters: {
        sort_by: string;
        limit: number;
        is_jain: number;
        page: number;
        query: string;
        taste: string;
      };
      url: String;
      search?: boolean;
    },
    { dispatch, getState }
  ) => {
    const {
      common: { controller: abortController },
    } = getState() as RootState;

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    const containsCategorySubCategory =
      url.includes("category") || url.includes("subcategory");

    const endpoint = search
      ? `${url}&page=${filters.page}${filters.is_jain ? `&jain=1` : ""}&limit=${
          filters.limit || 12
        }`
      : `${STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL}${url}${
          containsCategorySubCategory ? "&" : "?"
        }page=${filters.page}${filters.query ? `&query=${filters.query}` : ""}${
          filters.is_jain ? `&jain=1` : ""
        }&limit=${filters.limit || 12}${
          filters.sort_by !== "all" ? `&sort_by=${filters.sort_by}` : ""
        }${filters.taste ? `&taste=${filters.taste}` : ""}`;

    const response = await fetch(endpoint, {
      method: REQUEST_METHOD.GET,
      headers: {
        "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
      },
      signal: controller.signal,
    }).then((res) => res.json());

    return response;
  }
);

export const setListingProductsFromWidget = createAsyncThunk(
  "common/setListingProductsFromWidget",
  async (
    { data, type }: { data: any[]; type: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      return { data: data, type: type };
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

export const fetchInitialCarouselData = createAsyncThunk(
  "common/fetchInitialCarouselData",
  async ({
    data,
  }: {
    data: {
      url: string;
      type: string;
      page: number;
    }[];
  }) => {
    const temp: { [key: string]: any } = {};

    await Promise.all(
      data.map(async (item) => {
        const response = await fetch(
          `${STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL}${item.url}&page=${item.page}`,
          {
            method: REQUEST_METHOD.GET,
            headers: {
              "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
            },
          }
        ).then((res) => res.json());

        const data = item.url.includes("subcategory")
          ? response.result.results.map((sub_category: any) => ({
              title: sub_category.display_name,
              img: sub_category.icon,
              desc: sub_category.short_summary,
              link: constructSlug([
                sub_category.product_category.meta_slug || "",
                sub_category.meta_slug,
              ]),
              alt: sub_category.icon_alt,
              hasDesc: true,
            }))
          : response.result.results.map((product: IDBProduct) =>
              formatProduct(product)
            );

        temp[item.type] = {
          data: data,
          count: response.result.count,
          page: item.page,
        };
      })
    );

    return temp;
  }
);

export const fetchCarouselData = createAsyncThunk(
  "common/fetchCarouselData",
  async (
    {
      url,
      type,
      page,
      onSuccess,
    }: {
      url: string;
      type: string;
      page: number;
      onSuccess: (index: number) => void;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: type,
      })
    );
    const {
      common: { carouselData },
    } = getState() as RootState;

    let temp = JSON.parse(JSON.stringify(carouselData));

    try {
      const response = await fetch(`${url}&page=${page}`, {
        method: REQUEST_METHOD.GET,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
      }).then((res) => res.json());

      if (Object.keys(response).length === 1) {
        dispatch(
          setCommonState({
            status: STATUSES.ERROR,
            type: type,
          })
        );

        return carouselData;
      }

      const data = url.includes("subcategory")
        ? response.result.results.map((sub_category: any) => ({
            title: sub_category.display_name,
            img: sub_category.icon,
            desc: sub_category.short_summary,
            link: constructSlug([
              sub_category.product_category.meta_slug || "",
              sub_category.meta_slug,
            ]),
            alt: sub_category.icon_alt,
            hasDesc: true,
          }))
        : response.result.results.map((product: IDBProduct) =>
            formatProduct(product)
          );

      const currentCarouselData = temp[type];

      if (currentCarouselData !== undefined) {
        temp = {
          ...temp,
          [type]: {
            data: [...temp[type].data, ...data],
            count: response.result.count,
            page: page,
          },
        };
      } else {
        Object.assign(temp, {
          [type]: {
            data: data,
            count: response.result.count,
            page: 1,
          },
        });
      }

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: type,
        })
      );

      onSuccess &&
        onSuccess(
          (currentCarouselData !== undefined ? temp[type].page - 1 : 1) * 6 - 5
        );

      return temp;
    } catch (error: any) {
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: type,
        })
      );
      rejectWithValue(error.message);
    }
  }
);

export const contactUs = createAsyncThunk(
  "common/contactUs",
  async (
    {
      params,
      actions,
    }: {
      params: {
        name: string;
        email: string;
        contact_number: number;
        subject: string;
        message: string;
      };
      actions: FormikHelpers<any>;
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setCommonState({ status: STATUSES.LOADING, type: "contact-us" }));
    const response = await fetch("/api/contact_us/", {
      method: REQUEST_METHOD.POST,
      headers: {
        "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
      },
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .catch((err: any) => {
        dispatch(
          setCommonState({ status: STATUSES.ERROR, type: "contact-us" })
        );
        rejectWithValue(err.message);
      });

    dispatch(setCommonState({ status: STATUSES.IDLE, type: "contact-us" }));

    return response;
  }
);

export const addReview = createAsyncThunk(
  "common/addReview",
  async (
    {
      params,
      actions,
      setReview,
    }: {
      params: IAddReview;
      actions: FormikHelpers<IAddReview>;
      setReview: React.Dispatch<React.SetStateAction<boolean>>;
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setCommonState({ status: STATUSES.LOADING, type: "add-review" }));

    try {
      const response = await apiRequestClient({
        url: "/api/reviews/",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data: params,
      });

      actions.resetForm();

      setReview(false);

      dispatch(setCommonState({ status: STATUSES.IDLE, type: "add-review" }));

      return response;
    } catch (error: any) {
      dispatch(setCommonState({ status: STATUSES.ERROR, type: "add-review" }));
      rejectWithValue(error.message);
    } finally {
      customRevalidateTag("reviews");
    }
  }
);

export const uploadImage = createAsyncThunk(
  "common/uploadImage",
  async (
    {
      params,
      updatePropsValues,
      setUrl,
    }: {
      params: {
        folder_name: string;
        file_name: string;
        file?: File;
      };
      updatePropsValues?: (value: any) => void;
      setUrl?: React.Dispatch<React.SetStateAction<string>>;
    },
    { dispatch }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "upload-image",
      })
    );
    setUrl && setUrl("");
    try {
      const response: any = await fetch(`/api/upload/`, {
        method: REQUEST_METHOD.POST,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.FORM_DATA,
        },
        body: JSON.stringify({
          folder_name: params.folder_name,
          file_name: params.file_name,
        }),
      }).then((res) => res.json());

      setUrl && setUrl(response.url);

      if (params.file) {
        const formData: any = jsonDataToFormData(
          Object.assign({ ...response.fields }, { file: params.file })
        );

        try {
          const img_response = await fetch(response.url, {
            method: REQUEST_METHOD.POST,
            body: formData,
            headers: {
              "Content-Type": REQUEST_CONTENT_TYPE.FORM_DATA,
            },
          });

          updatePropsValues &&
            updatePropsValues(
              encodeURI(
                `${response.url}${params.folder_name}/${params.file_name}`
              )
            );

          dispatch(
            setCommonState({
              status: STATUSES.IDLE,
              type: "upload-image",
            })
          );
        } catch (error: any) {
          dispatch(
            setCommonState({
              status: STATUSES.ERROR,
              type: "upload-image",
            })
          );
          updatePropsValues && updatePropsValues("");
        }
      }
    } catch (error: any) {
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "upload-image",
        })
      );
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetCarouselData: (state) => {
      state.carouselData = {};
    },

    resetListingProducts: (state) => {
      state.listingProducts = {};
    },

    setControllerState: (state, action) => {
      state.controller = action.payload;
    },

    setFilteredProducts: (state, action) => {
      state.filteredProducts = action.payload;
    },

    setFilters: (
      state,
      action: {
        payload: {
          sort_by: string;
          limit: number;
          is_jain: number;
          page: number;
          query: string;
          taste: string;
        };
      }
    ) => {
      state.filter = action.payload;
    },
    resetFilters: (state) => {
      state.filter = {
        sort_by: "all",
        limit: 12,
        is_jain: 0,
        page: 1,
        query: "",
        taste: "",
      };
    },
    setPlacedOrderId: (state, action) => {
      state.placedOrderId = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setMinPageLimitState: (state, action) => {
      state.minPageLimit = action.payload;
    },
    setMaxPageLimitState: (state, action) => {
      state.maxPageLimit = action.payload;
    },
    setHeaderMenu: (state, action) => {
      state.header_menu = action.payload;
    },
    setFooterMenu: (state, action) => {
      state.footer_menu = action.payload;
    },
    setCommonState: (
      state,
      action: {
        payload: {
          status: string;
          type: string;
        };
      }
    ) => {
      state.state = action.payload;
    },
    toggleMobileMenuState: (state) => {
      state.toggleMobileMenu = !state.toggleMobileMenu;
    },
    setOffCanvas: (
      state,
      action: {
        payload: {
          state: boolean;
          component: string | null;
        };
      }
    ) => {
      state.offCanvas = action.payload;
    },
    setQuickView: (
      state,
      action: {
        payload: {
          state: boolean;
          data: any;
          type: string;
        };
      }
    ) => {
      state.quickView = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInitialCarouselData.fulfilled, (state, action) => {
        state.carouselData = action.payload;
      })
      .addCase(fetchCarouselData.fulfilled, (state, action) => {
        state.carouselData = action.payload;
      })
      .addCase(setListingProductsFromWidget.fulfilled, (state, action: any) => {
        state.listingProducts[action.payload.type] = action.payload.data;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        Toast(
          `${capitalizeFirstLetter(action.payload.message)}`,
          TOAST_CONSTANTS.SUCCESS
        );
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        if (action.payload) {
          Toast(
            `${capitalizeFirstLetter(action.payload.message)}`,
            TOAST_CONSTANTS.SUCCESS
          );
          action.meta.arg.actions.resetForm();
        }
      })
      .addCase(
        fetchMenuData.fulfilled,
        (
          state,
          action: {
            payload: any;
          }
        ) => {
          if (action.payload.result.category?.length > 0) {
            state.header = action.payload.result;
            state.header_menu = action.payload.result.id;
          } else {
            state.footer = action.payload.result;
            state.footer_menu = action.payload.result.id;
          }
        }
      )
      .addCase(fetchFilteredProducts.fulfilled, (state, action) => {
        state.filteredProducts = action.payload.result.results.map(
          (item: IDBProduct) => formatProduct(item)
        );
        state.totalPages = Math.ceil(
          action.payload.result.count / action.payload.result.page_size
        );
        state.count = action.payload.result.count;
      });
  },
});

export const {
  resetCarouselData,

  resetListingProducts,

  setControllerState,

  setFilteredProducts,
  setFilters,
  resetFilters,

  setPlacedOrderId,

  setTotalPages,
  setMinPageLimitState,
  setMaxPageLimitState,

  setCommonState,

  setHeaderMenu,
  setFooterMenu,

  toggleMobileMenuState,

  setOffCanvas,
  setQuickView,
} = commonSlice.actions;

export default commonSlice.reducer;
