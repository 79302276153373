"use client";

import { BannerItemProps, ProductHamperDealProps } from "@/utils";
import Image from "next/image";
import parse from "html-react-parser";
import { Button } from "@/components/core-components";
import { useRouter } from "next/navigation";

const ProductMultipleHamperDeal = ({
  data,
  widgetIndex,
}: {
  data: {
    data: ProductHamperDealProps[];
    main_title: string;
    main_sub_title?: string;
    main_description: string;
  };
  widgetIndex: number;
}) => {
  const router = useRouter();

  return (
    <section className="pt-30 bg-primary-2">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="row clear mb-x">
              <div className="col-lg-5 mb-30">
                <div className="position-relative col-lg-8 col-md-10 mb-30">
                  <Image
                    src={data?.data?.[0]?.img}
                    width={350}
                    height={500}
                    alt={data.data[0].title}
                    priority={widgetIndex <= 2}
                    className="h-100 w-100 img-fluid border-radius-0 mb-md-0 mb-lg-0 mb-sm-0"
                  />
                  <div className="overlay d-flex flex-column justify-content-center">
                    <h4 className="mb-20">{parse(data.data[0].title)}</h4>
                    <h6 className="mb-40">
                      {parse(data.data[0].description || "")}
                    </h6>
                    <Button
                      className="btn btn-lg bg-green font-xl text-white"
                      text={data.data[0].btnText}
                      type={"button"}
                      loading={false}
                      onClick={() => router.push(data.data[0].link || "/")}
                    />
                  </div>
                </div>

                <div className="position-relative offer-card-3">
                  <Image
                    width={500}
                    height={350}
                    src={data?.data?.[1]?.img}
                    alt={data.data[1].title}
                    priority={widgetIndex <= 2}
                    className="w-100 h-100 img-fluid border-radius-0 mb-md-0 mb-lg-0 mb-sm-0"
                  />
                  <div className="overlay d-flex flex-column justify-content-center">
                    <h4 className="mb-20">{parse(data.data[1].title)}</h4>
                    <h6 className="mb-40">
                      {parse(data.data[1].description || "")}
                    </h6>
                    <Button
                      className="btn btn-lg bg-green font-xl text-white"
                      text={data.data[1].btnText}
                      type={"button"}
                      loading={false}
                      onClick={() => router.push(data.data[1].link || "/")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="pl-25">
                  {data.main_sub_title && (
                    <h5 className="mb-30 text-primary">
                      {data.main_sub_title}
                    </h5>
                  )}
                  <h2 className="mb-30 text-dark">{data.main_title}</h2>
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-12">
                      <div className="position-relative offer-card-4 mb-30">
                        <Image
                          width={250}
                          height={350}
                          src={data?.data?.[2]?.img}
                          alt={data.data[2].title}
                          priority={widgetIndex <= 2}
                          className="h-100 w-100img-fluid border-radius-0 mb-md-0 mb-lg-0 mb-sm-0"
                        />
                        <div className="overlay d-flex flex-column justify-content-center">
                          <h4 className="mb-20">{parse(data.data[2].title)}</h4>
                          <h6 className="mb-40">
                            {parse(data.data[2].description || "")}
                          </h6>
                          <Button
                            className="btn btn-lg bg-green font-xl text-white"
                            text={data.data[2].btnText}
                            type={"button"}
                            loading={false}
                            onClick={() =>
                              router.push(data.data[2].link || "/")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12 pl-50">
                      <div className="mb-25 font-md line-height-2 ">
                        {data.main_description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProductHamperDeal = ({
  data,
  widgetIndex,
}: {
  data: BannerItemProps;
  widgetIndex: number;
}) => {
  const router = useRouter();

  return (
    <>
      <section className="section-padding-12 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 m-auto">
              <div className="row mb-x">
                {/* <div className="col-lg-4">
                <div className="position-relative">
                  <Image
                    width={500}
                    height={500}
                    src={data?.img}
                    alt={data.title}
                    priority={widgetIndex <= 2}
                    className="w-100 h-100 img-fluid mb-md-0 mb-lg-0 mb-sm-0"
                  />
                  <div className="overlay d-flex flex-column justify-content-center">
                    <h4 className="mb-20">{parse(data.title)}</h4>
                    <h6 className="mb-40">{parse(data.description || "")}</h6>
                    <Button
                      className="btn btn-lg bg-green font-xl text-white"
                      text={data.btnText}
                      type={"button"}
                      loading={false}
                      onClick={() => router.push(data.link || "/")}
                    />
                  </div>
                </div>
              </div> */}
                <div className="col-lg-12">
                  <div className="pl-lg-25 pl-md-0 pl-sm-0 pl-0">
                    <h1 className="category-title mb-10 text-primary">
                      {parse(data.main_title || "")}
                    </h1>
                    <div className="font-sm line-height-1-5">
                      {parse(data.main_description || "")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={`section-border-top`} />
    </>
  );
};

export { ProductHamperDeal, ProductMultipleHamperDeal };
