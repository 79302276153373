"use client";

import { Breadcrumb } from "@/components/core-components";
import { usePathname } from "next/navigation";
import React from "react";

const CategoryHeader = () => {
  return (
    <Breadcrumb
      homeElement={"Home"}
      separator={">"}
      activeClasses="text-primary"
      containerClasses="container"
      listClasses="breadcrumb px-2"
      capitalizeLinks
      description="Salty snacks are always found just around the corner, all over the country. You can order yours from Avarya. Namkeens ranging from bhel, chivda, ghatiya of various styles and specialties are found here."
      detailed={true}
    />
  );
};

export { CategoryHeader };
