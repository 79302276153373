import {
  apiRequestClient,
  calculateAllValues,
  formatProductForOrder,
  IOrderDetail,
  printBill,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setCommonState } from "./commonSlice";

const initialState = {
  successfulOrder: {} as IOrderDetail,
  shippingCost: 0,
  lastSelectedAddress: 0,
  status: STATUSES.IDLE,
  proceedToCheckout: false,
  error: null as null | string,
};

export const fetchSuccessfulOrder = createAsyncThunk(
  "order/fetchSuccessfulOrder",
  async ({ order_id }: { order_id: number }, { rejectWithValue }) => {
    const response = await fetch(`/api/order/${order_id}/`, {
      method: REQUEST_METHOD.GET,
      headers: {
        "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
      },
    }).then((res) => res.json());

    return {
      ...response.result,
      ordered_products: response.result.ordered_products.map((item: any) => ({
        ...item,
        product: {
          ...formatProductForOrder(item.product),
          selected_weight: item.product.product_weights?.find(
            (weight: any) => weight.id === item.product_weight
          ).weight,
        },
      })),
    };
  }
);

export const printOrderInvoice = createAsyncThunk(
  "order/printOrderInvoice",
  async ({ order_id }: { order_id: number }, { rejectWithValue }) => {
    const response = await fetch(`/api/order/print/${order_id}/`, {
      method: REQUEST_METHOD.GET,
      headers: {
        "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
      },
    }).then((res) => res.json());

    printBill(response);
  }
);

export const fetchShippingCost = createAsyncThunk(
  "order/fetchShippingCost",
  async (
    { cartItems }: { cartItems: any },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "shipping-cost",
      })
    );
    // const {
    //   cart: { cartItems },
    // } = getState() as RootState;

    const { total_weight } = calculateAllValues(cartItems);

    try {
      const response = await apiRequestClient({
        url: `/api/order/shipping-cost/?w=${total_weight}`,
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
      });

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "shipping-cost",
        })
      );
      return response;
    } catch (error: any) {
      rejectWithValue(error.message);
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "shipping-cost",
        })
      );
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setLastSelectedAddress: (state, action) => {
      state.lastSelectedAddress = action.payload;
    },
    setShippingCost: (state, action) => {
      state.shippingCost = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSuccessfulOrder.fulfilled, (state, action) => {
        state.successfulOrder = action.payload;
      })
      .addCase(fetchShippingCost.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.shippingCost = action.payload.result.price;
        } else {
          Toast(
            "Error occured while fetching shipping cost",
            TOAST_CONSTANTS.ERROR
          );
          state.proceedToCheckout = true;
        }
      })
      .addMatcher(
        isAnyOf(printOrderInvoice.pending, fetchSuccessfulOrder.pending),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(printOrderInvoice.fulfilled, fetchSuccessfulOrder.fulfilled),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      );
  },
});

export const { setLastSelectedAddress, setShippingCost } = orderSlice.actions;

export default orderSlice.reducer;
