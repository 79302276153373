"use client";

import { deleteAddress, setAsDefaultAddress, useAppDispatch } from "@/store";
import { customRevalidateTag, GET_AddressType } from "@/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const AddAddressCard = () => {
  return (
    <div className="col-lg-4 col-md-6 mb-20">
      <Link
        href="/account/addresses/add"
        className="featured-card address-card border-dashed d-flex flex-column justify-content-center text-center mb-3 mb-lg-0 p-10"
      >
        <div>
          <Image
            width={100}
            height={100}
            src="/assets/imgs/theme/icons/icon-plus-small.svg"
            alt="Add Address"
            className="mb-0"
          />
          <h4 className="mb-10">Add Address</h4>
        </div>
      </Link>
    </div>
  );
};

const AddressCard = ({ address }: { address: GET_AddressType }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="col-lg-4 col-md-6 mb-20">
      <div className="featured-card address-card mb-3 mb-lg-0 p-10">
        <div className="card-body">
          <div>
            <h6 className="mb-5">
              {`${address.first_name} ${address.last_name}`}
              {address.set_as_default !== 0 && (
                <span className="btn btn-xs bg-sky-blue pt-5 pb-5 pl-10 pr-10 ml-10 border-radius-15">
                  Default
                </span>
              )}
            </h6>
            <address>{`${address.address_line_1} ${address.address_line_2} ${address.landmark}`}</address>
            <p className="font-xs mb-0">
              {`${address.city.city}, ${address.state.state}, ${address.pincode} , ${address.country.country}`}
            </p>
            <p className="font-xs">Phone Number: {address.contact_number}</p>
          </div>
          <div>
            <Link
              href="/edit"
              as={`/account/addresses/edit/${address.id}`}
              className="btn-small"
            >
              Edit
            </Link>
            <span className="pl-10 pr-10 text-primary font-lg">|</span>
            <Link
              href="#"
              className="btn-small"
              onClick={() => {
                dispatch(deleteAddress({ address_id: address?.id || 0 }));
              }}
            >
              Remove
            </Link>
            {!address.set_as_default && (
              <>
                <span className="pl-10 pr-10 text-primary font-lg">|</span>
                <Link
                  href="#"
                  className={`btn-small ${
                    address.set_as_default && "text-muted"
                  }`}
                  onClick={() => {
                    address?.id &&
                      dispatch(
                        setAsDefaultAddress({
                          data: {
                            address_id: address?.id,
                          },
                        })
                      );
                  }}
                >
                  Set as Default
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const AddresscardShimmer = () => {
  return (
    <div className="col-lg-4 col-md-6 mb-20">
      <div className="featured-card address-card mb-3 mb-lg-0 p-10">
        <div className="card-body">
          <div>
            <h6 className="mb-10">
              <span
                className="skeleton skeleton-text"
                style={{ width: "100px" }}
              ></span>
            </h6>
            <address>
              <span
                className="skeleton skeleton-text"
                style={{ display: "block", width: "100%" }}
              ></span>
            </address>
            <p className="font-xs mb-0">
              <span
                className="skeleton skeleton-text"
                style={{ width: "150px" }}
              ></span>
            </p>
            <p className="font-xs">
              <span
                className="skeleton skeleton-text"
                style={{ width: "100px" }}
              ></span>
            </p>
          </div>
          <div>
            <span
              className="skeleton skeleton-text"
              style={{ width: "100px" }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddAddressCard, AddressCard, AddresscardShimmer };
