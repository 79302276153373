"use client";

import { useClickOutside, usePagination } from "@/utils";
import Link from "next/link";
import { useRef, useState, memo } from "react";
import { Button } from "./core-components";
import { toggleMobileMenuState, useAppDispatch, useAppSelector } from "@/store";
import Image from "next/image";
import { useRouter } from "next/navigation";

type MenuItem = {
  key?: number;
  label: string;
  link?: string;
  subItems?: MenuItem[];
};

type MenuData = MenuItem[];

const MenuItemComponent = ({
  initialOpen,
  item,
  activeKeys,
  toggleClick,
  handleToggle,
  handleSubToggle,
  subActiveKeys,
}: any) => {
  return (
    <li
      className={
        activeKeys[item.id]
          ? "menu-item-has-children active"
          : "menu-item-has-children"
      }
    >
      {item.children.length > 0 || item.is_mega_menu ? (
        <>
          <span className="menu-expand" onClick={() => handleToggle(item.id)}>
            <i className="fi-rs-angle-small-down"></i>
          </span>
          <span className="link-span">{item.menu_item_title}</span>
          <ul
            className={
              initialOpen === item.id || activeKeys[item.id]
                ? "dropdown"
                : "d-none"
            }
          >
            {(item.is_mega_menu ? item.mega_menu : item.children).map(
              (subItem: any) => {
                return (
                  <SubMenuItemComponent
                    key={subItem.id || subItem.menu_item_title}
                    parentKey={item.id}
                    toggleclick={toggleClick}
                    subItem={subItem}
                    subActiveKeys={subActiveKeys}
                    handleSubToggle={handleSubToggle}
                  />
                );
              }
            )}
          </ul>
        </>
      ) : (
        <Link href={item.slug || ""} onClick={() => toggleClick()}>
          {item.menu_item_title}
        </Link>
      )}
    </li>
  );
};

const SubMenuItemComponent = ({
  parentKey,
  toggleClick,
  subItem,
  subActiveKeys,
  handleSubToggle,
}: any) => {
  return (
    <li>
      {subItem.children.length > 0 ? (
        <>
          <span
            className="menu-expand"
            onClick={() => handleSubToggle(parentKey, subItem.id)}
          >
            <i className="fi-rs-angle-small-down"></i>
          </span>
          <Link href={`/${subItem.slug}`} onClick={() => toggleClick()}>
            {subItem.menu_item_title}
          </Link>
          <ul
            className={
              subActiveKeys[parentKey]?.[subItem.id] ? "dropdown" : "d-none"
            }
          >
            {subItem.children.map((nestedSubItem: any) => (
              <li key={nestedSubItem.menu_item_title}>
                <Link
                  href={`/${nestedSubItem.slug}`}
                  onClick={() => toggleClick()}
                >
                  {nestedSubItem.menu_item_title}
                </Link>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <Link href={`/${subItem.slug}`} onClick={() => toggleClick()}>
          {subItem.label}
        </Link>
      )}
    </li>
  );
};

const MobileMenu = ({ data }: { data?: any }) => {
  const dispatch = useAppDispatch();

  const { toggleMobileMenu } = useAppSelector((state) => state.common);

  const [activeKeys, setActiveKeys] = useState<{ [key: number]: boolean }>({
    [data[0]?.id]: data[0]?.is_mega_menu === 1,
  });

  const [subActiveKeys, setSubActiveKeys] = useState<{
    [key: number]: { [key: number]: boolean };
  }>({});

  const menuRef = useRef<HTMLUListElement | null>(null);

  const toggleClick = () => {
    dispatch(toggleMobileMenuState());
    document
      .querySelector("body")
      ?.classList.toggle("mobile-menu-active", toggleMobileMenu);
  };

  // const [searchTerm, setSearchTerm] = useState("");

  // const { updateSearch, handleEnterClick, searchParams } = usePagination();

  // const router = useRouter();

  // const handleSearch = () => {
  //   if (searchTerm) {
  //     updateSearch(searchTerm);
  //     router.push(`/search/?query=${searchTerm}`);
  //   } else {
  //     router.push(`/`);
  //   }
  // };

  const handleToggle = (key: number) => {
    setActiveKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleSubToggle = (parentKey: number, key: number) => {
    setSubActiveKeys((prev) => ({
      ...prev,
      [parentKey]: { ...prev[parentKey], [key]: !prev[parentKey]?.[key] },
    }));
  };

  useClickOutside({
    ref: menuRef,
    callback: () => {
      setActiveKeys({});
      setSubActiveKeys({});
    },
  });

  return (
    <div
      className={`mobile-header-active mobile-header-wrapper-style ${
        toggleMobileMenu ? "sidebar-visible" : ""
      }`}
    >
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-top">
          <div className="mobile-header-logo">
            <Link href="/">
              <Image
                width={140}
                height={55}
                src="/assets/imgs/theme/logo.png"
                alt="logo"
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
            <Button
              type="button"
              className="close-style search-close"
              onClick={toggleClick}
              text={
                <Image
                  width={10}
                  height={10}
                  src="/assets/imgs/theme/icons/icon-close.png"
                  alt="Close"
                />
              }
            />
          </div>
        </div>
        <div className="mobile-header-content-area">
          {/* <div className="mobile-search search-style-3 mobile-header-border">
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                value={searchTerm}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                type="text"
                placeholder="Search"
              />
              <Button
                type="submit"
                onClick={() => {
                  toggleClick();
                  handleSearch();
                }}
                text={<i className="fi-rs-search d-block"></i>}
              />
            </form>
          </div> */}
          <div className="mobile-menu-wrap mobile-header-border">
            <nav>
              <ul className="mobile-menu" ref={menuRef}>
                {data.map((item: any) => (
                  <MenuItemComponent
                    initialOpen={data?.[0]?.id}
                    key={item.id}
                    item={item}
                    activeKeys={activeKeys}
                    toggleClick={toggleClick}
                    handleToggle={handleToggle}
                    handleSubToggle={handleSubToggle}
                    subActiveKeys={subActiveKeys}
                  />
                ))}
              </ul>
            </nav>
          </div>
          <div className="mobile-social-icon mb-15 text-black d-flex align-items-center">
            <h6 className="text-grey-4">Follow Us</h6>
            <Link href="/fb/avaryaretail" className="d-flex align-items-center">
              <Image
                width={24}
                height={24}
                className="img-fluid h-100 w-100"
                src="/assets/imgs/theme/icons/icon-facebook.svg"
                alt="facebook-icon"
              />
            </Link>
            <Link
              href="/insta/avaryaretail"
              className="d-flex align-items-center"
            >
              <Image
                width={24}
                height={24}
                className="img-fluid h-100 w-100"
                src="/assets/imgs/theme/icons/icon-instagram.svg"
                alt="instagram-icon"
              />
            </Link>
            <Link
              href="https://www.youtube.com/@avaryaretail/"
              className="d-flex align-items-center"
            >
              <Image
                width={24}
                height={24}
                className="img-fluid h-100 w-100"
                src="/assets/imgs/theme/icons/icon-youtube.svg"
                alt="pintrest-icon"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MobileMenu };
