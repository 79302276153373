"use client";

import Image from "next/image";
import { useState } from "react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Thumbs]);

const ThumbSlider = ({ product }: { product: any }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);

  return (
    <div>
      <Swiper
        pagination={{
          clickable: true,
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        className="mySwiper2"
        style={{
          //@ts-ignore
          "--swiper-navigation-color": "#525252",
          // f3750f
        }}
      >
        {product?.images?.length > 0 &&
          product?.images?.map((item: any, index: number) => (
            <SwiperSlide key={index}>
              <Image
                width={800}
                height={800}
                src={item.img}
                alt={product.title}
                className="w-100 h-100 img-fluid"
              />
            </SwiperSlide>
          ))}
      </Swiper>
      <Swiper
        onSwiper={(swiper) => setThumbsSwiper(swiper)}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        className="mySwiper"
      >
        {product?.images?.length > 0 &&
          product?.images?.map((item: any, index: number) => (
            <SwiperSlide key={`thumb-${index}`}>
              <Image
                width={200}
                height={200}
                src={item.img}
                alt={product.title}
                className="w-100 h-100 img-fluid"
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export { ThumbSlider };
