"use client";

import { Loader, Table, TableBody } from "@/components/core-components";
import { useAppSelector } from "@/store";
import { calculateAllValues, displayValue, to2Decimal } from "@/utils";
import React from "react";
import { STATUSES } from "../../../utils/constants";

const CartSummary = ({
  title,
  className,
  parentClassName,
  cartItems,
  shipping_cost,
  showShipping = true,
  showTotalItems = false,
}: {
  title?: string;
  className?: string;
  parentClassName?: string;
  cartItems?: any;
  shipping_cost?: number;
  showShipping?: boolean;
  showTotalItems?: boolean;
}) => {
  const { state } = useAppSelector((state) => state.common);

  const { total_amount, sub_total, total_tax, total_items, round_off } =
    calculateAllValues(cartItems);

  return (
    <div
      className={`${
        parentClassName
          ? parentClassName
          : "col-lg-12 col-md-12 order-summary-section"
      } mb-10`}
    >
      {title && (
        <div className="heading_s1 mb-3 d-flex align-items-center flex-row gap-2">
          <h5>{title}</h5>
          {showTotalItems && (
            <span>{`(${total_items} item${total_items > 1 ? "s" : ""})`}</span>
          )}
        </div>
      )}
      <Table tableClassName="mb-0">
        <TableBody>
          <tr>
            <td className="cart_total_label">Item(s) Subtotal</td>
            <td className="cart_total_amount text-end">
              <span className="font-sm fw-500 text-dark">
                <span className="rupee-symbol">₹</span>
                {displayValue(to2Decimal(sub_total || 0))}
              </span>
            </td>
          </tr>

          {showShipping && (
            <tr>
              <td className="cart_total_label">Shipping</td>
              <td className="font-sm fw-500 text-end">
                {state.status === STATUSES.LOADING &&
                state.type === "shipping-cost" ? (
                  <Loader />
                ) : (
                  <span className="font-sm fw-500 text-dark">
                    <span className="rupee-symbol">₹</span>
                    {displayValue(to2Decimal(shipping_cost || 0))}
                  </span>
                )}
              </td>
            </tr>
          )}

          {round_off > 0 && (
            <tr>
              <td className="cart_total_label">Round Off</td>
              <td className="cart_total_amount text-end">
                <strong>
                  <span className="font-sm fw-500 text-dark">
                    <span className="rupee-symbol">₹</span>
                    {round_off}
                  </span>
                </strong>
              </td>
            </tr>
          )}

          <tr>
            <td className="cart_total_label">{`${
              title?.includes("Order") ? "Order" : ""
            } Total`}</td>
            <td className="cart_total_amount text-end">
              <strong>
                <span className="font-sm fw-500 text-dark">
                  <span className="rupee-symbol">₹</span>
                  {displayValue(
                    to2Decimal((total_amount || 0) + (shipping_cost || 0))
                  )}
                </span>
              </strong>
            </td>
          </tr>
        </TableBody>
      </Table>
    </div>
  );
};

export { CartSummary };
