import * as Yup from "yup";

export const AddressSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  contact_number: Yup.string()
    .required("Mobile Number is required")
    .max(10, "Mobile Number should be 10 digits")
    .min(10, "Mobile Number should be 10 digits"),
  pincode: Yup.string()
    .required("Pincode is required")
    .min(6, "Pincode should be 6 digits")
    .max(6, "Pincode should be 6 digits"),
  address_line_1: Yup.string().required(
    "Please enter flat/apartment details is required"
  ),
  address_line_2: Yup.string().required(
    "Please enter area/locality details is required"
  ),
  landmark: Yup.string().nullable(),
  city: Yup.string().required("City is required").nonNullable(),
  state: Yup.string().required("State is required").nonNullable(),
  country: Yup.string().required("Country is required").nonNullable(),
  is_default: Yup.string(),
});

export const ProfileSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  contact_number: Yup.string()
    .required("Mobile Number is required")
    .max(10, "Mobile Number should be 10 digits")
    .min(10, "Mobile Number should be 10 digits"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const UpdateProfileDetailsSchema = ({
  otpSent,
  type,
}: {
  otpSent: boolean;
  type: string;
}) => {
  const valueToSet = `${type === "email" ? "Email" : "Contact Number"}`;

  const emailOrPhoneValidation = Yup.string()
    .required(`${valueToSet} is required`)
    .test("profile_email_or_phone", `Invalid ${valueToSet}`, (value) => {
      if (!value) return false;
      const isPhoneNumber = /^[0-9]{10}$/.test(value);
      const isEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value);
      return isPhoneNumber || isEmail;
    });

  return Yup.object().shape({
    old_value: emailOrPhoneValidation,
    new_value: emailOrPhoneValidation.test(
      "not_same_as_old_value",
      `Please enter a new ${valueToSet}`,
      function (new_value) {
        const { old_value } = this.parent;
        return new_value !== old_value;
      }
    ),
    otp: otpSent
      ? Yup.number().nonNullable().required("OTP is required")
      : Yup.number().nullable(),
  });
};

export const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  contact_number: Yup.string()
    .required("Mobile Number is required")
    .max(10, "Mobile Number should be 10 digits")
    .min(10, "Mobile Number should be 10 digits"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

export const LoginSchema = ({
  verification_pending,
  otpSent,
}: {
  verification_pending: boolean;
  otpSent: boolean;
}) => {
  const emailOrPhoneValidation = Yup.string()
    .required("Email or Phone Number is required")
    .test("email_or_phone", "Invalid Email or Phone Number", (value) => {
      if (!value) return false;
      const isPhoneNumber = /^[0-9]{10}$/.test(value);
      const isEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value);
      return isPhoneNumber || isEmail;
    });

  return Yup.object().shape({
    email_or_phone: emailOrPhoneValidation,
    email: verification_pending
      ? Yup.string()
          .email("Invalid email")
          .nonNullable()
          .required("Email is required")
      : Yup.string().email("Invalid email").nullable(),
    otp: otpSent
      ? Yup.number().nonNullable().required("OTP is required")
      : Yup.number().nullable(),
  });
};

export const RegisterSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  contact_number: Yup.string()
    .required("Contact Number is required")
    .max(10, "Contact Number should be 10 digits")
    .min(10, "Contact Number should be 10 digits"),
  country_code: Yup.string().required("Country code is required"),
  // whatsapp_number: Yup.string()
  //   .required("Whatsapp Number is required")
  //   .max(10, "Whatsapp Number should be 10 digits")
  //   .min(10, "Whatsapp Number should be 10 digits"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const ReviewSchema = Yup.object().shape({
  rating: Yup.number().required("Please provide a valid rating"),

  review_comment: Yup.string().required("Review is required"),

  review_status: Yup.string().nullable(),

  review_media: Yup.array().of(Yup.string()).nullable(),
});
