"use client";

import {
  Loader,
  Table,
  TableBody,
  TableHeader,
} from "@/components/core-components";
import { setWholeCart, useAppDispatch, useAppSelector } from "@/store";
import React, { useEffect } from "react";
import { CartItem } from "./CartItem";
import Link from "next/link";
import { CartSummary } from "./CartSummary";
import {
  API_ROUTES,
  calculateAllValues,
  canPlaceOrder,
  CART_CONSTANTS,
  STATUSES,
} from "@/utils";
import { useRouter } from "next/navigation";

const Cart = () => {
  const dispatch = useAppDispatch();

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { state } = useAppSelector((state) => state.common);

  const { cartItems } = useAppSelector((state) => state.cart);

  const router = useRouter();

  const is_logged_in = logged_in_user || access_token;

  const canProceedToCheckout = canPlaceOrder(cartItems);

  useEffect(() => {
    router.refresh();

    if (is_logged_in) {
      dispatch(setWholeCart());
    }
  }, []);

  return (
    <section className="">
      <div className="container section-padding-12 pt-30">
        <div className="row">
          <div className="col-lg-12 mb-15">
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <h3>Shopping Cart</h3>
                <h6 className="pt-2 text-body">
                  Kindly check your order before checkout
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-9">
            <Table
              parentClassName="shopping-summery"
              tableClassName="table-wishlist"
            >
              <TableHeader
                cols={CART_CONSTANTS.COLS}
                rowClassName="main-heading"
              />
              <TableBody>
                {state.status === STATUSES.LOADING &&
                state.type === `fetch-cart` ? (
                  <tr>
                    <td
                      colSpan={CART_CONSTANTS.COLS?.length + 1}
                      className="text-center"
                    >
                      <Loader />
                    </td>
                  </tr>
                ) : cartItems?.length > 0 ? (
                  cartItems?.map((item: any, i: number) => (
                    <CartItem key={i} index={i} cartItem={item} />
                  ))
                ) : (
                  "No Products"
                )}
              </TableBody>
            </Table>
          </div>
          <div className="col-lg-3 col-md-12 pb-30">
            <div className="border p-md-4 p-15 border-radius cart-totals">
              <CartSummary
                title={"Cart Total"}
                cartItems={cartItems}
                shipping_cost={0}
                showShipping={false}
                showTotalItems={true}
              />
              <div className="text-center text-sm my-2">
                Shipping calculated at checkout
              </div>
              <Link
                href={canProceedToCheckout ? API_ROUTES.CHECKOUT : "#"}
                className={`btn btn-sm w-100 ${
                  cartItems?.length === 0 ||
                  !canProceedToCheckout ||
                  (state.status === STATUSES.LOADING &&
                    state.type === `fetch-cart`) ||
                  (state.status === STATUSES.LOADING &&
                    state.type?.includes("delete-cartItem"))
                    ? "disabled"
                    : ""
                }`}
              >
                Proceed To Checkout
              </Link>
            </div>
          </div>
        </div>

        {/* <ProductHorizontalListing
          hasDesc={false}
          slidesPerView={6}
          spaceBetween={30}
          prevBtn={"custom_prev_2"}
          nextBtn={"custom_next_2"}
          parentClassName="row"
          btnClassName="slider-arrow-2 carausel-6-columns-arrow"
          sliderClassName="col-12"
          sliderId="row related-products position-relative"
          data={STATIC_CONSTANTS.PRODUCTS}
          title="You May Also Like"
        /> */}
      </div>
    </section>
  );
};

export { Cart };
