"use client";

import Script from "next/script";
import { Form, Formik, FormikHelpers } from "formik";
import { useRouter } from "next/navigation";
import { Button, CustomInput } from "../core-components";
import { FORM_CONSTANTS, STATIC_CONSTANTS } from "@/utils";
import { useRef } from "react";

function RazorPay() {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);

  const router = useRouter();

  const createOrderId = async (amount: string) => {
    try {
      const response = await fetch("/api/razorpay/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: parseFloat(amount) * 100,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      console.log("razorpay", data);

      return data.orderId;
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  const processPayment = async ({
    name,
    contact,
    email,
    amount,
  }: {
    name: string;
    contact: string;
    email: string;
    amount: string;
  }) => {
    try {
      const orderId: string = await createOrderId(amount);

      const options = {
        key: STATIC_CONSTANTS.ENV_CONSTANTS.RAZORPAY_ID,
        amount: parseFloat(amount) * 100,
        currency: "INR",
        name: name,
        contact: contact,
        email: email,
        description: "description",
        order_id: orderId,
        handler: async function (response: any) {
          console.log("handler", response);
          const data = {
            orderCreationId: orderId,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          const result = await fetch("/api/verify/", {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
          });

          const res = await result.json();

          console.log("verfiy", res);

          if (res.isOk) {
            router.push("/success");
          } else {
            alert(res.message);
          }
        },
        prefill: {
          name: name,
          email: email,
        },
        theme: {
          color: "#fff1e6",
        },
      };

      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.submit", (response: any) => {
        paymentMethod.current = response.method;
      });

      paymentObject.on("paymeny.success", function (response: any) {
        console.log(response);
      });

      paymentObject.on("payment.failed", function (response: any) {
        console.log("response", response);
        paymentId.current = response.error.metadata.payment_id;
        alert(response.error.description);
      });

      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  function handleSubmit(
    values: {
      name: string;
      contact: number;
      email: string;
      amount: number;
    },
    actions: FormikHelpers<{
      name: string;
      contact: number;
      email: string;
      amount: number;
    }>
  ) {
    processPayment({
      name: values.name,
      contact: values.contact.toString(),
      email: values.email,
      amount: values.amount.toString(),
    });
  }

  return (
    <>
      <Script
        id="razorpay-checkout-js"
        src={STATIC_CONSTANTS.RAZORPAY_CONSTANTS.CHECKOUT_URL}
      />
      <div className="page-content section-padding-12">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 m-auto">
              <div className="contact-from-area padding-20-row-col">
                <h5 className="text-brand mb-10">Razorpay</h5>
                <h1 className="mb-10">Test a payment</h1>
                <p className="text-muted mb-30 font-sm">
                  Your funds will not be deducted. Required fields are marked *
                </p>
                <Formik
                  initialValues={{ name: "", contact: 0, email: "", amount: 0 }}
                  onSubmit={handleSubmit}
                >
                  {(props) => (
                    <Form>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <CustomInput
                            required
                            placeholder="First Name"
                            formikProps={props}
                            label={"First Name"}
                            name={"name"}
                            type={FORM_CONSTANTS.TEXT}
                            titleCase={true}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <CustomInput
                            required
                            placeholder="Contact Details"
                            formikProps={props}
                            label={"Contact"}
                            name={"contact"}
                            type={FORM_CONSTANTS.NUMBER}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <CustomInput
                            required
                            placeholder="Your Email"
                            formikProps={props}
                            label={"Email"}
                            name={"email"}
                            type={FORM_CONSTANTS.EMAIL}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <CustomInput
                            required
                            min={1}
                            placeholder="Your Ampount"
                            formikProps={props}
                            label={"Amount"}
                            name={"amount"}
                            type={FORM_CONSTANTS.NUMBER}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <Button
                            text="Submit"
                            type="submit"
                            className="btn btn-primary"
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export { RazorPay };
