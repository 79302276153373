"use client";

import {
  capitalizeFirstLetter,
  IDBSitemap,
  SiteMapItem,
  STATIC_CONSTANTS,
  SubSiteMapItem,
} from "@/utils";

const Sitemap = ({ sitemapData }: { sitemapData: IDBSitemap }) => {
  const formatSitemapData = (data: IDBSitemap) => {
    const dataToReturn = [];

    data.pages.map((pageItem) => {
      dataToReturn.push({
        title: pageItem.page_name,
        link: pageItem.meta_slug,
      });
    });

    const category_data = data.category.map((item) => ({
      title: item.display_name,
      link: item.meta_slug,
      subItems: item.sub_category.map((subItem) => ({
        title: subItem.display_name,
        link: subItem.meta_slug,
      })),
    }));

    STATIC_CONSTANTS.LOCAL_PAGES.forEach((slug) => {
      dataToReturn.push({
        title: slug
          .split("-")
          .map((item: any) => capitalizeFirstLetter(item))
          .join(" "),
        link: slug,
        subItems: [],
      });
    });

    dataToReturn.push({
      title: "Browse All Categories",
      link: "#",
      subItems: category_data,
    });

    return dataToReturn;
  };

  const renderSubItems = (subItems: SubSiteMapItem[]) => {
    if (!subItems?.length) return null;
    return (
      <ul className="list-nest">
        {subItems?.map((subItem: any, index: number) => (
          <li key={index}>
            <span className="line"></span>
            <img
              src="/assets/imgs/theme/icons/icon-arrow-alt-right.svg"
              alt="arrow-right"
              width="30px"
            />
            {subItem.title}
            {renderSubItems(subItem?.subItems)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="page-content pt-30">
      <div className="col-xl-10 col-lg-12 m-auto">
        <div className="row">
          <div className="col-lg-12">
            <div className="single-page mb-lg-0 mb-sm-5">
              <div className="single-header style-2">
                <h1 className="heading-2 text-center">Sitemap</h1>
              </div>
              <div className="single-content sitemap mb-50">
                <ul className="list-nest">
                  {formatSitemapData(sitemapData).length > 0
                    ? formatSitemapData(sitemapData)?.map(
                        (item: SubSiteMapItem, index) => (
                          <li key={index}>
                            <span className="line"></span>
                            <img
                              src="/assets/imgs/theme/icons/icon-arrow-alt-right.svg"
                              alt="arrow-alt-right"
                              width="30px"
                            />
                            {item.title}
                            {item.subItems && renderSubItems(item.subItems)}
                          </li>
                        )
                      )
                    : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Sitemap };
