import {
  customRevalidateTag,
  IAddress,
  POST_PUT_AddressType,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATUSES,
} from "@/utils";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { setQuickView } from "./commonSlice";
import { FormikHelpers } from "formik";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

const initialState = {
  addressDetail: {} as IAddress,
  status: STATUSES.IDLE as string,
  error: null as null | string,
};

export const fetchAddressDetails = createAsyncThunk(
  "auth/fetchAddressDetails",
  async (
    {
      address_id,
    }: {
      address_id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(`/api/account/addresses/${address_id}/`, {
        method: REQUEST_METHOD.GET,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
      }).then((res) => res.json());

      return response;
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

export const addAddress = createAsyncThunk(
  "auth/addAddress",
  async (
    {
      data,
      actions,
      router,
      modal = false,
    }: {
      data: POST_PUT_AddressType;
      actions: FormikHelpers<any>;
      router: AppRouterInstance;
      modal: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await fetch("/api/account/addresses", {
        method: REQUEST_METHOD.POST,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
        body: JSON.stringify(data),
      }).then((res) => res.json());

      if (modal) {
        dispatch(
          setQuickView({
            state: false,
            data: null,
            type: "",
          })
        );
      } else {
        router.replace("/account/addresses");
      }

      actions.resetForm();

      return response;
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "auth/updateAddress",
  async (
    {
      data,
      actions,
      router,
      modal = false,
    }: {
      data: POST_PUT_AddressType;
      actions: FormikHelpers<any>;
      router: AppRouterInstance;
      modal: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await fetch(`/api/account/addresses/${data.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
        body: JSON.stringify(data),
      }).then((res) => res.json());

      if (modal) {
        dispatch(
          setQuickView({
            state: false,
            data: null,
            type: "",
          })
        );
      } else {
        router.replace("/account/addresses");
      }

      actions.resetForm();

      return response;
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "auth/deleteAddress",
  async (
    {
      address_id,
    }: {
      address_id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(`/api/account/addresses/${address_id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
      }).then((res) => res.json());

      return response;
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

export const setAsDefaultAddress = createAsyncThunk(
  "auth/setAsDefaultAddress",
  async (
    {
      data,
    }: {
      data: {
        address_id: number;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        `/api/account/addresses/set_as_default/?address=${data.address_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
          },
        }
      ).then((res) => res.json());

      return response;
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setAdddressDetail: (state, action) => {
      state.addressDetail = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAddressDetails.pending, (state) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAddressDetails.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.addressDetail = action.payload.result;
      })
      .addMatcher(
        isAnyOf(
          addAddress.fulfilled,
          updateAddress.fulfilled,
          deleteAddress.fulfilled,
          setAsDefaultAddress.fulfilled
        ),
        () => {
          customRevalidateTag("addresses");
        }
      );
  },
});

export const { setAdddressDetail } = addressSlice.actions;

export default addressSlice.reducer;
