"use client";

import { capitalizeFirstLetter, STATIC_CONSTANTS } from "@/utils";
import { ProductListing } from "./Product/ProductListing";
import { Pagination } from "@/components/Pagination";
import { ExtraFiltersForm } from "./ExtraFiltersForm";
import { Button } from "@/components/core-components";
import {
  fetchFilteredProducts,
  resetFilters,
  setFilters,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { useEffect, useMemo } from "react";

const ListingWithFilters = ({
  data,
  type,
  search = false,
  total_pages = 1,
  count,
  title,
  filters = [],
  widgetIndex,
  extraFilters = false,
  clearBtnText,
  clearBtnClick,
  individualDelete,
  largeCard = false,
  hasDesc = false,
  fetchUrl,
  serverFilters = false,
  last = false,
}: {
  data?: any[];
  type?: string;
  fetchUrl?: string;
  total_pages?: number;
  count?: number;
  search?: boolean;
  title: string;
  filters: string[];
  largeCard?: boolean;
  widgetIndex?: number;
  extraFilters?: boolean;
  hasDesc?: boolean;
  clearBtnText?: string;
  clearBtnClick?: () => void;
  individualDelete?: (value: any) => void;
  serverFilters?: boolean;
  last?: boolean;
  showLimit?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const {
    state,
    filter,
    filteredProducts,
    totalPages,
    count: productCount,
  } = useAppSelector((state) => state.common);

  const noFiltersApplied =
    filter.page === 1 &&
    filter.is_jain === 0 &&
    filter.query === "" &&
    filter.taste === "" &&
    filter.sort_by === "all";

  useEffect(() => {
    if (!serverFilters) {
      dispatch(
        fetchFilteredProducts({
          filters: {
            ...filter,
            limit: STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "")
              ? 24
              : filter.limit,
          },
          url: fetchUrl || "/shop/product/search/",
          search: serverFilters,
        })
      );
    }
  }, [
    filter.is_jain,
    filter.limit,
    filter.page,
    filter.query,
    filter.taste,
    filter.sort_by,
    fetchUrl,
  ]);

  const renderFilters = () => {
    return (
      <div className="shop-product-fillter">
        <div className="totall-product">
          {/* {clearBtnClick ? (
            <p>
              You have
              <strong className="text-brand">{count}</strong>
              items in your wishlist!
            </p>
          ) :
           serverFilters ? (
            <p>
              We found
              <strong className="text-brand">
                {count || productCount}
              </strong>
              items for you!
            </p>
          ):
          null} */}
        </div>
        <ExtraFiltersForm
          showLimit={
            data?.length === 0 ||
            STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "")
          }
          search={search}
          serverFilters={serverFilters}
          clearBtnClick={clearBtnClick}
          clearBtnText={clearBtnText}
        />
      </div>
    );
  };

  return (
    <>
      <section className="product-tabs section-border-bottom section-padding-12 position-relative">
        <div className="container" id="listing-container">
          <div className="row">
            {extraFilters ? (
              <>
                {/* need clariffication for the col-4. i am replacing it for now */}
                <div
                  className={`${
                    STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "")
                      ? "col-md-4 col-12"
                      : "col-12"
                  }`}
                >
                  <div className="section-title">
                    <h3>{title}</h3>
                  </div>
                </div>

                {STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "") ? (
                  <div className="col-8">{renderFilters()}</div>
                ) : null}
              </>
            ) : null}
            <div className="col-12">
              {STATIC_CONSTANTS.LISTING_EXCLUDE.includes(
                type || ""
              ) ? null : extraFilters ? (
                renderFilters()
              ) : (
                <div className="section-title d-flex flex-row align-items-center style-2 wow animate__animated animate__fadeIn">
                  <h3>{title}</h3>
                  {filters && filters?.length > 0 ? (
                    <ul
                      className="nav nav-tabs links filter-right"
                      id="myTab"
                      role="tablist"
                    >
                      {filters?.map((item, index) => (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <Button
                            className={
                              capitalizeFirstLetter(filter.sort_by || "") ===
                              item
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => {
                              if (item === "All") {
                                dispatch(
                                  setFilters({ ...filter, sort_by: "" })
                                );
                                // additionalFilter("type");
                              } else {
                                dispatch(
                                  setFilters({ ...filter, sort_by: item })
                                );
                                // additionalFilter("type", item);
                              }
                            }}
                            text={item}
                            type="button"
                          />
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              )}
              <div className="tab-content wow fadeIn animated">
                <div className="tab-pane fade show active">
                  <div
                    className={`row product-grid-${
                      individualDelete ? "3" : "4"
                    }`}
                  >
                    <ProductListing
                      type={type}
                      widgetIndex={widgetIndex || 0}
                      hasDesc={hasDesc}
                      products={
                        noFiltersApplied && data && data?.length > 0
                          ? data
                          : fetchUrl
                          ? filteredProducts
                          : data
                      }
                      deleteBtn={individualDelete}
                      largeCard={largeCard}
                    />
                  </div>
                </div>
              </div>
              {fetchUrl &&
              (filteredProducts.length > 0 || (data && data?.length > 0)) &&
              (total_pages > 1 || totalPages > 1) ? (
                // mt-15
                <div className="pagination-area mt-5 mb-sm-5 mb-lg-0 mb-0">
                  <nav aria-label="Page navigation example">
                    <Pagination
                      total_page={
                        noFiltersApplied && data && data?.length > 0
                          ? total_pages
                          : fetchUrl
                          ? totalPages
                          : total_pages
                      }
                      serverFilters={serverFilters}
                    />
                  </nav>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      {!last && <div className={`section-border-top`} />}
    </>
  );
};

export { ListingWithFilters };
