"use client";

import { useAppSelector } from "@/store";
import React from "react";
import { CartOffCanvas } from "./Cart";
import { OffCanvas } from "../core-components";
import { AddressForm } from "./Account";

const GlobalOffCanvas = ({ data }: { data?: any }) => {
  const {
    offCanvas: { state, component },
  } = useAppSelector((state) => state.common);

  const renderOffCanvas = () => {
    switch (component) {
      case "cart":
        return <CartOffCanvas cartData={data["cart"]} />;

      default:
        return null;
    }
  };

  return <OffCanvas type={component || ""}>{renderOffCanvas()}</OffCanvas>;
};

export { GlobalOffCanvas };
