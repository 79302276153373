"use client";

import React from "react";
import { Pagination } from "../Pagination";

interface TableProps {
  children: React.ReactNode;
  parentClassName?: string;
  tableClassName?: string;
  pagination?: boolean;
  paginationClassName?: string;
  total_page?: number;
}

const Table: React.FC<TableProps> = ({
  children,
  parentClassName,
  tableClassName,
  paginationClassName,
  pagination,
  total_page,
}) => {
  return (
    <div
      className={`table-responsive ${parentClassName ? parentClassName : ""}`}
    >
      <table
        className={`table ${tableClassName ? tableClassName : "text-nowrap"}`}
      >
        {children}
      </table>
      {pagination && total_page ? (
        <div className={`${paginationClassName ? paginationClassName : ""}`}>
          <Pagination total_page={total_page} serverFilters={true} />
        </div>
      ) : null}
    </div>
  );
};

export { Table };
