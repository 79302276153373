"use client";

import React, { useEffect, useState } from "react";
import { About } from "./About";
import { Festive01, Festive02, Festive03 } from "./Festive";
import { Category } from "./Category";
import { OurStores } from "./OurStores";
import { Testimonials } from "./Testimonials";
import { ContactUs } from "./ContactUs";
import { FAQ } from "./FAQ";
import { SecureShopping } from "./SecureShopping";
import { FestiveCalendar } from "./FestiveCalendar";
import { Terms } from "./Terms";
import { ShippingPolicy } from "./ShippingPolicy";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { Disclaimer } from "./Disclaimer";
import { Sitemap } from "./Sitemap";
import { notFound } from "next/navigation";
import { StructuredData } from "../StructuredData";

const StaticPages = ({ slug, data }: { slug: string; data: any }) => {
  const renderStaticPages = (page: string) => {
    switch (page) {
      case "sitemap":
        return <Sitemap sitemapData={data} />;
      // case "disclaimer":
      //   return <Disclaimer />;
      // case "privacy-policy":
      //   return <PrivacyPolicy />;
      // case "shipping-policy":
      //   return <ShippingPolicy />;
      // case "terms":
      //   return <Terms />;
      // case "festival-calendar":
      //   return <FestiveCalendar />;
      // case "secure-shopping":
      //   return <SecureShopping />;
      case "faqs":
        return <FAQ faqs={data} />;
      case "contact-us":
        return <ContactUs />;
      case "testimonials":
        return <Testimonials testimonials={data} />;
      case "our-stores":
        return <OurStores stores={data} />;
      // case "about":
      //   return <About />;
      // case "festive-offers-1":
      //   return <Festive01 />;
      // case "festive-offers-2":
      //   return <Festive02 />;
      // case "festive-offers-3":
      //   return <Festive03 />;
      default:
        return notFound();
    }
  };

  return (
    <>
      <StructuredData faqs={slug === "faqs" ? data : []} />
      {renderStaticPages(slug)}
    </>
  );
};

export { StaticPages };
