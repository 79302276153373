import React from "react";

const BannerShimmer = () => {
  return (
    <div className={`skeleton-rect single-hero-slider single-animation-wrap`}>
      <div className="slider-content">
        <h1 className={`display-2 mb-40`}>
          <span
            className="skeleton skeleton-text"
            style={{ width: "auto" }}
          ></span>
        </h1>
        <p className={`mb-65`}>
          <span
            className="skeleton skeleton-text"
            style={{ width: "auto" }}
          ></span>
        </p>
      </div>
    </div>
  );
};

export { BannerShimmer };
