"use client";

import { BannerItemProps } from "@/utils";
import React from "react";
import { BannerItem } from "./BannerItem";

const BannersList = ({
  banners,
  widgetIndex,
  direction
}: {
  banners: BannerItemProps[];
    widgetIndex: number;
  direction: string
}) => {
  return (
    <section className="section-padding-12 banners">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              {banners?.length > 0 &&
                banners?.map((banner, i) => (
                  <BannerItem
                    banner={banner}
                    direction={direction}
                    key={i}
                    length={banners?.length}
                    index={i}
                    widgetIndex={widgetIndex}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { BannersList };
