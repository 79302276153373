"use client";

import { Button } from "@/components/core-components";
import { STATIC_CONSTANTS } from "@/utils";
import Image from "next/image";
import React, { useState } from "react";

const ShareModal = ({
  modal,
  setModal,
  slug,
}: {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  slug?: string;
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(`${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/${slug}` || "")
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
  };

  if (!modal) return null;
  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h5>Share this product</h5>
        <div className="buttonContainer flex-row mb-20">
          <Button
            type="button"
            textClassName={"d-flex"}
            onClick={handleCopyLink}
            className="shareButton mr-10"
            text={
              <>
                <Image
                  width={20}
                  height={20}
                  src="/assets/imgs/theme/icons/icon-copy.png"
                  alt="Copy Link"
                  className="icon"
                />
                {copied ? "Copied!" : "Copy Link"}
              </>
            }
          />
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              `${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/${slug}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <Image
              width={20}
              height={20}
              src="/assets/imgs/theme/icons/facebook.png"
              alt="Share on Facebook"
              className="icon"
            />
          </a>
          <a
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              `${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/${slug}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <Image
              width={20}
              height={20}
              src="/assets/imgs/theme/icons/whatsapp.png"
              alt="Share on WhatsApp"
              className="icon"
            />
          </a>
          <a
            href={`https://www.instagram.com/?url=${encodeURIComponent(
              `${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/${slug}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <Image
              width={20}
              height={20}
              src="/assets/imgs/theme/icons/instagram.png"
              alt="Share on Instagram"
              className="icon"
            />
          </a>
          <a
            href={`mailto:?subject=Check out this product&body=${encodeURIComponent(
              `${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/${slug}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <Image
              width={20}
              height={20}
              src="/assets/imgs/theme/icons/mail.png"
              alt="Share via Email"
              className="icon"
            />
          </a>
        </div>
        <Button
          type="button"
          onClick={() => {
            setModal(false);
          }}
          className="btn btn-md"
          text={"Close"}
        />
      </div>
    </div>
  );
};

export { ShareModal };
