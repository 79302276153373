import React from "react";

const TimeLineItem = ({
  title,
  description,
  date,
}: {
  title: string;
  description: string;
  date: string;
}) => {
  return (
    <div className="event">
      <div className="icon"></div>
      <div className="content">
        <h2>{title}</h2>
        <p>{description}</p>
        <span className="date">{date}</span>
      </div>
    </div>
  );
};

const OrderTracking = () => {
  const timelineData = [
    {
      title: "Order Placed",
      description: "Your order has been placed successfully.",
      date: "01/01/2024",
    },
    {
      title: "Order Confirmed",
      description: "Your order has been confirmed.",
      date: "02/01/2024",
    },
    {
      title: "Order Dispatched",
      description:
        "Your order has been dispatched from the warehouse.Your order has been dispatched from the warehouse.Your order has been dispatched from the warehouse.",
      date: "03/01/2024",
    },
    {
      title: "Out for Delivery",
      description: "Your order is out for delivery.",
      date: "04/01/2024",
    },
    {
      title: "Delivered",
      description: "Your order has been delivered.",
      date: "05/01/2024",
    },
  ];
  return (
    <section className="section-padding-12 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="tracking-info-section row">
              <div className="col-lg-12 col-md-12">
                <div className="border pl-15 pr-15 pt-15 pb-0 border-radius cart-totals">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="pb-15 mb-0">
                        <h3 className="section-title mb-0">
                          Tracking Information
                        </h3>
                      </div>
                      <div className="timeline">
                        {timelineData.map((item, index) => (
                          <TimeLineItem
                            key={index}
                            title={item.title}
                            description={item.description}
                            date={item.date}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { OrderTracking };
