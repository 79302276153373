"use client";

import React from "react";
import { OrderItems } from "./OrderItems";
import { setOffCanvas, useAppDispatch, useAppSelector } from "@/store";
import { Button } from "@/components/core-components";
import { useRouter } from "next/navigation";
import { API_ROUTES, calculateAllValues } from "@/utils";

type Section = {
  title: string;
  component: React.JSX.Element;
};

const CartOffCanvas = ({ cartData }: { cartData?: any }) => {
  const dispatch = useAppDispatch();
  const { cartItems } = useAppSelector((state) => state.cart);
  const router = useRouter();
  const { total_items } = calculateAllValues(cartItems);

  const sections = [
    {
      title: "Cart Items",
      component: <OrderItems showQuantity={true} cartItems={cartItems} />,
    },
  ];

  const renderSections = (sections: Section[]) => {
    return sections.map((section, index) => (
      <div key={index} className="w-100 mb-md-0 mb-10">
        <span className="section-title gap-2 justify-content-start font-xl fw-600 text-extra-dark mb-15 pb-0">
          {section.title}
          <span className="text-muted font-md">{`(${total_items} item${
            total_items > 1 ? "s" : ""
          })`}</span>
        </span>
        <div
          className="product-list-small overflow-y-scroll"
          style={{ height: `calc(100vh - 210px)` }}
        >
          {section.component}
        </div>
      </div>
    ));
  };

  return (
    <div className="d-flex flex-column gap-1">
      <>
        {renderSections(sections)}
        <Button
          text="Checkout"
          disabled={cartItems?.length === 0}
          type="button"
          className="btn btn-sm w-100"
          onClick={() => {
            router.push(API_ROUTES.CHECKOUT);
            dispatch(setOffCanvas({ state: false, component: "cart" }));
          }}
        />
        <Button
          text="View Cart"
          type="button"
          className="btn btn-sm w-100"
          onClick={() => {
            router.push("/cart");
            dispatch(setOffCanvas({ state: false, component: "cart" }));
          }}
        />
      </>
    </div>
  );
};

export { CartOffCanvas };
