import Image from "next/image";

import about1 from "../../../public/assets/imgs/page/about-1.png";
import about5 from "../../../public/assets/imgs/page/about-5.jpg";
import graph02 from "../../../public/assets/imgs/page/graph-02.jpg";

function About() {
  return (
    <>
      {/* about */}
      <section className='section-padding-12'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='row align-items-center mb-x'>
                <div className='col-lg-6'>
                  <Image
                    width={600}
                    height={650}
                    src={about1}
                    alt='nest'
                    className='border-radius-0 mb-md-3 mb-lg-0 mb-sm-4'
                  />
                </div>
                <div className='col-lg-6'>
                  <div className='pl-25'>
                    <h1 className='mb-15'>Welcome to Avarya</h1>
                    <p className='mb-15 font-lg line-height-2'>
                      Avarya Fine Foods is a manufacturer and retailer of a
                      range of delectable mithais, namkeens and dry fruits. A
                      premium quality food store, Avarya was established in an
                      endeavour to reviving the traditional flavours of India by
                      bringing authentic sweets and snacks, along with exotic
                      dry fruits to urban consumers. We also offer a range of
                      gifting solutions to corporate organizations in
                      customizable packages for meeting their requirements. We
                      cater to diverse preferences and tastes with a
                      state-of-the-art manufacturing and packaging facility. Our
                      stores are spread across three locations in Mumbai, also
                      exporting to Singapore and Malaysia. Over the years,
                      Avarya has demonstrated robust business performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Essence */}
      <section className='section-padding-12 bg-primary-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='row align-items-center'>
                <div className='col-lg-12'>
                  <div>
                    <h2 className='mb-15'>Avarya - The Essence</h2>
                    <p className='mb-15 font-lg line-height-2'>
                      ‘Avarya’ stands for ‘irresistible’ in Sanskrit. This is
                      precisely what Avarya Fine Foods aspires to offer its
                      customers.
                    </p>
                    <p className='mb-15 font-lg line-height-2'>
                      At Avarya, we understand the preferences and the local
                      tastes of Indian consumers. We leverage our expertise in
                      traditional Indian cuisine and modern tools to prepare
                      sweets, snacks and savories that not only retain but also
                      enrich the original flavors.
                    </p>
                    <p className='mb-15 font-lg line-height-2'>
                      Our baskets and boxes of mithais and namkeens are homemade
                      with carefully picked ingredients and authentic recipes to
                      capture the ethnicity and blend them with irresistible
                      taste.
                    </p>
                  </div>
                </div>
              </div>

              {/* Mission Vision */}
              <section className='row mb-x'>
                <div className='col-lg-6'>
                  <div>
                    <h3 className='mb-30'>Mission</h3>
                    <p className='mb-15 font-lg line-header-height-2 mb-2'>
                      Customer satisfaction is a way of life at Avarya. We
                      strive to achieve maximum customer delight by
                    </p>
                    <ol>
                      <li className='font-md line-header-height-2 mb-2'>
                        <i className='fi-rs-angle-right font-xs'></i>
                        &nbsp;&nbsp;Driving high standards of quality across all
                        performance parameters
                      </li>
                      <li className='font-md line-header-height-2 mb-2'>
                        <i className='fi-rs-angle-right font-xs'></i>
                        &nbsp;&nbsp;Leveraging the experience of our four
                        directors
                      </li>
                      <li className='font-md line-header-height-2 mb-2'>
                        <i className='fi-rs-angle-right font-xs'></i>
                        &nbsp;&nbsp;Ensuring optimal utilization of resources,
                        including finance, human resources and material
                      </li>
                      <li className='font-md line-header-height-2 mb-2'>
                        <i className='fi-rs-angle-right font-xs'></i>
                        &nbsp;&nbsp;Identifying, encouraging and developing
                        skills among employees
                      </li>
                      <li className='font-md line-header-height-2 mb-2'>
                        <i className='fi-rs-angle-right font-xs'></i>
                        &nbsp;&nbsp;By agreeing to grant such access, the
                        Company does not obligate itself to maintain the course,
                        or to maintain it in its present form.
                      </li>
                    </ol>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div>
                    <h3 className='mb-30'>Vision</h3>
                    <p className='mb-15 font-lg line-height-2'>
                      We envision becoming a frontrunner of ethnic sweets and
                      savories in India, setting newer benchmarks of quality,
                      innovation and authenticity.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      {/* Guiding Principles */}
      <section className='section-padding-12'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='row mb-30 align-items-center'>
                <div className='col-lg-3 d-flex justify-content-center'>
                  <Image
                    src={about5}
                    alt='nest'
                    className='mb-md-3 mb-lg-0 mb-sm-4'
                  />
                </div>
                <div className='col-lg-9'>
                  <h1 className='heading-1 mb-40'>Gding Principles</h1>
                  <h5 className='mb-30'>
                    We are crusaders of our core values that will help us
                    achieve our vision
                  </h5>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5'>
                  <h3 className='mb-30'>Quality</h3>
                  <p className='mb-15 font-lg line-height-2'>
                    Quality is an indispensable part of our business. We adhere
                    to industry standards and strive to create new benchmarks in
                    performance. Every process, every requirement and every
                    delivery is handled with utmost focus on quality.
                  </p>
                </div>
                <div className='col-lg-4 pr-30 mb-md-5 mb-lg-0 mb-sm-5'>
                  <h3 className='mb-30'>Reliability</h3>
                  <p className='mb-15 font-lg line-height-2'>
                    It is a matter of pride and honor that our clients consider
                    us a reliable partner, someone they can trust. We make sure
                    that deliveries are handled with utmost precision in budget
                    and on time always. Furthermore, we drive reliability not
                    just in our deliveries, but also in people, processes and
                    equipment.
                  </p>
                </div>
                <div className='col-lg-4'>
                  <h3 className='mb-30'>Novelty</h3>
                  <p className='mb-15 font-lg line-height-2'>
                    We believe that cuisines evolve with time and the
                    preferences of consumers. Our chief aim is to strike chord
                    with customers’ taste buds. With this in mind, our team
                    continuously explores new ways of achieving this, without
                    compromising the traditional flavors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Gifting Solutions */}
      <section className='section-padding-12 bg-primary-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='row align-items-center'>
                <div className='col-lg-12'>
                  <h1 className='heading-1 mb-30'> Solutions</h1>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 pr-30 mb-md-5 mb-lg-0 mb-sm-5'>
                  <div className='featured-card border-none'>
                    <h4 className='mb-30 text-center'>Personal Gifting</h4>
                    <p className='mb-15 font-lg line-height-2'>
                      Gift symbolizes the purity of love, care and affection.
                      Mithais and dry fruits, which epitomize purity, are then
                      the perfect choice to showcase your affection for your
                      loved ones. Avarya provides customers unique gifting ideas
                      in the form of
                    </p>
                    <ol className='d-flex mb-30'>
                      <p className='font-lg line-height-2 mb-2 mr-30'>
                        <i className='fi-rs-angle-right font-xs'></i>
                        &nbsp;&nbsp;Dry Fruit Boxes
                      </p>
                      <p className='font-lg line-height-2 mb-2 mr-30'>
                        <i className='fi-rs-angle-right font-xs'></i>
                        &nbsp;&nbsp;Gift Baskets
                      </p>
                      <p className='font-lg line-height-2 mb-2 mr-30'>
                        <i className='fi-rs-angle-right font-xs'></i>
                        &nbsp;&nbsp;Diwali Gifts
                      </p>
                    </ol>
                    <p className='mb-0 font-lg line-height-2'>
                      You can customize the gifts with personalized messages. We
                      also deliver your gifts directly to the doorsteps of your
                      friends or family.
                    </p>
                  </div>
                </div>
                <div className='col-lg-6 pr-30 mb-md-5 mb-lg-0 mb-sm-5'>
                  <div className='featured-card border-none'>
                    <h4 className='mb-30 text-center'>Corporate Gifting</h4>
                    <p className='mb-0 font-lg line-height-2'>
                      An organization’s employees and clients are often
                      considered crusaders of its brand. One way to leave a
                      lasting impression on your stakeholders is by gifting them
                      a symbol of thoughtfulness. Gourmet mithais, farsans and
                      dry fruits are the sole contenders of kindling loyalty and
                      positive perception of your organization, establishing a
                      unique brand image. Amidst a dearth of gifting ideas,
                      Avarya offers a broad range of gifting solutions for
                      corporate organizations. You can customize your gift
                      hampers and gourmet baskets with mithais and dry fruits of
                      your choice, and personalize them with messages. We use
                      durable, high-quality packaging for the gift hampers and
                      deliver it to your peers, colleagues and clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Infrastructure */}
      <section className='section-padding-12'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='row align-items-center mb-x'>
                <div className='col-lg-12'>
                  <div>
                    <h2 className='mb-30'>Infrastructure</h2>
                    <p className='mb-15 font-lg line-height-2'>
                      We have a state-of-the-art manufacturing and packaging
                      facility. We adhere to the specifications of FSSAI (Food
                      Safety and Standards Authority of India), ensuring
                      superior quality and food safety.
                    </p>
                    <p className='mb-15 font-lg line-height-2'>
                      Our expert team employs modern techniques and age-old
                      recipes to bring a range of mouth-watering mithais,
                      namkeens and dry fruits to our customers. We are equipped
                      with structured processes to manage orders of large
                      volumes within the speculated time.
                    </p>
                    <p className='mb-15 font-lg line-height-2'>
                      We also possess a well-appointed warehouse for streamlined
                      inventory management of raw materials, and enabling
                      efficient export.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Company policies */}
      <section className='pb-30'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='row align-items-center mb-x'>
                <div className='col-lg-12'>
                  <div>
                    <h2 className='mb-30'>Company Policies</h2>
                    <h4 className='mb-30'>Quality Policy</h4>
                    <p className='mb-15 font-lg line-height-2'>
                      Think Avarya, Think Quality!
                    </p>
                    <p className='mb-15 font-lg line-height-2'>
                      At Avarya, quality is not an afterthought, but a promise.
                      We follow industry best practices and incorporate industry
                      standards to ensure stringent adherence to quality
                      guidelines. We have a robust quality management system
                      across functions, from raw material procurement and
                      cleaning to manufacture and packaging.
                    </p>
                    <p className='mb-15 font-lg line-height-2'>
                      Packaging is also handled with utmost care to ensure that
                      the products retain its freshness and flavors till it
                      reaches our consumers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Safe Food */}
      <section className='section-padding-12 bg-primary-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='text-center mb-x'>
                <h2 className='title style-3 mb-40'>Safe Food</h2>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 mb-24'>
                    <div className='featured-card border-none'>
                      <h4>
                        FSSAI (Food Safety and Standards Authority of India)
                      </h4>
                      <p className='font-lg line-height-2 mb-0'>
                        We are a registered firm with FSSAI, a national body
                        governing and regulating food safety for promoting
                        public health. We comply with FSSAI norms and
                        specifications to ensure highest level of food safety.
                      </p>
                      {/* <a href="#">Read more</a> */}
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 mb-24'>
                    <div className='featured-card border-none'>
                      <h4>
                        HACCP (Hazard Analysis and Critical Control Points)
                      </h4>
                      <p className='font-lg line-height-2 mb-0'>
                        Our mithai and dry fruits are in the process of
                        acquiring HACCP certification. HACCP defines systematic
                        approach to ensuring that the food is safe from
                        chemical, biological and physical hazards and mitigate
                        potential risks for unsafe food.
                      </p>
                      {/* <a href="#">Read more</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why avarya */}
      <section className='pt-30'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='row mb-30'>
                <div className='col-lg-12'>
                  <h1 className='heading-1 mb-40 text-center'>Why Avarya ?</h1>
                </div>
                <div className='col-lg-12 text-center'>
                  <Image
                    src={graph02}
                    alt='nest'
                    className='mb-md-3 mb-lg-0 mb-sm-4'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4 col-md-6 mb-24'>
                  <div className='featured-card  bg-primary-2 border-none'>
                    <h4>Authentic</h4>
                    <p className='font-lg line-height-2 mb-0'>
                      At Avarya, we understand the needs of the Indian
                      consumers. We use ethnic recipes and a combination of
                      traditional and modern methods to bring distinct flavours
                      and tastes in mithais and farsans that linger for a long
                      time.
                    </p>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-24'>
                  <div className='featured-card bg-primary-2 border-none'>
                    <h4>Food Safety</h4>
                    <p className='font-lg line-height-2 mb-0'>
                      We employ processes that comply with the specifications
                      defined FSSAI (Food Safety and Standards Authority of
                      India) to ensure superior hygiene and zero health hazards.
                      Our manufacturing and packaging facility is also in the
                      process of getting HACCP certification, which is a global
                      certification for food safety.
                    </p>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-24'>
                  <div className='featured-card bg-primary-2 border-none'>
                    <h4>Extensive Experience</h4>
                    <p className='font-lg line-height-2 mb-0'>
                      Our directors bring with them rich experience and
                      expertise in the food and retail sector. We leverage their
                      experience to create maximum value for our customers,
                      thereby ensuring customer delight.
                    </p>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-24'>
                  <div className='featured-card bg-primary-2 border-none'>
                    <h4>Modern Infrastructure</h4>
                    <p className='font-lg line-height-2 mb-0'>
                      The manufacturing and packaging facility is equipped with
                      the latest technology to streamline our production
                      processes and enhance efficiency. When used along with
                      structured processes, this ensures highest levels of
                      quality and hygiene.
                    </p>
                  </div>
                </div>
                <div className='col-lg-8 col-md-6 mb-24'>
                  <div className='featured-card bg-primary-2 border-none'>
                    <h4>Diverse, Novel Offering</h4>
                    <p className='font-lg line-height-2 mb-0'>
                      We offer an array of delectable mithais, piquant namkeens
                      and scrumptious dry fruits to cater to different taste
                      buds. Our team devises innovative recipes that retain the
                      traditional taste, thereby making it enjoyable by all. We
                      offer a portfolio of products that is novel, unique and
                      yet original.
                    </p>
                    <p className='font-lg line-height-2 mb-0'>
                      Quality is our key core value. We ensure the best quality
                      at every stage of delivery. We use the purest of
                      ingredients, and employ tested recipes and robust
                      processes for manufacturing food products of unmatched
                      quality. We use high-quality packaging material to ensure
                      the products stay fresh and flavourful.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Status */}
      <section className='section-padding-12'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-10 col-lg-12 m-auto'>
              <div className='container mb-x d-none d-md-block'>
                <div className='row about-count'>
                  <div className='col-lg-1-5 col-md-6 text-center mb-lg-0 mb-md-5'>
                    <h1 className='heading-1'>
                      <span className='count'>12</span>+
                    </h1>
                    <h4>Glorious years</h4>
                  </div>
                  <div className='col-lg-1-5 col-md-6 text-center'>
                    <h1 className='heading-1'>
                      <span className='count'>36</span>+
                    </h1>
                    <h4>Happy clients</h4>
                  </div>
                  <div className='col-lg-1-5 col-md-6 text-center'>
                    <h1 className='heading-1'>
                      <span className='count'>58</span>+
                    </h1>
                    <h4>Projects complete</h4>
                  </div>
                  <div className='col-lg-1-5 col-md-6 text-center'>
                    <h1 className='heading-1'>
                      <span className='count'>24</span>+
                    </h1>
                    <h4>Team advisor</h4>
                  </div>
                  <div className='col-lg-1-5 text-center d-none d-lg-block'>
                    <h1 className='heading-1'>
                      <span className='count'>26</span>+
                    </h1>
                    <h4>Products Sale</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export { About };
