"use client";

import { Button, StarRating, TextArea } from "@/components/core-components";
import { addReview, useAppDispatch, useAppSelector } from "@/store";
import { ReviewSchema, STATUSES } from "@/utils";
import { Form, Formik } from "formik";

const ReviewForm = ({
  product_id,
  setReview,
}: {
  product_id: number;
  setReview: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector((state) => state.common);

  const handleSubmit = (values: any, actions: any) => {
    const reviewToAdd = {
      product_id: product_id,
      rating: values.rating,
      review_comment: values.review_comment,
      review_status: "pending",
      review_media: [],
    };
    dispatch(addReview({ params: reviewToAdd, actions: actions, setReview }));
  };

  return (
    <>
      <h4 className="mb-15">Add a review</h4>
      {/* <div className="row"> */}
      <div className="col-lg-8 col-md-12">
        <Formik
          initialValues={{
            rating: 0,
            review_comment: "",
            review_status: "pending",
            review_media: [],
          }}
          validationSchema={ReviewSchema}
          onSubmit={handleSubmit}
        >
          {(reviewProps) => (
            <Form className="form-contact comment_form">
              <div className="product-rating d-inline-block mb-30">
                <StarRating
                  totalStars={5}
                  setFieldValue={reviewProps.setFieldValue}
                  value={reviewProps.values.rating}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  {/* <FileUpload
                    name="files"
                    multiple
                    label="Upload Media"
                    setFieldValue={reviewProps.setFieldValue}
                    value={reviewProps.values.files}
                    onChange={(files: any) => {
                      console.log(files);
                    }}
                  /> */}
                  <TextArea
                    label=""
                    name="review_comment"
                    rows={10}
                    cols={5}
                    placeholder="Write Review"
                    formikProps={reviewProps}
                  />
                </div>
              </div>
              <Button
                className="btn btn-sm button-contactForm"
                loading={
                  state.status === STATUSES.LOADING &&
                  state.type === "add-review"
                }
                text="Submit Review"
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
      {/* </div> */}
    </>
  );
};

export { ReviewForm };
