"use client";

import { Slider } from "@/components/core-components";
import React from "react";
import { BannerSlide } from "./BannerSlide";
import { SwiperSlide } from "swiper/react";
import { GeneralSliderProps } from "@/utils";

const BannerSlider = ({
  data,
  prevBtn,
  nextBtn,
  className,
  btnClassName,
  slideClassName,
  widgetIndex,
}: GeneralSliderProps) => {
  return (
    <Slider
      prevBtn={prevBtn}
      nextBtn={nextBtn}
      className={`${className || ""}`}
      btnClassName={`${btnClassName || ""}`}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      slidesPerView={1}
      breakpoints={null}
      pagination={{
        clickable: true,
      }}
    >
      {data?.length > 0 &&
        data?.map((item: any, index: number) => (
          <SwiperSlide
            className={`${slideClassName || ""}`}
            key={index + 1}
            virtualIndex={index + 1}
          >
            <BannerSlide
              item={item}
              index={index + 1}
              widgetIndex={widgetIndex || 0}
            />
          </SwiperSlide>
        ))}
    </Slider>
  );
};

export { BannerSlider };
