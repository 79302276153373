"use client";

import Image from "next/image";
import React from "react";
import { Button, CustomInput, TextArea } from "../core-components";
import { Form, Formik } from "formik";
import { ContactUsSchema, FORM_CONSTANTS, STATUSES } from "@/utils";
import { contactUs, useAppDispatch, useAppSelector } from "@/store";

const ContactUs = () => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector((state) => state.common);

  return (
    <div className="page-content section-padding-12">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 m-auto">
            <section className="">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <div className="contact-from-area padding-20-row-col">
                    <h5 className="text-brand mb-10">Contact</h5>
                    <h1 className="mb-10 heading-2">Drop Us a Line</h1>
                    <p className="text-muted font-sm">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        contact_number: "",
                        subject: "",
                        message: "",
                      }}
                      onSubmit={(values, actions) => {
                        dispatch(
                          contactUs({
                            params: {
                              ...values,
                              contact_number: Number(values.contact_number),
                            },
                            actions,
                          })
                        );
                      }}
                      validationSchema={ContactUsSchema}
                    >
                      {(contactUsProps) => (
                        <Form
                          className="contact-form-style mt-15"
                          id="contact-form"
                        >
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <CustomInput
                                placeholder="First Name*"
                                formikProps={contactUsProps}
                                // label={"First Name"}
                                name={"name"}
                                type={FORM_CONSTANTS.TEXT}
                                titleCase={true}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <CustomInput
                                placeholder="Your Email*"
                                formikProps={contactUsProps}
                                // label={"First Name"}
                                name={"email"}
                                type={FORM_CONSTANTS.EMAIL}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <CustomInput
                                placeholder="Your Phone*"
                                formikProps={contactUsProps}
                                // label={"First Name"}
                                name={"contact_number"}
                                type={FORM_CONSTANTS.NUMBER}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <CustomInput
                                placeholder="Subject*"
                                formikProps={contactUsProps}
                                // label={"First Name"}
                                name={"subject"}
                                type={FORM_CONSTANTS.TEXT}
                              />
                            </div>
                            <div className="col-lg-12 col-md-12">
                              <div className="textarea-style mb-15">
                                <TextArea
                                  name="message"
                                  rows={10}
                                  cols={5}
                                  placeholder="Message"
                                  formikProps={contactUsProps}
                                />
                              </div>
                              <Button
                                className="submit submit-auto-width btn btn-sm"
                                type="submit"
                                text="Send message"
                                loading={
                                  state.status === STATUSES.LOADING &&
                                  state.type === "contact-us"
                                }
                              />
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <p className="form-messege"></p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ContactUs };
