"use client";

import React, { useEffect } from "react";
import { ListingWithFilters } from "../Listing";
import {
  clearWishlist,
  removeFromWishlist,
  setWholeWishlist,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { STATUSES, Toast, TOAST_CONSTANTS } from "@/utils";

const Wishlist = () => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector((state) => state.common);

  const { wishlist } = useAppSelector((state) => state.wishlist);

  useEffect(() => {
    dispatch(setWholeWishlist());
  }, []);

  return (
    <>
      {wishlist?.length > 0 ? (
        <ListingWithFilters
          type="wishlist"
          serverFilters={true}
          data={wishlist}
          title="Your Wishlist"
          filters={["All", "Popular", "Trending"]}
          search={true}
          count={wishlist?.length}
          total_pages={Math.ceil(wishlist?.length / 10)}
          extraFilters={true}
          clearBtnText={"Clear Wishlist"}
          clearBtnClick={() => {
            dispatch(clearWishlist());
          }}
          individualDelete={(product) => {
            dispatch(
              removeFromWishlist({
                wishlist_id: product.wishlist_id,
                onSuccess: () => {
                  Toast(
                    "Removed from wishlist",
                    TOAST_CONSTANTS.INFO,
                    <i className="fi-rs-heart"></i>
                  );
                },
              })
            );
          }}
        />
      ) : state.status === STATUSES.LOADING &&
        state.type === "fetch-wishlist" ? (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <span
            data-testid="loader"
            className={`spinner-border text-primary m-2`}
          ></span>
        </div>
      ) : (
        <h4 className="text-center section-padding-12">
          No items in your wishlist
        </h4>
      )}
    </>
  );
};

export { Wishlist };
