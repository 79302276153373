import { ProductHamperDeal, ProductMultipleHamperDeal } from "../Listing";

const Deals = ({
  config,
  widgetIndex,
  pageDetails,
}: {
  config: {
    content: {
      media: string;
      title: string;
      description: string;
      action: {
        link: string;
        text: string;
      };
    }[];
    main_title: string;
    main_sub_title?: string;
    main_description: string;
  };
  widgetIndex: number;
  pageDetails?: {
    display_name: string;
    short_summary: string;
  };
}) => {
  const renderDeals = () => {
    if (config.content.length > 1) {
      const data = {
        data: config.content.map((item) => {
          return {
            title: item.title,
            description: item.description,
            img: item.media,
            btnText: item.action.text,
            link: item.action.link,
          };
        }),
        main_title: config.main_title,
        main_description: config.main_description,
        main_sub_title: config.main_sub_title,
      };

      return (
        <ProductMultipleHamperDeal data={data} widgetIndex={widgetIndex} />
      );
    } else {
      const data = {
        title: config.content[0].title,
        main_title: pageDetails?.display_name || config.main_title,
        main_description: pageDetails?.short_summary || config.main_description,
        description: config.content[0].description,
        img: config.content[0].media,
        btnText: config.content[0].action.text,
        link: config.content[0].action.link,
      };
      return <ProductHamperDeal data={data} widgetIndex={widgetIndex} />;
    }
  };

  return <>{renderDeals()}</>;
};

export { Deals };
