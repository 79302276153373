"use client";

import { GeneralSliderProps } from "@/utils";
import Image from "next/image";
import Link from "next/link";
import { HorizontalListing } from "../HorizontalListing";
import { SwiperSlide } from "swiper/react";
import { CategoryItem } from "./CategoryItem";
import { useRouter } from "next/navigation";
import { Fragment, useCallback, useMemo } from "react";
import { STATIC_CONSTANTS } from "../../../../utils/constants";

const CategoryHeaderListing = ({
  data,
}: {
  data?: {
    id: number;
    title: string;
    img: string;
    link: string;
    alt: string;
  }[];
}) => {
  const router = useRouter();

  // const element = document.getElementById("browse-all-categories");

  return (
    <div className="d-flex categori-dropdown-inner">
      <ul className="">
        {data?.map((category, index) => {
          if (index % 2 === 0) {
            return (
              <li
                key={index}
                onClick={() => {
                  // element?.classList?.toggle("hover");
                }}
              >
                <Link
                  href={`/${category.link}`}
                  className="browse-category-link"
                  content="category"
                  // onClick={() => {
                  //   router.replace(category.link);
                  // }}
                >
                  <Image
                    width={20}
                    height={20}
                    className="w-100 h-100"
                    src={
                      category.img || "/assets/imgs/theme/icons/category-1.svg"
                    }
                    alt={category.alt || "nest"}
                  />
                  <span>{category.title}</span>
                </Link>
              </li>
            );
          }
        })}
      </ul>
      <ul className="">
        {data?.map((category, index) => {
          if (index % 2 !== 0) {
            return (
              <li
                key={index}
                onClick={() => {
                  // element?.classList?.toggle("hover");
                }}
              >
                <Link
                  href={`/${category.link}`}
                  className="browse-category-link"
                  content="category"
                  // onClick={() => {
                  //   router.replace(category.link);
                  // }}
                >
                  <Image
                    width={20}
                    height={20}
                    className="w-100 h-100"
                    src={
                      category.img || "/assets/imgs/theme/icons/category-2.svg"
                    }
                    alt={category.alt || "nest"}
                  />
                  <span>{category.title}</span>
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

const CategoryHorizontalListing = ({
  last,
  data: initialData,
  title,
  prevBtn,
  nextBtn,
  parentClassName,
  btnClassName,
  sliderClassName,
  slideClassName,
  sliderId,
  slidesPerView = 6,
  spaceBetween,
  autoplay,
  containerClassName,
  hasDesc = false,
  pagination,
  widgetIndex = 0,
  type,
  lengthToRender = 0,
  onNavigationNext,
}: GeneralSliderProps) => {
  const renderCategory = useMemo(() => {
    return initialData?.map((item: any, index: number) => {
      return (
        <>
          <SwiperSlide
            className={`category-${index + 1} ${slideClassName || ""}`}
            key={index}
            virtualIndex={index}
          >
            <CategoryItem
              key={index}
              item={item}
              index={index}
              hasDesc={hasDesc}
              widgetIndex={widgetIndex || 0}
            />
          </SwiperSlide>
          {!widgetIndex &&
          initialData.length % 6 === 0 &&
          initialData.length - 1 === index ? (
            <SwiperSlide
              className={`category-${index + 1}`}
              key={index + 1}
              virtualIndex={index + 1}
            >
              <div className="h-100 d-flex flex-row justify-content-center align-items-center">
                <span
                  className="text-primary spinner-border align-self-center"
                  aria-hidden="true"
                />
              </div>
            </SwiperSlide>
          ) : null}
        </>
      );
    });
  }, [initialData?.length]);

  return (
    <Fragment key={`${widgetIndex || 0}-${type}-category-listing-fragment`}>
      <HorizontalListing
        key={type}
        type={type}
        autoplay={autoplay}
        title={title}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        prevBtn={prevBtn}
        nextBtn={nextBtn}
        parentClassName={parentClassName}
        btnClassName={btnClassName}
        sliderClassName={sliderClassName}
        sliderId={sliderId}
        pagination={pagination}
        dataLength={initialData?.length}
        toRender={initialData?.length >= lengthToRender}
        onNavigationNext={onNavigationNext}
        last={last}
      >
        {initialData?.length >= lengthToRender ? renderCategory : null}
      </HorizontalListing>
    </Fragment>
  );
};

export { CategoryHeaderListing, CategoryHorizontalListing };
