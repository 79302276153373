"use client";

import { ProductItem } from "./ProductItem";
import { HorizontalListing } from "../HorizontalListing";
import { SwiperSlide } from "swiper/react";
import { GeneralSliderProps, IProduct } from "@/utils";
import { useMemo } from "react";

const ProductShimmerItem = () => {
  return (
    <div className={`product-cart-wrap mb-18 `}>
      <div className="product-img-action-wrap">
        <div className="product-img product-img-zoom mx-1">
          <span
            className="skeleton skeleton-text default-img"
            style={{
              display: "block",
              width: "200px",
              height: "200px",
            }}
          ></span>
        </div>
      </div>

      <div className={`product-content-wrap `}>
        <h2>
          <span className="skeleton skeleton-text d-block w-100"></span>
        </h2>

        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="product-rate-cover">
            <span className="skeleton skeleton-text d-block w-100"></span>
          </div>
        </div>

        <div className="product-card-bottom mt-0 flex-column">
          <div className="d-flex flex-row-align-items-center justify-content-between">
            <div className="product-price d-flex flex-row align-items-center mb-5">
              <span className="d-flex flex-row align-items-start">
                <span className="rupee-symbol">₹</span>
                <span className="skeleton skeleton-text d-block w-100"></span>
              </span>
              <span className="old-price">
                <span className="skeleton skeleton-text d-block w-100"></span>
              </span>
            </div>
            <a className="w-max-content quickview-link text-center wishlist-link-icon">
              <i className="fi-rs-heart"></i>
            </a>
          </div>
          <div className="add-to-cart-buttons d-flex justify-content-between"></div>

          <div className="add-cart d-flex gap-2 mt-5">
            <a className="add w-100 text-center">
              <i className="fi-rs-shopping-cart mr-5"></i>
              Add to Cart
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductListing = ({
  type,
  products,
  deleteBtn,
  hasDesc = false,
  largeCard = false,
  widgetIndex,
}: {
  type?: string;
  products: IProduct[];
  deleteBtn?: (value: any) => void;
  largeCard?: boolean;
  hasDesc?: boolean;
  widgetIndex: number;
}) => {
  return (
    // <>
    <>
      {products?.length > 0 ? (
        products?.map((product, i) => (
          <div
            className={`col-md-4 col-sm-6 col-6 col-lg-${
              deleteBtn ? "1-5" : largeCard ? "3" : "2"
            }`}
            key={i}
          >
            <ProductItem
              type={type}
              widgetIndex={widgetIndex}
              index={i}
              product={product}
              hasDesc={hasDesc}
              deleteBtn={deleteBtn}
              largeCard={largeCard}
            />
          </div>
        ))
      ) : (
        <div className="col-12">
          <p>No Products Found</p>
        </div>
      )}
    </>

    // Array.from({ length: 12 }).map((_, index: number) => (
    //     <div
    //       className={`col-md-4 col-sm-6 col-6 col-lg-${
    //         deleteBtn ? "1-5" : largeCard ? "3" : "2"
    //       }`}
    //       key={index}
    //     >
    //       <ProductShimmerItem />
    //     </div>
    //   ))}
    // </>
  );
};

const ProductHorizontalListing = ({
  data: initialData,
  title,
  prevBtn,
  nextBtn,
  parentClassName,
  btnClassName,
  sliderClassName,
  sliderId,
  slidesPerView = 6,
  spaceBetween,
  autoplay,
  hasDesc = false,
  widgetIndex,
  type,
  lengthToRender = 0,
  centered = false,
  onNavigationNext,
}: GeneralSliderProps) => {
  const renderProducts = useMemo(() => {
    return initialData.map((item: any, index: number) => (
      <>
        <SwiperSlide
          className={`product-${index + 1}`}
          key={index}
          virtualIndex={index}
        >
          <ProductItem
            widgetIndex={widgetIndex || 0}
            product={item}
            key={index}
            hasDesc={hasDesc}
            index={index}
          />
        </SwiperSlide>

        {!widgetIndex &&
        initialData.length % 6 === 0 &&
        initialData.length - 1 === index ? (
          <SwiperSlide
            className={`product-${index + 1}`}
            key={index + 1}
            virtualIndex={index + 1}
          >
            <div className="h-100 d-flex flex-row justify-content-center align-items-center">
              <span
                className="text-primary spinner-border align-self-center"
                aria-hidden="true"
              />
            </div>
          </SwiperSlide>
        ) : null}
      </>
    ));
  }, [initialData.length, initialData.length % 6 === 0]);

  return (
    <HorizontalListing
      type={type}
      autoplay={autoplay}
      title={title}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      prevBtn={prevBtn}
      nextBtn={nextBtn}
      parentClassName={parentClassName}
      btnClassName={btnClassName}
      sliderClassName={sliderClassName}
      sliderId={sliderId}
      onNavigationNext={onNavigationNext}
      centered={centered}
      dataLength={initialData.length}
      toRender={initialData.length >= lengthToRender}
    >
      {initialData.length >= lengthToRender ? renderProducts : null}
    </HorizontalListing>
  );
};

export { ProductListing, ProductHorizontalListing };
