"use client";

import {
  API_ROUTES,
  FORM_CONSTANTS,
  isNumber,
  REGISTER_CONSTANTS,
  RegisterSchema,
  STATUSES,
} from "@/utils";
import { Form, Formik } from "formik";
import Link from "next/link";
import React from "react";
import { Button, CustomInput } from "./core-components";
import { registerUser, useAppDispatch, useAppSelector } from "@/store";
import parse from "html-react-parser";
import { useRouter } from "next/navigation";

const Register = () => {
  const dispatch = useAppDispatch();

  const { auth_state, register_details } = useAppSelector(
    (state) => state.auth
  );

  const router = useRouter();

  const handleSubmit = (values: any, actions: any) => {
    dispatch(registerUser({ data: values, router }));
  };

  return (
    <div className="page-content register-page-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8 col-md-12 col-sm-8 text-center">
            <div className="heading_s1 text-start mb-20">
              <h1 className="mb-5 font-32">Create Account</h1>
              <p>
                Already a customer?&nbsp;
                <Link href={API_ROUTES.LOGIN} replace={true}>
                  Sign in
                </Link>
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="login_wrap widget-taber-content background-white">
                  <div className="padding_eight_all bg-white">
                    <Formik
                      initialValues={{
                        name: "",
                        contact_number: isNumber(register_details)
                          ? register_details
                          : "",
                        country_code: "+91",
                        // whatsapp_number: "",
                        email: isNumber(register_details)
                          ? ""
                          : register_details,
                      }}
                      onSubmit={handleSubmit}
                      validationSchema={RegisterSchema}
                    >
                      {(props) => (
                        <Form>
                          <div className="row align-items-center">
                            <div className="col-3">
                              <CustomInput
                                required
                                formikProps={props}
                                name={REGISTER_CONSTANTS.COUNTRY_CODE.name}
                                type={FORM_CONSTANTS.TEXT}
                              />
                            </div>
                            <div className="col-9">
                              <CustomInput
                                required
                                placeholder={
                                  REGISTER_CONSTANTS.CONTACT_NUMBER.placeholder
                                }
                                formikProps={props}
                                name={REGISTER_CONSTANTS.CONTACT_NUMBER.name}
                                type={FORM_CONSTANTS.NUMBER}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <CustomInput
                              required
                              placeholder={REGISTER_CONSTANTS.NAME.placeholder}
                              formikProps={props}
                              name={REGISTER_CONSTANTS.NAME.name}
                              type={FORM_CONSTANTS.TEXT}
                              titleCase={true}
                            />
                          </div>
                          <div className="row">
                            <CustomInput
                              required
                              placeholder={REGISTER_CONSTANTS.EMAIL.placeholder}
                              formikProps={props}
                              name={REGISTER_CONSTANTS.EMAIL.name}
                              type={FORM_CONSTANTS.EMAIL}
                            />
                          </div>
                          <div className="text-xs text-start pb-10">
                            {parse(
                              `By continuing, you agree to Avarya's <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>`
                            )}
                          </div>
                          <Button
                            loading={auth_state === STATUSES.LOADING}
                            type="submit"
                            text="Register"
                            className="btn btn-sm font-weight-bold fw-bold w-100"
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Register };
