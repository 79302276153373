"use client";

import Link from "next/link";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import heart from "../../../../../public/assets/imgs/theme/icons/icon-heart.svg";
import heartFilled from "../../../../../public/assets/imgs/theme/icons/icon-heart-filled.svg";
import {
  calculatePrice,
  handleCart,
  IProduct,
  STATUSES,
  strip_html_tags,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { Button, CustomSelect, Ratings } from "@/components/core-components";
import {
  addPendingItem,
  addToWishlist,
  incrementCartItemsCounter,
  removeFromWishlist,
  setOffCanvas,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { useRouter } from "next/navigation";

const ProductItem = ({
  type,
  index,
  product,
  hasDesc,
  deleteBtn,
  largeCard = false,
  widgetIndex,
}: {
  type?: string;
  index: number;
  product: IProduct;
  hasDesc: boolean;
  deleteBtn?: (value: any) => void;
  largeCard?: boolean;
  widgetIndex: number;
}) => {
  const dispatch = useAppDispatch();

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { state } = useAppSelector((state) => state.common);

  const { status: wishlistStatus, wishlist } = useAppSelector(
    (state) => state.wishlist
  );

  const router = useRouter();

  const [weight, setWeight] = useState<
    {
      id: number;
      value: number;
      label: string;
      uom: string;
    }[]
  >([]);

  const [selectedItem, setSelectedItem] = useState<number>();

  const canAddtoWishlist = wishlist.find(
    (item: any) => item.id === product.id
  )?.wishlist_id;

  const AddToCart = (product: IProduct) => {
    handleCart({
      dispatch: dispatch,
      is_logged_in: logged_in_user || access_token,
      product: product,
      selected_weight: weight,
      onSuccess() {
        dispatch(setOffCanvas({ state: true, component: "cart" }));
        dispatch(incrementCartItemsCounter());
      },
    });
  };

  const handleWishlist = (product: any) => {
    if (logged_in_user || access_token) {
      dispatch(
        addToWishlist({
          product_id: product.id,
          onSucces: () => {
            Toast(
              "Added to Wishlist !",
              TOAST_CONSTANTS.SUCCESS,
              <i className="fi-rs-heart"></i>
            );
          },
        })
      );
    } else {
      dispatch(addPendingItem(product.id));
      router.push("/account/login");
    }
  };

  useEffect(() => {
    setWeight([product?.weights?.[0]]);
  }, [product?.id]);

  return (
    <>
      <div
        className={`product-cart-wrap mb-18 ${
          deleteBtn ? "wishlist-card" : largeCard ? "single-product-3" : ""
        }`}
      >
        <div className="product-img-action-wrap">
          <div className="product-img product-img-zoom">
            <Link href="/[...slug]" as={`/${product.slug}`}>
              {largeCard && <div className="overlay bottom-0"></div>}
              <Image
                width={200}
                height={200}
                className="w-100 h-100 default-img"
                src={
                  product?.thumbnails?.[0]?.img ||
                  product?.images?.[0]?.img ||
                  "/assets/images/products/product-1-1.jpg"
                }
                alt={product.title}
                priority={widgetIndex <= 2}
              />
            </Link>
          </div>

          <div className="product-badges product-badges-position product-badges-mrg">
            {!largeCard && product.discount.value > 0 && (
              <span className="sale">Sale</span>
            )}
            {!largeCard && product.discount.value >= 5 && (
              <span className="bg-yellow">
                {`${
                  product.discount.type === "percentage"
                    ? `${product.discount.value}%`
                    : `₹${product.discount.value}`
                }`}
              </span>
            )}
          </div>
        </div>

        <div
          className={`product-content-wrap ${
            largeCard ? "large-card-content text-center" : ""
          }`}
        >
          <h2 className={`${largeCard ? "mb-5" : ""}`}>
            <Link href="/[slug]" as={`/${product.slug}`}>
              {product.title}
            </Link>
          </h2>

          {largeCard && (
            <div className="product-card-bottom mt-0 mb-5">
              <div className="product-price pt-0">
                {product.oldPrice > 0 ? (
                  <span className="old-price mr-10">
                    <span className="rupee-symbol">₹</span>
                    {product.oldPrice}
                  </span>
                ) : null}
                <span>
                  <span className="rupee-symbol">₹</span>
                  {`${
                    product.selling_uom === "pcs"
                      ? product.price
                      : calculatePrice(1000, product.price, weight?.[0]?.value)
                  } `}
                </span>
              </div>
            </div>
          )}

          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="product-rate-cover">
              <Ratings rating={product.rating} />
            </div>
            {!largeCard && (
              <CustomSelect
                id={`product-item-${widgetIndex}-${index}-${product.title.replace(
                  /\s/g,
                  "-"
                )}-weight`}
                onlyLabel={product.weights?.length === 1 || false}
                options={product.weights?.map((w: any) => ({
                  value: w.value,
                  label: `${w.label} ${w.uom}`,
                }))}
                name="weight"
                onChange={(e: any) => {
                  setWeight(
                    product.weights.filter(
                      (w: any) => w.value === Number(e.target.value)
                    )
                  );
                }}
              />
            )}
          </div>

          {hasDesc && !largeCard && (
            <p className="font-xs product-description line-height-1-5">
              {strip_html_tags(product.description)}
            </p>
          )}

          {!largeCard && (
            <div className="product-card-bottom mt-0 flex-column">
              <div className="d-flex flex-row-align-items-center justify-content-between">
                <div className="product-price d-flex flex-row align-items-center mb-5">
                  <span className="d-flex flex-row align-items-start">
                    <span className="rupee-symbol">₹</span>
                    {`${
                      product.selling_uom === "pcs"
                        ? product.price
                        : calculatePrice(
                            1000,
                            product.price,
                            weight?.[0]?.value
                          )
                    } `}
                  </span>
                  <span className="old-price">
                    {product.oldPrice > 0 ? (
                      <span>
                        <span className="rupee-symbol">₹</span>
                        {product.oldPrice}
                      </span>
                    ) : null}
                  </span>
                </div>
                {type !== "wishlist" && (
                  <a
                    className="w-max-content quickview-link text-center wishlist-link-icon"
                    onClick={() => {
                      if (product.wishlist_added || canAddtoWishlist) {
                        dispatch(
                          removeFromWishlist({
                            wishlist_id: canAddtoWishlist,
                            onSuccess: () => {
                              Toast(
                                "Removed from wishlist",
                                TOAST_CONSTANTS.INFO,
                                <i className="fi-rs-heart"></i>
                              );
                            },
                          })
                        );
                        // Toast(
                        //   "Already added in wishlist",
                        //   TOAST_CONSTANTS.INFO,
                        //   <i className="fi-rs-heart"></i>
                        // );
                      } else {
                        handleWishlist(product);
                      }
                    }}
                  >
                    {/* <i className="fi-rs-heart"></i> */}
                    <Image
                      width={20}
                      height={20}
                      className="svgInject filter-black-primary"
                      alt="wishlist"
                      src={!canAddtoWishlist ? heart : heartFilled}
                    />
                  </a>
                )}
              </div>
              <div className="add-to-cart-buttons d-flex justify-content-between">
                {/* Delete Item */}
                {deleteBtn && (
                  <div className="add-cart mb-10 w-100 border-none">
                    <Button
                      className="btn add w-100 text-center bg-red text-white"
                      text="Delete"
                      type={"button"}
                      loading={
                        selectedItem === index &&
                        wishlistStatus === STATUSES.LOADING
                      }
                      onClick={() => {
                        deleteBtn(product);
                        setSelectedItem(index);
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="add-cart d-flex gap-2 mt-5">
                <a
                  className="add w-100 text-center"
                  onClick={() => {
                    AddToCart(product);
                  }}
                >
                  {(logged_in_user || access_token) &&
                  state.status === STATUSES.LOADING &&
                  state.type === `add-cartItem-${product.id}` ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        aria-hidden="true"
                      />
                      &nbsp;
                    </>
                  ) : (
                    <>
                      <i className="fi-rs-shopping-cart mr-5"></i>
                      Add to Cart
                    </>
                  )}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { ProductItem };
