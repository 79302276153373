"use client";

import { useState } from "react";
import { ReviewForm } from "./ReviewForm";
import { ReviewList } from "./ReviewList";
import { useAppSelector } from "@/store";

const ProductReviews = ({
  product_id,
  setModal,
  reviews,
}: {
  product_id: number;
  reviews: any;
  setModal: React.Dispatch<
    React.SetStateAction<{
      state: boolean;
      content: {
        media_url: string;
        media_type: string;
      };
    }>
  >;
}) => {
  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const is_logged_in = logged_in_user || access_token;

  const [reviewSection, setReviewSection] = useState(false);

  return (
    <section
      className={`section-padding-12 ${
        !is_logged_in && reviews?.length === 0 ? "visually-hidden" : ""
      }`}
    >
      <div className="container">
        <div className="row pb-0">
          <div className="col-12">
            <h3 className="section-title style-1 mb-0 pb-10">
              Customer Reviews
            </h3>
          </div>
          <div className="col-12">
            <ReviewList
              reviews={reviews}
              reviewSection={reviewSection}
              setReviewSection={setReviewSection}
              setModal={setModal}
            />
            {reviewSection && (
              <ReviewForm
                product_id={product_id}
                setReview={setReviewSection}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export { ProductReviews };
