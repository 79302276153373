"use client";

import Image from "next/image";
import React from "react";

const Banner = ({
  src,
  alt,
  className,
  imageClassName,
  parentClassName,
  children,
  widgetIndex,
}: {
  src: string;
  alt: string;
  className?: string;
  imageClassName?: string;
  parentClassName?: string;
  children?: React.ReactNode;
  widgetIndex: number;
}) => {
  return (
    <section className={`${parentClassName ? parentClassName : ""}`}>
      <div className={`full-width-banner ${className ? className : ""}`}>
        {children}
        <Image
          alt={alt}
          src={src}
          width={1600}
          height={600}
          className={`w-100 h-100 ${imageClassName ? imageClassName : ""}`}
          priority={widgetIndex <= 2}
        />
        {/* {widgetIndex === 0 ? (
          <div className={`section-border-top mx-0`} />
        ) : null} */}
      </div>
    </section>
  );
};

export { Banner };
