import { IVideoBanner } from "@/utils";
import React from "react";

const BannerVideo = ({ data }: { data: IVideoBanner }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="row align-items-center mb-x">
            <div className="col-lg-12 text-center">
              {data.subTitle && (
                <p className="mb-10 text-primary font-xl montserrat-600 title-1">
                  {data.subTitle}
                </p>
              )}
              <h2 className="mb-10 font-xxl prata-regular text-extra-dark">
                {data.title}
              </h2>
              <p className="mb-15 font-lg line-height-2">{data.description}</p>
            </div>
            <div className="col-lg-12">
              <div className="video-wrapper">
                <iframe
                  className="video-player"
                  src={data.videoUrl}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BannerVideo };
