"use client";

import { CustomSelect } from "@/components/core-components";
import { filterNullElements, usePagination } from "@/utils";
import { Form, Formik } from "formik";
import React from "react";
import moment from "moment";

const OrderFilters = () => {
  const { additionalFilter, searchParams } = usePagination();

  const yearOptions = filterNullElements(
    Array.from({ length: 5 }, (_, i) => {
      const year = moment().subtract(i, "years").format("YYYY");
      if (Number(year) < 2024) return null;
      return { value: year, label: year };
    })
  );

  return (
    <div className="sort-by-product-area">
      <div className="sort-by-cover">
        <Formik
          initialValues={{
            year: searchParams.get("year") || moment().format("YYYY"),
          }}
          onSubmit={(values, actions) => {}}
        >
          {(sortProps) => (
            <Form>
              <CustomSelect
                name="year"
                formikProps={sortProps}
                options={yearOptions}
                inline={{
                  title: "Year:",
                  icon: "fi-rs-apps-sort",
                }}
                onBlur={(e: any) => {
                  additionalFilter("year", e.target.value.toString());
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { OrderFilters };
