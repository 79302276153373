import {
  apiRequest,
  formatProduct,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
  transformDataToBannerConfig,
  transformDataToListingConfig,
  transformDataToMultipleListingConfig,
  transformDataToProductDealsConfig,
  WIDGET_CONSTANTS,
} from "@/utils";
import { notFound } from "next/navigation";
import {
  BannerWidget,
  Deals,
  ListingWidet,
  MulipleListing,
} from "./group-components";

import parse from "html-react-parser";
import React from "react";
import { StructuredData } from "./StructuredData";

const Widget = ({
  pageData,
  is_logged_in = false,
}: {
  pageData?: any;
  is_logged_in?: boolean;
}) => {
  const renderWidgets = () => {
    if (!pageData || (Array.isArray(pageData) && pageData.length === 0)) {
      return notFound();
    }

    const widgets = pageData.content.map((item: any, index: number) => {
      return {
        page_id: pageData.id,
        id: item.id,
        widget_id: item.widget.id,
        widget_type: item.widget.widget_type,
        content: item.content,
        type: item.pages
          ? "static"
          : item.product_category_cms
          ? "category"
          : item.product_subcategory_cms
          ? "sub_category"
          : "product",
      };
    });

    if (widgets.length === 0) {
      return notFound();
    } else {
      return widgets?.map(async (widget: any, index: number) => {
        switch (widget.widget_type) {
          case WIDGET_CONSTANTS.BANNER:
            return (
              <BannerWidget
                config={transformDataToBannerConfig(widget)}
                widgetIndex={index}
                pageDetails={{
                  display_name: pageData?.display_name,
                  short_summary: pageData?.short_summary,
                }}
              />
            );

          case WIDGET_CONSTANTS.LISTING:
            let listingConfig = transformDataToListingConfig(widget);

            if (listingConfig?.content?.length === 0) {
              const containsCategorySubCategory =
                listingConfig?.fetchUrl.includes("category") ||
                listingConfig?.fetchUrl.includes("subcategory");

              const endpoint = `${STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL}${
                listingConfig?.fetchUrl
              }${containsCategorySubCategory ? "&" : "?"}page=${1}&limit=${
                containsCategorySubCategory ? 24 : 12
              }`;

              let response;

              if (is_logged_in) {
                response = await apiRequest({
                  url: endpoint,
                  method: REQUEST_METHOD.GET,
                  additionalHeaders: {
                    "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
                  },
                  tags: ["page-data"],
                }).then((res) => res.json());
              } else {
                response = await fetch(endpoint, {
                  method: REQUEST_METHOD.GET,
                  headers: {
                    "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
                  },
                  next: {
                    tags: ["page-data"],
                  },
                }).then((res) => res.json());
              }

              if (response?.result?.results?.length > 0) {
                listingConfig.content =
                  response?.result?.results.map(formatProduct);

                listingConfig.totalPages = Math.ceil(
                  response.result.count / response.result.page_size
                );

                listingConfig.count = response.result.count;
              }
            }

            return (
              <ListingWidet
                config={listingConfig}
                type={widget.type}
                widgetIndex={index}
                noOfWidget={pageData?.content?.length}
              />
            );

          case WIDGET_CONSTANTS.MULTIPLE_LISTING:
            return (
              <MulipleListing
                config={transformDataToMultipleListingConfig(widget)}
                widgetIndex={index}
                pageDetails={{
                  display_name: pageData?.display_name,
                  short_summary: pageData?.short_summary,
                }}
              />
            );

          case WIDGET_CONSTANTS.DEALS:
            return (
              <Deals
                config={transformDataToProductDealsConfig(widget)}
                widgetIndex={index}
                pageDetails={{
                  display_name: pageData?.display_name,
                  short_summary: pageData?.short_summary,
                }}
              />
            );

          case WIDGET_CONSTANTS.STATIC:
            return parse(widget?.content?.[0]?.value);

          default:
            return notFound();
        }
      });
    }
  };

  return (
    <React.Fragment key={pageData?.meta_slug || pageData?.id}>
      <StructuredData />
      {renderWidgets()}
    </React.Fragment>
  );
};

export { Widget };
