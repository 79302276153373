import React from "react";
import Link from "next/link";
import Image from "next/image";
import parse from "html-react-parser";

import phonecall from "../../public/assets/imgs/theme/icons/phone-call.svg";
import whatapp from "../../public/assets/imgs/theme/icons/icon-whatsapp.svg";

const FooterShimmer = () => {
  return (
    <div
      className="footer-link-widget col wow animate__animated animate__fadeInUp"
      data-wow-delay=".1s"
    >
      <h4 className="widget-title">
        <span
          className="skeleton skeleton-text"
          style={{ width: "200px" }}
        ></span>
      </h4>
      <ul className="footer-list  mb-sm-4 mb-md-0">
        {Array.from({ length: 4 }).map((child: any, childIndex: number) => (
          <li key={childIndex}>
            <Link href={"#"}>
              <span
                className="skeleton skeleton-text"
                style={{ width: "200px" }}
              ></span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Footer = ({ data: footer }: { data: any }) => {
  const renderFooterMenu = () => {
    return (
      <React.Fragment key={footer?.menu_title || "footer"}>
        <div className="footer-bottom mtb-12"></div>
        <div className="row text-start text-md-center">
          {footer && footer.length > 0
            ? footer.map((item: any, index: number) => (
                <div
                  key={`footer-item-${index}`}
                  className="footer-link-widget col wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  <h5 className="widget-title">{item.menu_item_title}</h5>
                  <ul className="footer-list mb-sm-4 mb-md-0">
                    {item.children.map((child: any, index: number) => (
                      <li key={index}>
                        <Link href={`/${child.slug}`}>
                          {child.menu_item_title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            : Array.from({ length: 4 }).map((_: any, index: number) => (
                <FooterShimmer key={index} />
              ))}
        </div>
      </React.Fragment>
    );
  };

  return (
    <>
      <footer className="main">
        <section className="bg-primary-2 section-padding-12 footer-mid">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <div
                  className="widget-about font-md wow animate__animated animate__fadeInUp d-flex justify-content-center flex-column"
                  data-wow-delay="0"
                >
                  <div className="logo mb-30">
                    <Link href="/" className="mb-15">
                      <Image
                        src={
                          "https://avaryaallimages.s3.ap-south-1.amazonaws.com/site-settings/icon/avarya-brand-logo.png"
                        }
                        alt="logo"
                        className="w-100"
                        height={120}
                        width={300}
                      />
                    </Link>
                    <p className="font-md line-height-2 text-heading">
                      Avarya Retail Pvt Ltd is a manufacturer and retailer of a
                      range of delectable mithais, namkeens and dry fruits. A
                      premium quality food store, Avarya was established in an
                      endeavour to reviving the traditional flavours of India by
                      bringing authentic sweets and snacks, along with exotic
                      dry fruits to urban consumers. We also offer a range of
                      gifting solutions to corporate organizations in
                      customizable packages for meeting their requirements.
                    </p>
                  </div>

                  <ul className="row contact-infor d-flex justify-content-center">
                    <li className="d-flex flex-column col-lg-3 col-md-3 col-sm-6 col-6 mb-30 col-6 mb-30">
                      <i className="fi fi-rs-headphones"></i>
                      <div className="d-flex flex-row align-items-center justify-content-start justify-content-md-center">
                        <Image
                          width={50}
                          height={50}
                          className="h-100 w-100 mr-10"
                          src={phonecall}
                          alt="hotline"
                        />
                        <span className="address-business-link">
                          <Link href={`tel:9152414320`}>
                            (+91) - 9152414320
                          </Link>
                        </span>
                      </div>
                      <div className="d-flex flex-row align-items-center justify-content-start justify-content-md-center">
                        <Image
                          width={50}
                          height={50}
                          className="h-100 w-100 mr-10"
                          src={whatapp}
                          alt="hotline"
                        />
                        <span className="address-business-link">
                          <Link
                            target="_blank"
                            href={`https://api.whatsapp.com/send?phone=919152414230`}
                          >
                            (+91) - 9152414230
                          </Link>
                        </span>
                      </div>
                    </li>
                    <li className="d-flex flex-column col-lg-3 col-md-3 col-sm-6 col-6 mb-30 col-6 mb-30">
                      <i className="fi fi-rs-marker"></i>
                      <span className="address-business-link">
                        <Link
                          href={
                            "https://www.google.com/search?sca_esv=1671dfeb145c5278&tbs=lf:1,lf_ui:10&tbm=lcl&sxsrf=ADLYWIL9Vh6CTPvbJXOf-rpUhsFaRUS2tg:1722240120666&q=Avarya&rflfq=1&num=10&sa=X&ved=2ahUKEwi-xfC05MuHAxXn4zgGHdhcAcAQjGp6BAgnEAE&biw=1536&bih=745&dpr=1#rlfi=hd:;si:;mv:[[19.206521499999997,73.0439797],[18.9330049,72.7829876]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10"
                          }
                          target="_blank"
                        >
                          Shop No 5 to 9, Avighna 9, Lalbaug, Mumbai - 400012
                        </Link>
                      </span>
                    </li>
                    <li className="d-flex flex-column col-lg-3 col-md-3 col-sm-6 col-6 mb-30 col-6 mb-30">
                      <i className="fi fi-rs-clock"></i>
                      <span>
                        10:00 - 18:00
                        <br />
                        Mon - Sat
                      </span>
                    </li>
                    <li className="d-flex flex-column col-lg-3 col-md-3 col-sm-6 col-6 mb-30 col-6 mb-30">
                      <i className="fi fi-rs-envelope"></i>
                      <span className="address-business-link">
                        <Link href={"mailto:info@avarya.in"}>
                          info@avarya.in
                        </Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {renderFooterMenu()}
          </div>
        </section>
        <section className="bg-primary-2">
          <div className="container pb-10 bg-primary-2">
            <div className="col-12">
              <div className="pb-10 footer-bottom"></div>
            </div>
            <div className="container row">
              <div className="col-12">
                <p className="font-sm mb-0 copyright px-0">
                  {parse(
                    '&copy; 2024 <strong className="text-brand">Avarya Retail Pvt Ltd.</strong> All rights reserved'
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export { Footer, FooterShimmer };
