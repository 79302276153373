"use client";

import {
  CategoryHorizontalListing,
  DealsList,
  ListingWithFilters,
  ProductHorizontalListing,
} from "../Listing";
import { Fragment, useEffect, useState } from "react";
import {
  resetFilters,
  resetListingProducts,
  setListingProductsFromWidget,
  useAppDispatch,
  useAppSelector,
} from "@/store";

const ListingWidet = ({
  config,
  widgetIndex,
  type,
  noOfWidget,
}: {
  config: {
    type: string;
    title: string;
    content: any;
    filters: any;
    extraFilters: any;
    largeCard: boolean;
    fetchUrl: string;
    totalPages?: number;
    count?: number;
  };
  noOfWidget?: number;
  widgetIndex: number;
  type: "category" | "sub_category" | "product" | "static";
}) => {
  const dispatch = useAppDispatch();
  const { listingProducts } = useAppSelector((state) => state.common);

  const renderListing = () => {
    switch (config.type) {
      case "product-grid":
        return (
          // <div className="container mb-30">
          //   <div className="row">
          //     <div className="col-lg-12">
          <ListingWithFilters
            key={`${config?.title}-${widgetIndex}` || ""}
            total_pages={config?.totalPages}
            count={config?.count}
            type={type}
            widgetIndex={widgetIndex}
            hasDesc={type === "category"}
            // data={listingProducts[`widget-${widgetIndex}`]}
            data={config?.content}
            title={config?.title || ""}
            filters={config.filters || []}
            extraFilters={config.extraFilters}
            largeCard={config.largeCard}
            fetchUrl={config.fetchUrl}
            last={noOfWidget === widgetIndex + 1}
          />
          //     </div>
          //   </div>
          // </div>
        );

      case "product-carousel":
        return (
          <ProductHorizontalListing
            lengthToRender={2}
            key={`${config?.title}-${widgetIndex}` || ""}
            widgetIndex={widgetIndex}
            hasDesc={false}
            slidesPerView={6}
            spaceBetween={30}
            prevBtn={"custom_prev_2"}
            nextBtn={"custom_next_2"}
            parentClassName="section-padding-12"
            btnClassName="slider-arrow-2 carausel-6-columns-arrow"
            sliderClassName="col-12"
            sliderId="row related-products position-relative"
            data={config.content}
            title={config?.title || ""}
          />
        );

      case "product-deals":
        return (
          // <div className="container mb-30">
          //   <div className="row">
          //     <div className="col-lg-12">
          <DealsList
            title={config?.title}
            data={config.content}
            key={`${config?.title}-${widgetIndex}` || ""}
            widgetIndex={widgetIndex}
          />

          //     </div>
          //   </div>
          // </div>
        );

      case "category-carousel":
        return (
          <CategoryHorizontalListing
            lengthToRender={2}
            widgetIndex={widgetIndex}
            key={`${config?.title}-${widgetIndex}` || ""}
            type={config.type}
            data={config.content}
            autoplay={
              config.content?.length > 5
                ? {
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }
                : undefined
            }
            title={config?.title || ""}
            parentClassName="popular-categories section-padding-12 subcategory-carousel mt-0"
            slideClassName="card-1"
            sliderClassName="carausel-8-columns-cover position-relative"
            sliderId="carausel-5-columns"
            slidesPerView={5}
            spaceBetween={12}
            prevBtn={"custom_prev_ct1"}
            nextBtn={"custom_next_ct1"}
            btnClassName="slider-arrow-2 flex-right carausel-10-columns-arrow"
            hasDesc={true}
            last={noOfWidget === widgetIndex + 1}
          />
        );

      case "sub-category-carousel":
        return (
          <CategoryHorizontalListing
            lengthToRender={2}
            widgetIndex={widgetIndex}
            key={`${config?.title}-${widgetIndex}` || ""}
            type={config.type}
            data={config.content}
            autoplay={
              config.content?.length > 5
                ? {
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }
                : undefined
            }
            title={config?.title || ""}
            parentClassName="popular-categories section-padding-12 subcategory-carousel mt-0"
            slideClassName="card-1"
            sliderClassName="carausel-8-columns-cover position-relative"
            sliderId="carausel-5-columns"
            slidesPerView={5}
            spaceBetween={12}
            prevBtn={"custom_prev_ct1"}
            nextBtn={"custom_next_ct1"}
            btnClassName="slider-arrow-2 flex-right carausel-10-columns-arrow"
            hasDesc={true}
            last={noOfWidget === widgetIndex + 1}
          />
        );
    }
  };

  // useEffect(() => {
  //   if (config.type === "product-grid") {
  //     dispatch(
  //       setListingProductsFromWidget({
  //         data: config.content,
  //         type: `widget-${widgetIndex}`,
  //       })
  //     );
  //   }

  //   return () => {
  //     dispatch(resetListingProducts());
  //   };
  // }, []);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return <Fragment key={`${type}-${widgetIndex}`}>{renderListing()}</Fragment>;
};

export { ListingWidet };
