"use client";

import React, { useRef, useState } from "react";
import {
  addPaymentMapping,
  BUTTON_CONSTANTS,
  displayValue,
  formatDate,
  generateRazorpayOptions,
  IOrderAddress,
  IOrderDetail,
  onPaymentFailure,
  STATIC_CONSTANTS,
  STATUSES,
} from "@/utils";
import { Button, Ratings } from "@/components/core-components";
import Link from "next/link";

import { printOrderInvoice, useAppDispatch, useAppSelector } from "@/store";
import Script from "next/script";
import { useRouter } from "next/navigation";
import { OrderInfo } from "./OrderInfo";
import moment from "moment";
import Image from "next/image";

const OrderDetail = ({ orderDetails }: { orderDetails: IOrderDetail }) => {
  const dispatch = useAppDispatch();

  const { status } = useAppSelector((state) => state.order);

  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const payment_mode = useRef(null);

  const shippingAddress = orderDetails.addresses.find(
    (address: IOrderAddress) => address.address_type === "shipping"
  );

  const billingAddress = orderDetails.addresses.find(
    (address: IOrderAddress) => address.address_type === "billing"
  );

  const router = useRouter();

  const successfullPayment = orderDetails?.payment_data?.find(
    (item) =>
      item.payment_status === "success" &&
      item.payment_amount === orderDetails?.payable_amount
  );

  const canRetryPayment =
    (orderDetails?.payment_data?.every(
      (item) => item.payment_status === "failed"
    ) ||
      orderDetails?.payment_data?.length === 0 ||
      orderDetails?.payment_data?.some(
        (item) => item.payment_gateway_id === null
      )) &&
    !orderDetails?.payment_data?.some(
      (item) =>
        item.payment_status === "success" &&
        item.payment_amount === orderDetails?.payable_amount
    ) &&
    moment(new Date()).isBefore(
      moment(
        orderDetails?.payment_data?.[orderDetails?.payment_data?.length - 1]
          ?.created_at
      ).add(2, "hours")
    );

  // console.log(
  //   orderDetails?.payment_data,
  //   orderDetails?.payment_data?.every(
  //     (item) => item.payment_status === "failed"
  //   ),
  //   orderDetails?.payment_data?.length === 0,
  //   orderDetails?.payment_data?.some(
  //     (item) => item.payment_gateway_id === null
  //   ),
  //   !orderDetails?.payment_data?.some(
  //     (item) =>
  //       item.payment_status === "success" &&
  //       item.payment_amount === orderDetails?.payable_amount
  //   )
  // );

  const retryPayment = async () => {
    const failedPayment = orderDetails.payment_data[0];

    let paymentMappingToUse = failedPayment.id;

    if (
      orderDetails?.payment_data?.length === 0 ||
      !orderDetails?.payment_data?.[0]?.payment_gateway_id
    ) {
      const newPaymentMapping = await addPaymentMapping({
        razorpay_order_id: failedPayment?.payment_gateway_order,
        order_id: orderDetails.id,
      });

      paymentMappingToUse = newPaymentMapping;
    }

    const options = generateRazorpayOptions(
      {
        amount: orderDetails?.payable_amount,
        id: failedPayment.payment_gateway_order,
        notes: {
          customer_contact_number: orderDetails?.customer?.contact_number,
          customer_email: orderDetails?.customer?.email,
          customer_name: orderDetails?.customer?.name,
          order_id: failedPayment?.order,
        },
        payment_mapping_id: paymentMappingToUse || 0,
      },
      paymentFailure,
      router,
      dispatch,
      true,
      setLoading
    );

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.submit", async function (response: any) {
      payment_mode.current = response.method;
    });

    paymentObject.on("payment.failed", async function (response: any) {
      const orderDetail = await fetch(
        `/api/razorpay/order/${response.error.metadata.order_id}/`
      ).then((res) => res.json());

      await onPaymentFailure({
        paymentFailure: orderDetail.attempts > 1,
        error_response: response,
        razorpay_order_response: {
          notes: {
            order_id: orderDetails.id,
          },
          payment_mapping_id: failedPayment?.id || 0,
          id: failedPayment?.payment_gateway_order || "",
        },
      });

      setPaymentFailure(true);
    });

    paymentObject.open();
  };

  return (
    <>
      <Script
        id="razorpay-checkout-js"
        src={STATIC_CONSTANTS.RAZORPAY_CONSTANTS.CHECKOUT_URL}
      />
      <section className="section-padding-12 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title style-2 mb-0">
                <h3>Order Details</h3>
              </div>
            </div>
            <div className="col-lg-12 d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-between align-items-end pb-15">
              <p className="mb-10">
                {`Ordered on ${formatDate(orderDetails?.created_at)}`}
                <span className="font-xl pl-10 pr-10">|</span>
                {` Order ID ${orderDetails?.invoice_code}`}
              </p>
              <div className="d-flex flex-row gap-3">
                {canRetryPayment && (
                  <Button
                    text="Retry Payment"
                    type={BUTTON_CONSTANTS.BUTTON}
                    className="btn btn-xs"
                    loading={loading}
                    onClick={() => retryPayment()}
                  />
                )}
                {successfullPayment !== undefined && (
                  <Button
                    text="Download Invoice"
                    type={BUTTON_CONSTANTS.BUTTON}
                    className="btn btn-xs"
                    loading={status === STATUSES.LOADING}
                    onClick={() => {
                      dispatch(
                        printOrderInvoice({ order_id: orderDetails.id })
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            {/* cart left section */}
            <div className="col-lg-12">
              <div className="table-responsive shopping-summery">
                {orderDetails?.ordered_products?.length <= 0 && "No Products"}
                <table
                  className={
                    orderDetails?.ordered_products?.length > 0
                      ? "table table-wishlist"
                      : "d-none"
                  }
                >
                  <thead>
                    <tr className="main-heading">
                      <th className="custome-checkbox start pl-30" colSpan={2}>
                        Product
                      </th>
                      <th scope="col">Qty</th>
                      <th scope="col">Price</th>
                      <th scope="col">Weight</th>
                      <th scope="col" className="end">
                        Amount
                      </th>
                      {/* <th scope="col" className="end">
                        Actions
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {orderDetails?.ordered_products?.map((item, i) => (
                      <tr key={`order-cart-item-${i}`}>
                        <td className="image product-thumbnail" width={"12%"}>
                          <Image
                            width={180}
                            height={180}
                            className="img-fluid"
                            src={item.product.thumbnails?.[0]?.img}
                            alt={item.product.thumbnails?.[0]?.alt}
                          />
                        </td>

                        <td className="product-des product-name v-align-top">
                          <div>
                            <h6 className="product-name">
                              <Link href={`/${item.product.slug}`}>
                                {item.product.title}
                              </Link>
                            </h6>
                            <div className="product-rate-cover">
                              <Ratings rating={item.product.rating} />
                            </div>
                          </div>
                        </td>
                        <td
                          className="price text-center v-align-top"
                          data-title=""
                        >
                          <h6 className="text-dark">{item.quantity}</h6>
                        </td>
                        <td
                          className="price text-center v-align-top"
                          data-title=""
                        >
                          <h6 className="text-dark">
                            <span className="rupee-symbol">₹</span>
                            {displayValue(item.unit_price)}
                          </h6>
                        </td>
                        <td
                          className="price text-center v-align-top"
                          data-title=""
                        >
                          <h6 className="text-dark">{`${item.product.selected_weight} ${item.product.uom}`}</h6>
                        </td>
                        <td
                          className="price text-center v-align-top"
                          data-title=""
                        >
                          <h6 className="text-dark">
                            <span className="rupee-symbol">₹</span>
                            {displayValue(item.payable_amount)}
                          </h6>
                        </td>
                        {/* <td
                          className="text-center v-align-top"
                          data-title=""
                        >
                          <Button
                            type="button"
                            className="btn btn-xs mr-20"
                            text="Write a product review"
                          />
                          <Button
                            type="button"
                            className="btn btn-xs"
                            text="Buy Again"
                          />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <OrderTracking /> */}
      <section className="section-padding-12">
        <div className="container">
          <OrderInfo
            orderDetails={orderDetails}
            isOrderDetailPage={true}
            sectionclassName="col-lg-3 col-md-3"
            shippingAddress={shippingAddress}
            billingAddress={billingAddress}
            cartItems={orderDetails?.ordered_products}
            successfullPayment={successfullPayment}
          />
        </div>
      </section>

      {/* <ListingWithFilters
        key={`best-deals`}
        hasDesc={false}
        type={"order-detail"}
        fetchUrl={`/shop/product/search/`}
        serverFilters={false}
        title="Best Deals"
        filters={["All", "Featured", "Popular", "New Added"]}
        extraFilters={false}
        largeCard={false}
        last={false}
      />

      <ProductHorizontalListing
        lengthToRender={1}
        key={"you-may-also-like"}
        hasDesc={false}
        slidesPerView={5.7}
        spaceBetween={30}
        prevBtn={"custom_prev_2"}
        nextBtn={"custom_next_2"}
        parentClassName="section-padding-12 "
        btnClassName="slider-arrow-2 carausel-6-columns-arrow"
        sliderClassName="col-12"
        sliderId="row related-products position-relative"
        data={carouselData?.["you-may-also-like"]?.data || []}
        onNavigationNext={fetchYouMayAlsoLike}
        type="you-may-also-like"
        title="You May Also Like"
        last={true}
      /> */}
    </>
  );
};

export { OrderDetail };
