"use client";

import {
  Button,
  CustomCheckbox,
  CustomInput,
  CustomSelect,
} from "@/components/core-components";
import {
  addAddress,
  fetchAddressDetails,
  fetchCity,
  fetchCountries,
  fetchState,
  setAdddressDetail,
  updateAddress,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  AddressSchema,
  FORM_CONSTANTS,
  GET_AddressType,
  STATUSES,
} from "@/utils";
import { Form, Formik } from "formik";
import { useRouter } from "next/navigation";
import React, { useEffect, useMemo } from "react";

const AddressForm = ({
  address,
  modalData = { state: false, data: null },
}: {
  address?: GET_AddressType;
  modalData?: { state: boolean; data: any };
}) => {
  const dispatch = useAppDispatch();

  const { addressDetail, status } = useAppSelector((state) => state.address);

  const { countries, states, cities } = useAppSelector(
    (state) => state.location
  );

  const router = useRouter();

  const addresstoEdit = modalData.state ? addressDetail : address;

  const getCountryOptions = useMemo(() => {
    return countries.map((country) => ({
      value: country.id,
      label: country.country,
    }));
  }, [countries.length]);

  const getStateOptions = useMemo(() => {
    return states.map((state) => ({
      value: state.id,
      label: state.state,
    }));
  }, [states.length]);

  const getCityOptions = useMemo(() => {
    return cities.map((city) => ({
      value: city.id,
      label: city.city,
    }));
  }, [cities.length]);

  useEffect(() => {
    if (modalData && modalData.data) {
      dispatch(fetchAddressDetails({ address_id: modalData.data }));
    }

    dispatch(fetchCountries());

    return () => {
      dispatch(setAdddressDetail(null));
    };
  }, []);

  useEffect(() => {
    if (addresstoEdit && Object.keys(addresstoEdit).length > 0) {
      dispatch(fetchState({ country_id: addresstoEdit?.country?.id }));
      dispatch(fetchCity({ state_id: addresstoEdit?.state?.id }));
    }
  }, [Object.keys(addresstoEdit || {}).length]);

  return (
    <div className="tab-pane fade active show">
      <div className={`${modalData.state ? "p-3" : ""} card add-address-card`}>
        <div className="card-header p-0">
          <div className="section-title style-2 mb-0">
            <h3>{`${
              addresstoEdit || modalData?.data ? "Edit" : "Add"
            } Address`}</h3>
          </div>
        </div>
        <div
          className={`card-body pt-0 pb-0 pl-5 ${
            modalData.state && status === STATUSES.LOADING ? "text-center" : ""
          }`}
        >
          {modalData.state && status === STATUSES.LOADING ? (
            <span
              data-testid="loader"
              className={`spinner-border text-primary m-2`}
            ></span>
          ) : (
            <Formik
              initialValues={{
                first_name: addresstoEdit?.first_name || "",
                last_name: addresstoEdit?.last_name || "",
                contact_number: addresstoEdit?.contact_number || "",
                pincode: addresstoEdit?.pincode || "",
                address_line_1: addresstoEdit?.address_line_1 || "",
                address_line_2: addresstoEdit?.address_line_2 || "",
                landmark: addresstoEdit?.landmark || "",
                city: addresstoEdit?.city?.id || "",
                state: addresstoEdit?.state?.id || "",
                country: addresstoEdit?.country?.id || "",
                is_default: addresstoEdit?.set_as_default !== 0 || false,
              }}
              onSubmit={(values, actions) => {
                let dataToSend = {
                  first_name: values?.first_name,
                  last_name: values?.last_name,
                  pincode: values?.pincode,
                  address_line_1: values?.address_line_1,
                  address_line_2: values?.address_line_2,
                  landmark: values?.landmark,
                  city: Number(values?.city),
                  state: Number(values?.state),
                  country: Number(values?.country),
                  contact_number: Number(values?.contact_number),
                  set_as_default: values?.is_default ? 1 : 0,
                };

                if (addresstoEdit) {
                  dispatch(
                    updateAddress({
                      data: {
                        ...dataToSend,
                        id: addresstoEdit.id,
                      },
                      actions,
                      router,
                      modal: true,
                    })
                  );
                } else {
                  dispatch(
                    addAddress({
                      data: dataToSend,
                      actions,
                      router,
                      modal: true,
                    })
                  );
                }
              }}
              validationSchema={AddressSchema}
            >
              {(addressProps) => {
                return (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter First Name"
                          formikProps={addressProps}
                          label={"First Name"}
                          name={"first_name"}
                          isRequired={true}
                          type={FORM_CONSTANTS.TEXT}
                          titleCase={true}
                        />
                      </div>
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter Last Name"
                          formikProps={addressProps}
                          label={"Last Name"}
                          name={"last_name"}
                          isRequired={true}
                          type={FORM_CONSTANTS.TEXT}
                          titleCase={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter Mobile Number"
                          formikProps={addressProps}
                          label={"Mobile Number"}
                          name={"contact_number"}
                          isRequired={true}
                          type={FORM_CONSTANTS.NUMBER}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter Flat, House no., Building, Company, Apartment"
                          formikProps={addressProps}
                          label={
                            "Flat, House no., Building, Company, Apartment "
                          }
                          name={"address_line_1"}
                          isRequired={true}
                          type={FORM_CONSTANTS.TEXT}
                        />
                      </div>
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter Area, Street, Sector, Village"
                          formikProps={addressProps}
                          label={"Area, Street, Sector, Village "}
                          name={"address_line_2"}
                          isRequired={true}
                          type={FORM_CONSTANTS.TEXT}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter Landmark"
                          formikProps={addressProps}
                          label={"Landmark"}
                          name={"landmark"}
                          isRequired={false}
                          type={FORM_CONSTANTS.TEXT}
                        />
                      </div>
                      <div className="col-md-6">
                        <CustomInput
                          placeholder="Enter Pincode"
                          formikProps={addressProps}
                          label={"Pincode"}
                          name={"pincode"}
                          isRequired={true}
                          type={FORM_CONSTANTS.NUMBER}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <CustomSelect
                          placeholder="Select Country"
                          formikProps={addressProps}
                          className="form-group"
                          isRequired={true}
                          options={getCountryOptions}
                          onChangeCapture={(e: any) => {
                            e.target.value &&
                              dispatch(
                                fetchState({
                                  country_id: Number(e.target.value),
                                })
                              );
                          }}
                          label="Country / Region"
                          name="country"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomSelect
                          placeholder="Select State"
                          formikProps={addressProps}
                          className="form-group"
                          isRequired={true}
                          options={getStateOptions}
                          onChangeCapture={(e: any) => {
                            e.target.value &&
                              dispatch(
                                fetchCity({
                                  state_id: Number(e.target.value),
                                })
                              );
                          }}
                          label="State"
                          name="state"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomSelect
                          placeholder="Select City"
                          formikProps={addressProps}
                          className="form-group"
                          isRequired={true}
                          options={getCityOptions}
                          label="City"
                          name="city"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6 mt-10">
                      <div className="custome-checkbox">
                        <CustomCheckbox
                          name="is_default"
                          filters={[
                            {
                              id: 1,
                              value: "Make this my default address",
                            },
                          ]}
                          onClickHandler={(e: any) => {
                            addressProps.setFieldValue(
                              "is_default",
                              e.target.checked
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <Button
                        className="btn btn-sm btn-fill-out submit font-weight-bold"
                        type="submit"
                        text="Save Address"
                        loading={addressProps.isSubmitting}
                        // onClick={() => {
                        //   router.replace("/account/addresses");
                        // }}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export { AddressForm };
