"use client";

import { setOffCanvas, useAppDispatch, useAppSelector } from "@/store";
import React from "react";
import { Button } from "./Button";
import Image from "next/image";

const OffCanvas = ({
  children,
  type,
}: {
  children: React.ReactNode;
  type?: string;
}) => {
  const {
    offCanvas: { state: open, component },
  } = useAppSelector((state) => state.common);

  const dispatch = useAppDispatch();

  return (
    <div className={`offCanvas ${open && type === component ? "open" : ""}`}>
      <div className="content">
        <Button
          type="button"
          className="closeButton mb-20"
          onClick={() => {
            dispatch(setOffCanvas({ state: false, component: null }));
          }}
          text={
            <Image
              width={20}
              height={20}
              src="/assets/imgs/theme/icons/icon-close.png"
              alt="Close"
            />
          }
        />
        {children}
      </div>
    </div>
  );
};

export { OffCanvas };
