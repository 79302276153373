import React from "react";
import { Banner, BannersList } from "../Banners";
import { ListingWithFilters, ProductMultipleHamperDeal } from "../Listing";
import { STATIC_CONSTANTS } from "@/utils";
import Image from "next/image";

const Festive02 = () => {
  return (
    <>
      <Banner
        src={"/assets/imgs/banner/festive-banner-3.png"}
        alt={""}
        widgetIndex={0}
      />
      <ProductMultipleHamperDeal
        data={STATIC_CONSTANTS.PRODUCT_MULTIPLE_HAMPER_DEAL}
        widgetIndex={0}
      />

      <section className="bg-primary-2 section-padding-12">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Image
                src={"/assets/imgs/banner/festive-banner-4.png"}
                alt={""}
                width={1600}
                height={600}
                className={`w-100 h-100`}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="pt-80 pb-80 bg-primary-2 mb-40">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-12"> */}
      <ListingWithFilters
        data={STATIC_CONSTANTS.PRODUCTS}
        title="Best Offers"
        filters={[]}
        largeCard={true}
        extraFilters={false}
      />
      {/* </div>
          </div>
        </div>
      </section> */}

      <BannersList banners={STATIC_CONSTANTS.BANNERS} direction={'horizontal'} widgetIndex={0} />
    </>
  );
};

export { Festive02 };
