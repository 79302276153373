"use client";

import { STATIC_CONSTANTS } from "@/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

const Breadcrumb = ({
  homeElement,
  separator,
  containerClasses,
  listClasses,
  activeClasses,
  capitalizeLinks,
  detailed = false,
  description,
}: {
  homeElement: React.ReactNode;
  separator: React.ReactNode;
  containerClasses?: string;
  listClasses?: string;
  activeClasses?: string;
  capitalizeLinks?: boolean;
  detailed?: boolean;
  description?: string;
}) => {
  const paths = usePathname();
  const pathNames = paths.split("/").filter((path) => path);

  const displayBreadCrumb =
    pathNames.filter((value) =>
      STATIC_CONSTANTS.DISABLED_BREADCRUMB.includes(value)
    )?.length > 0;

  return !displayBreadCrumb && pathNames.length > 0 ? (
    detailed ? (
      <>
        <div className="page-header mt-30 mb-30">
          <div className="container">
            <div className="archive-header">
              <div className="row align-items-center">
                <div className="col-xl-4">
                  <h3 className="mb-15 text-capitalize">
                    {pathNames.length > 0 ? pathNames[0] : "Category"}
                  </h3>
                  <div className="breadcrumb">
                    <li className={listClasses}>
                      <Link href={"/"}>{homeElement}</Link>
                    </li>
                    {pathNames.length > 0 && separator}
                    {pathNames.map((link, index) => {
                      let href = `/${pathNames.slice(0, index + 1).join("/")}`;

                      let itemClasses =
                        paths === href
                          ? `${listClasses} ${activeClasses}`
                          : listClasses;

                      let itemLink = capitalizeLinks
                        ? decodeURIComponent(
                            link[0].toUpperCase() + link.slice(1, link.length)
                          ).replace(/-/g, " ")
                        : decodeURIComponent(link).replace(/-/g, " ");

                      return (
                        <React.Fragment key={`breadcrumb-item-${index}`}>
                          <li className={itemClasses}>
                            <Link href={href}>{itemLink}</Link>
                          </li>
                          {pathNames.length !== index + 1 && separator}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className="col-xl-8 text-end d-none d-xl-block">
                  <p className="category-description">{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <section className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul>
                <li className={listClasses}>
                  <Link href={"/"}>{homeElement}</Link>
                </li>
                {pathNames.length > 0 && separator}
                {pathNames.map((link, index) => {
                  let href = `/${pathNames.slice(0, index + 1).join("/")}`;

                  let itemClasses =
                    paths === href
                      ? `${listClasses} ${activeClasses}`
                      : listClasses;

                  let itemLink = capitalizeLinks
                    ? decodeURIComponent(
                        link[0].toUpperCase() + link.slice(1, link.length)
                      ).replace(/-/g, " ")
                    : decodeURIComponent(link).replace(/-/g, " ");

                  return (
                    <React.Fragment key={`breadcrumb-item-${index}`}>
                      <li className={itemClasses}>
                        {pathNames.length === index + 1 ? (
                          <>{itemLink}</>
                        ) : (
                          <Link href={href}>{itemLink}</Link>
                        )}
                      </li>
                      {pathNames.length !== index + 1 && separator}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  ) : null;
};

export { Breadcrumb };
