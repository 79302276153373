"use client";

import React, { useState } from "react";
import { usePagination } from "@/utils";
import { useRouter } from "next/navigation";

const Search = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const { updateSearch, handleEnterClick, searchParams } = usePagination();

  const router = useRouter();

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (searchTerm) {
                updateSearch(searchTerm);
                router.push(`/search/?query=${searchTerm}`);
              } else {
                router.push(`/`);
              }
            }
          }}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          type="text"
          placeholder="Search Avarya.in"
        />
      </form>
    </>
  );
};

export { Search };
