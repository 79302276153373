import React from "react";
import { Banner, BannersList, BannerVideo, GiftHamper } from "../Banners";
import { ListingWithFilters } from "../Listing";
import { STATIC_CONSTANTS } from "@/utils";
import Image from "next/image";
import { Button } from "@/components/core-components";

const Festive03 = () => {
  return (
    <>
      <Banner
        widgetIndex={0}
        src={"/assets/imgs/banner/festive-banner-4.jpg"}
        alt={""}
        parentClassName='festive-option-3 festive-banner'
        children={
          <div className='slider-content text-left'>
            <p className='mb-40 text-uppercase montserrat-600'>Lorem Ipsum</p>
            <h3 className='display-4 mb-65 prata-regular'>
              Wishing a Very Happy Diwali To You With Amazing Deals!
            </h3>
          </div>
        }
      />

      <section className='festive-option-3-section-1 section-padding-12'>
        <BannerVideo data={STATIC_CONSTANTS.VIDEO_BANNER} />
      </section>

      <section className='festive-option-3-section-2 section-padding-12'>
        <GiftHamper widgetIndex={0} />
      </section>

      <section className='festive-option-3-section-3 section-padding-12'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-12'>
              <div className='row mb-x'>
                <Banner
                  widgetIndex={0}
                  children={
                    <div className='col-lg-12 text-center mt-15'>
                      <h2 className='mb-15 prata-regular text-extra-dark'>
                        Upto 40% Off Final Sale Items.
                        <br />
                        Caught in the moment!
                      </h2>
                      <Button
                        className='btn btn-xl prata-regular'
                        text={`Shop Now`}
                        type={"button"}
                        loading={false}
                      />
                    </div>
                  }
                  alt=''
                  src='/assets/imgs/banner/festive-banner-6.png'
                  className='col-lg-12 d-flex flex-column-reverse'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='festive-option-3-section-4 section-padding-12'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-6'>
              <Image
                alt=''
                width={800}
                height={300}
                src='/assets/imgs/banner/festive-banner-7.png'
                className='w-100 h-100'
              />
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6'>
              <Image
                alt=''
                width={800}
                height={300}
                src='/assets/imgs/banner/festive-banner-8.png'
                className='w-100 h-100'
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="festive-option-3-section-5 section-padding-12">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-12"> */}
      <ListingWithFilters
        data={STATIC_CONSTANTS.PRODUCTS}
        title='Best Offers'
        filters={[]}
        largeCard={true}
        extraFilters={false}
      />
      {/* </div>
          </div>
        </div>
      </section> */}

      <BannersList
        banners={STATIC_CONSTANTS.BANNERS}
        direction={"horizontal"}
        widgetIndex={0}
      />
    </>
  );
};

export { Festive03 };
