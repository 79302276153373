"use client";

import {
  Button,
  CustomSelect,
  Ratings,
  Tags,
  ThumbSlider,
} from "@/components/core-components";
import { useEffect, useState } from "react";
import {
  calculatePrice,
  constructTastes,
  extractProductNumber,
  handleCart,
  IProduct,
  STATIC_CONSTANTS,
  to2Decimal,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { ProductReviews } from "./ProductReviews";
import { ReviewModal } from "./ReviewModal";
import { ShareModal } from "./ShareModal";
import { ProductHorizontalListing } from "./ProductListing";
import {
  addToWishlist,
  fetchCarouselData,
  fetchInitialCarouselData,
  removeFromWishlist,
  resetCarouselData,
  setCartItemsCounter,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import parse from "html-react-parser";
import { notFound, useRouter } from "next/navigation";
import { CategoryHorizontalListing } from "../Category";

import heart from "../../../../../public/assets/imgs/theme/icons/icon-heart.svg";
import heartFilled from "../../../../../public/assets/imgs/theme/icons/icon-heart-filled.svg";
import Image from "next/image";
import { StructuredData } from "@/components/StructuredData";

const ProductDetails = ({
  product,
  reviews = [],
  quickView,
}: {
  reviews?: any[];
  product: IProduct | any;
  quickView: boolean;
}) => {
  const dispatch = useAppDispatch();

  if (Object.keys(product).length === 0) {
    Toast("Something went wrong", TOAST_CONSTANTS.ERROR);
    return notFound();
  }

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const is_logged_in = logged_in_user || access_token;

  const { cartItemsCounter } = useAppSelector((state) => state.cart);

  const { state, carouselData } = useAppSelector((state) => state.common);

  const { wishlist } = useAppSelector((state) => state.wishlist);

  const canAddtoWishlist = wishlist.find(
    (item: any) => item.id === product.id
  )?.wishlist_id;

  const router = useRouter();

  const [quantity, setQuantity] = useState(1);

  const [weight, setWeight] = useState<
    {
      id: number;
      value: number;
      label: string;
      uom: string;
    }[]
  >([product?.weights?.[0]]);

  const [reviewModal, setReviewModal] = useState({
    state: false,
    content: {
      media_url: "",
      media_type: "",
    },
  });

  const [shareModal, setShareModal] = useState(false);

  const fetchRelatedSubCategories = (swiper: any) => {
    if (swiper.destroyed) {
      return;
    }

    if (swiper.visibleSlides?.length > 0) {
      const currentViewProducts = swiper.visibleSlides.map((slide: any) => {
        return Number(extractProductNumber(slide.classList, "category"));
      });

      checkIfLastPage({
        carouselData: carouselData,
        itemsPerPage: 6,
        type: "related-sub-categories",
        url: `/api/recommended/?slug=${product.slug
          .split("/")
          .slice(-1)}&type=subcategory`,
        visibleSlides: currentViewProducts,
      });
    }
  };

  const fetchFrequentlyBought = (swiper: any) => {
    const currentViewProducts = swiper.visibleSlides.map((slide: any) => {
      return Number(extractProductNumber(slide.classList));
    });

    if (
      currentViewProducts.length === 5
        ? currentViewProducts.includes(
            carouselData["frequently-bought"]?.page * 6
          )
        : currentViewProducts[currentViewProducts?.length - 1] <
          carouselData["frequently-bought"]?.page * 6
    ) {
      dispatch(
        fetchCarouselData({
          page: carouselData["frequently-bought"]?.page + 1,
          type: "frequently-bought",
          url: `/api/recommended/?slug=${product.slug
            .split("/")
            .slice(-1)}&type=product`,
          onSuccess: (index: number) => {
            // console.log("slideTo", index);
            // swiper?.slideTo(index);
          },
        })
      );
    }
  };

  const fetchYouMayAlsoLike = (swiper: any) => {
    if (swiper.destroyed) {
      return;
    }

    if (swiper.visibleSlides?.length > 0) {
      const currentViewProducts = swiper.visibleSlides.map((slide: any) => {
        return Number(extractProductNumber(slide.classList));
      });

      checkIfLastPage({
        carouselData: carouselData,
        itemsPerPage: 6,
        type: "you-may-also-like",
        url: `/api/recommended/?slug=${product.slug
          .split("/")
          .slice(-1)}&type=product`,
        visibleSlides: currentViewProducts,
      });
    }
  };

  function checkIfLastPage({
    carouselData,
    visibleSlides,
    type,
    itemsPerPage = 6,

    url,
  }: {
    carouselData: any;
    visibleSlides: number[];
    type: string;
    itemsPerPage: number;
    url: string;
  }) {
    const lessThanTotalItems = !visibleSlides.includes(
      carouselData[type].count
    );

    const validSlidesLength =
      visibleSlides.length === 6 ||
      visibleSlides.length === 4 ||
      visibleSlides.length === 2;

    const validToFetch = validSlidesLength
      ? visibleSlides.includes(carouselData[type]?.page * itemsPerPage)
      : visibleSlides[visibleSlides?.length - 1] <
        carouselData[type]?.page * itemsPerPage;

    if (lessThanTotalItems && validToFetch) {
      dispatch(
        fetchCarouselData({
          page: carouselData[type]?.page + 1,
          type: type,
          url: url,
          onSuccess: (index: number) => {},
        })
      );
    }
  }

  const AddToCart = (product: IProduct) => {
    handleCart({
      dispatch: dispatch,
      is_logged_in: logged_in_user || access_token,
      product: product,
      selected_quantity: Number(quantity),
      selected_weight: weight,
      onSuccess() {
        // Toast(
        //   "Added to Cart !",
        //   TOAST_CONSTANTS.SUCCESS
        //   // <i className="fi-rs-heart"></i>
        // );
        dispatch(setCartItemsCounter(cartItemsCounter + Number(quantity)));
      },
    });
  };

  const handleWishlist = (product: any) => {
    if (logged_in_user || access_token) {
      dispatch(
        addToWishlist({
          product_id: product.id,
          onSucces: () => {
            Toast(
              "Added to Wishlist !",
              TOAST_CONSTANTS.SUCCESS,
              <i className="fi-rs-heart"></i>
            );
          },
        })
      );
    } else {
      router.push("/account/login");
      // dispatch(addPendingItem(product.id));
      // Toast(
      //   "Added to Wishlist!, Please Login to view wishlist",
      //   TOAST_CONSTANTS.SUCCESS,
      //   <i className="fi-rs-heart"></i>
      // );
    }
  };

  useEffect(() => {
    if (!quickView) {
      dispatch(
        fetchInitialCarouselData({
          data: [
            {
              page: 1,
              type: "you-may-also-like",
              url: `/shop/product/recommendation/${product.slug
                .split("/")
                .slice(-1)}/?type=product`,
            },
            // {
            //   page: 1,
            //   type: "frequently-bought",
            //   url: `/shop/product/recommendation/${product.slug
            //     .split("/")
            //     .slice(-1)}/?type=product`,
            // },
            {
              page: 1,
              type: "related-sub-categories",
              url: `/shop/product/recommendation/${product.slug
                .split("/")
                .slice(-1)}/?type=subcategory`,
            },
          ],
        })
      );
    }

    return () => {
      if (!quickView) {
        dispatch(resetCarouselData());
      }
    };
  }, [dispatch, quickView]);

  return (
    <>
      <StructuredData productDetails={product} reviews={reviews} />
      <section className="product-detail-page section-padding-12 pb-0">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-xl-12 col-lg-12 m-auto">
              <div className="product-detail accordion-detail">
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <div className="detail-gallery">
                      {/* <span className="zoom-icon">
                        <i className="fi-rs-search"></i>
                      </span> */}
                      <div className="product-image-slider">
                        <ThumbSlider product={product} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div className="detail-info">
                      <h1 className="title-detail">{product.title}</h1>
                      <div className="product-detail-rating">
                        <div className="product-rate-cover text-end">
                          <Ratings rating={product.rating} />
                        </div>
                      </div>
                      <Tags product={product} />
                      <hr />
                      <div className="clearfix product-price-cover">
                        <div className="product-price ">
                          {product.discount.type === "percentage" &&
                            product.discount.value > 0 && (
                              <span className="save-price font-md color3 ml-15">
                                {product.discount.value}% Off
                              </span>
                            )}
                          <span className="current-price text-brand d-flex flex-row align-items-start">
                            <span className="rupee-symbol">₹</span>
                            {product.selling_uom === "pcs"
                              ? product.price
                              : calculatePrice(
                                  1000,
                                  product.price,
                                  weight?.[0]?.value
                                )}
                          </span>
                          <span className="font-sm font-weight-lighter ml-5">
                            {`(₹${to2Decimal(
                              (product.selling_uom === "pcs"
                                ? product.price
                                : calculatePrice(
                                    1000,
                                    product.price,
                                    weight?.[0]?.value
                                  )) / weight?.[0]?.value
                            )} per gm)`}
                          </span>
                        </div>
                        <span className="old-price font-md ml-15">
                          {product.oldPrice ? `₹${product.oldPrice}` : null}
                        </span>
                        <span className="d-block inclusive-text">
                          Inclusive of all taxes
                        </span>
                      </div>

                      <div className="attr-detail attr-size mb-15">
                        <p className="mr-10 mb-0 fw-600">Weight</p>
                        <ul className="list-filter size-filter font-small">
                          {product.weights.map((item: any, index: number) => (
                            <li
                              key={index}
                              className={
                                weight[0]?.value === item.value
                                  ? "active font-weight-bold"
                                  : ""
                              }
                            >
                              <a
                                onClick={(e) => {
                                  setWeight([item]);
                                }}
                              >
                                {`${item.label} ${item.uom}`}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* <div className='bt-1 border-color-1 mt-30 mb-30'></div> */}
                      <div className="detail-extralink mb-15">
                        <div className="product-extra-link2 d-flex align-items-center">
                          <p className="mr-10 mb-0 fw-600">Qty</p>
                          <CustomSelect
                            options={STATIC_CONSTANTS.QUANTITIES}
                            name="quantity"
                            onChange={(e: any) => {
                              setQuantity(e.target.value);
                            }}
                          />
                          <Button
                            className="btn btn-sm button-add-to-cart hover-up mr-10 no-wrap product-detail-add"
                            // textClassName={"button-add-to-cart-text"}
                            onClick={() => AddToCart(product)}
                            text="Add to Cart"
                            type="button"
                            loading={false}
                          />
                          <a
                            aria-label="Add To Wishlist"
                            className="action-btn hover-up mr-10"
                            onClick={(e) => {
                              if (product.wishlist_added || canAddtoWishlist) {
                                dispatch(
                                  removeFromWishlist({
                                    wishlist_id: canAddtoWishlist,
                                    onSuccess: () => {
                                      Toast(
                                        "Removed from wishlist",
                                        TOAST_CONSTANTS.INFO,
                                        <i className="fi-rs-heart"></i>
                                      );
                                    },
                                  })
                                );
                                // Toast(
                                //   "Already added in wishlist",
                                //   TOAST_CONSTANTS.INFO,
                                //   <i className="fi-rs-heart"></i>
                                // );
                              } else {
                                handleWishlist(product);
                              }
                            }}
                          >
                            {/* <i className="fi-rs-heart"></i> */}
                            <Image
                              width={20}
                              height={20}
                              className="svgInject filter-black-primary"
                              alt="wishlist"
                              src={!canAddtoWishlist ? heart : heartFilled}
                            />
                          </a>
                          <a
                            aria-label="Share"
                            className="action-btn hover-up mr-10"
                            onClick={(e) => {
                              setShareModal(true);
                            }}
                          >
                            <i className="fi-rs-share"></i>
                          </a>
                          <ShareModal
                            modal={shareModal}
                            setModal={setShareModal}
                            slug={product.slug}
                          />
                        </div>
                      </div>
                      <div className="product-meta-info">
                        <h5 className="mb-10">Product Details</h5>
                        <table className="w-50">
                          <tbody>
                            <tr>
                              <td>SKU</td>
                              <td>{product.product_id}</td>
                            </tr>
                            <tr>
                              <td>Taste</td>
                              <td>{`${constructTastes(product.tastes)}`}</td>
                            </tr>
                            <tr>
                              <td>Shelf Life</td>
                              <td>{`${product.shelf_life} days`}</td>
                            </tr>
                            <tr>
                              <td>Availability</td>
                              <td>In Stock</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {quickView ? null : (
        <>
          {/* Description */}
          <section className="section-padding-12">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title">
                    <div className="title">
                      <h2 className="about-product-title">
                        {`About ${product?.title}`}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="position-relative">
                    <p className="font-sm line-height-1-4">
                      {parse(product.description)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className={`section-border-top`} />

          {/* <ProductHorizontalListing
            key={"frequently-bought"}
            data={carouselData["frequently-bought"]?.data || []}
            title="Frequently Bought Together"
            hasDesc={false}
            slidesPerView={6}
            spaceBetween={8}
            prevBtn={"custom_prev_1"}
            nextBtn={"custom_next_1"}
            parentClassName="section-padding-12"
            btnClassName="slider-arrow-2 carausel-6-columns-arrow"
            sliderClassName="col-12"
            type="frequently-bought"
            onNavigationNext={fetchFrequentlyBought}
            sliderId="row related-products position-relative"
            centered={
              state.status === STATUSES.LOADING &&
              state.type === "frequently-bought"
            }
          /> */}

          <ProductHorizontalListing
            lengthToRender={1}
            key={"you-may-also-like"}
            hasDesc={false}
            slidesPerView={6}
            spaceBetween={8}
            prevBtn={"custom_prev_2"}
            nextBtn={"custom_next_2"}
            parentClassName="section-padding-12 "
            btnClassName="slider-arrow-2 carausel-6-columns-arrow"
            sliderClassName="col-12"
            sliderId="row related-products position-relative"
            data={carouselData?.["you-may-also-like"]?.data || []}
            onNavigationNext={fetchYouMayAlsoLike}
            type="you-may-also-like"
            title="You May Also Like"
          />

          <CategoryHorizontalListing
            lengthToRender={1}
            type={"related-sub-categories"}
            key={"related-sub-categories"}
            data={carouselData?.["related-sub-categories"]?.data || []}
            onNavigationNext={fetchRelatedSubCategories}
            title={"Shop By Categories"}
            parentClassName="popular-categories section-padding-12  subcategory-carousel mt-0"
            slideClassName="card-1 ptb-10"
            sliderClassName="carausel-8-columns-cover position-relative"
            sliderId="carausel-5-columns"
            slidesPerView={6}
            spaceBetween={8}
            prevBtn={"custom_prev_ct1"}
            nextBtn={"custom_next_ct1"}
            btnClassName="slider-arrow-2 flex-right carausel-10-columns-arrow"
            hasDesc={false}
            last={false}
          />

          <ProductReviews
            setModal={setReviewModal}
            reviews={reviews}
            product_id={product.id}
          />

          {reviewModal && (
            <ReviewModal visible={reviewModal} setVisible={setReviewModal} />
          )}
        </>
      )}
    </>
  );
};

export { ProductDetails };
