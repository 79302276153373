"use client";

import { logoutUser, useAppDispatch } from "@/store";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

const AccountLayout = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  const pathNames = usePathname();

  const currentPath = pathNames.split("/");

  const dontShowTabs = currentPath.some(
    (path) =>
      (Number.isInteger(parseInt(path)) && currentPath.includes("orders")) ||
      currentPath.includes("login") ||
      currentPath.includes("register")
  );

  return dontShowTabs ? (
    <>{children}</>
  ) : (
    <div className="page-content ptb-30">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 m-auto">
            <div className="row">
              <div className="col-md-2 mb-40">
                <div className="dashboard-menu">
                  <ul className="nav flex-column" role="tablist">
                    <li className="nav-item">
                      <Link
                        href="/account/profile"
                        className={
                          currentPath.includes("profile")
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        <i className="fi-rs-user mr-10"></i>Profile
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        href={"/account/orders"}
                        className={
                          currentPath.includes("orders")
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        <i className="fi-rs-shopping-bag mr-10"></i>Orders
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        href="/account/addresses"
                        className={
                          currentPath.includes("addresses")
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        <i className="fi-rs-marker mr-10"></i>My Addresses
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link
                        href="/account/login"
                        className="nav-link"
                        onClick={() => dispatch(logoutUser())}
                      >
                        <i className="fi-rs-sign-out mr-10"></i>Sign out
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-10">
                <div className="tab-content account dashboard-content pl-0">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AccountLayout };
