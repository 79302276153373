"use client";

import { Modal } from "react-responsive-modal";
import { ProductDetails } from "./Listing";
import { setQuickView, useAppDispatch, useAppSelector } from "@/store";
import { IProduct } from "@/utils";
import { AddressForm, UpdateDetails } from "./Account";

const QuickView = () => {
  const {
    quickView: { data, state, type },
  } = useAppSelector((state) => state.common);

  const dispatch = useAppDispatch();

  const renderModal = () => {
    switch (type) {
      case "address":
        return <AddressForm modalData={{ state: state, data: data }} />;

      case "change-details":
        return <UpdateDetails modalData={{ state: state, data: data }} />;

      case "product":
        return (
          <ProductDetails
            product={data || ({} as IProduct)}
            quickView={state}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      open={state}
      onClose={() => {
        dispatch(setQuickView({ state: false, data: null, type: "" }));
      }}
    >
      {state && <div className="quick-view">{renderModal()}</div>}
    </Modal>
  );
};

export { QuickView };
