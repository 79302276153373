import {
  City,
  Country,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  State,
  STATUSES,
} from "@/utils";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";

const initialState = {
  cities: [] as City[],
  states: [] as State[],
  countries: [] as Country[],
  status: STATUSES.IDLE as string,
  error: null,
};

export const fetchCountries = createAsyncThunk(
  "location/fetchCountries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch("/api/location/country/", {
        method: REQUEST_METHOD.GET,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
      }).then((res) => res.json());

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchState = createAsyncThunk(
  "location/fetchState",
  async ({ country_id }: { country_id: number }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `/api/location/state/?country=${country_id}`,
        {
          method: REQUEST_METHOD.GET,
          headers: {
            "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
          },
        }
      ).then((res) => res.json());

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCity = createAsyncThunk(
  "location/fetchCity",
  async ({ state_id }: { state_id: number }, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/location/city/?state=${state_id}`, {
        method: REQUEST_METHOD.GET,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
      }).then((res) => res.json());

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload.result;
      })
      .addCase(fetchState.fulfilled, (state, action) => {
        state.states = action.payload.result;
      })
      .addCase(fetchCity.fulfilled, (state, action) => {
        state.cities = action.payload.result;
      })
      .addMatcher(
        isAnyOf(fetchCountries.pending, fetchState.pending, fetchCity.pending),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(
          fetchCountries.fulfilled,
          fetchState.fulfilled,
          fetchCity.fulfilled
        ),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      );
  },
});

export const {} = locationSlice.actions;

export default locationSlice.reducer;
