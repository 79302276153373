import { IStores } from "@/utils";
import React from "react";
import Image from "next/image";
import phonecall from "../../../public/assets/imgs/theme/icons/phone-call.svg";
import whatsapp from "../../../public/assets/imgs/theme/icons/whatsapp-outline.svg";
import Link from "next/link";
import parse from "html-react-parser";

const OurStores = ({ stores }: { stores: IStores[] }) => {
  return (
    <div className="page-content pt-30">
      <div className="container">
        <div className="row mb-30">
          <div className="col-lg-12">
            <h1 className="heading-2 text-center">Our Stores</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 m-auto">
            <section className="">
              <div className="row">
                {stores.map((store, index) => (
                  <div key={index} className="store-info col-md-6">
                    <h5 className="mb-10 text-brand">{store.store_name}</h5>
                    {/* <div className='custom-tooltip'>
                      {`${store.address?.address_line_1}, ${store.address?.address_line_2}, ${store.address?.city.city}, ${store.address?.state.state}, ${store.address?.country.country}`}
                      <span className='custom-tooltiptext'>{`${store.address?.address_line_1}, ${store.address?.address_line_2}, ${store.address?.city.city}, ${store.address?.state.state}, ${store.address?.country.country}`}</span>
                    </div> */}
                    <div className="store-address">
                      {`${store.address?.address_line_1}, ${store.address?.address_line_2}, ${store.address?.city.city}, ${store.address?.state.state}, ${store.address?.country.country}`}
                    </div>
                    <span className="d-flex align-items-center">
                      <Image
                        width={50}
                        height={50}
                        className="hotline-custom mr-10"
                        src={phonecall}
                        alt="hotline"
                      />
                      <span className="address-business-link">
                        <Link
                          target="_blank"
                          href={`tel:${store.contact_number}`}
                        >
                          {store.contact_number}
                        </Link>
                      </span>
                    </span>
                    <span className="d-flex align-items-center mb-10">
                      <Image
                        width={50}
                        height={50}
                        className="hotline-custom mr-10"
                        src={whatsapp}
                        alt="hotline"
                      />
                      <span className="address-business-link">
                        <Link
                          target="_blank"
                          href={`https://api.whatsapp.com/send?phone=${
                            store.whatsapp_number || store.contact_number
                          }`}
                        >
                          {store.whatsapp_number || store.contact_number}
                        </Link>
                      </span>
                    </span>
                    {/* <a
                      href={store.mapLink}
                      className='btn btn-xs font-weight-bold text-white mt-20 border-radius-5 btn-shadow-brand hover-up'
                    >
                      View map
                    </a> */}
                    {parse(`${store?.store_gmap}` || "")}
                    {/* <iframe
                      src={}
                      // frameborder='0'
                      // marginwidth='0'
                      // marginheight='0'
                      // scrolling='no'
                      width="100%"
                      height="350"
                    ></iframe> */}

                    <div className="divider mt-15 mb-15"></div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export { OurStores };
