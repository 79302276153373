"use client";

import React from "react";
import {
  setFilters,
  setMaxPageLimitState,
  setMinPageLimitState,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { GENERAL_CONSTANTS, usePagination } from "@/utils";

const Pagination = ({
  total_page,
  serverFilters = false,
}: {
  total_page: number;
  serverFilters?: boolean;
}) => {
  const pages: number[] = Array.apply(null, {
    length: total_page,
  } as number[]).map(Number.call, Number) as number[];

  const dispatch = useAppDispatch();

  const { filter } = useAppSelector((state) => state.common);
  const { updatePage, currentPage, onPreviousClick, onNextClick } =
    usePagination();

  const { maxPageLimit, minPageLimit } = useAppSelector(
    (state) => state.common
  );

  const scrollToTop = () => {
    document.getElementById("listing-container")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return total_page > 0 ? (
    <>
      <ul className="pagination justify-content-end">
        {(serverFilters ? currentPage - 1 : filter.page - 1) === 0 ? null : (
          <li
            // onClick={() => onPreviousClick(currentPage - 1)}
            onClick={() => {
              if (serverFilters) {
                onPreviousClick(currentPage - 1);
              } else {
                if (
                  (filter.page - 1) % GENERAL_CONSTANTS.PER_PAGE_LIMIT ===
                  0
                ) {
                  dispatch(
                    setMaxPageLimitState(
                      maxPageLimit - GENERAL_CONSTANTS.PER_PAGE_LIMIT
                    )
                  );
                  dispatch(
                    setMinPageLimitState(
                      minPageLimit - GENERAL_CONSTANTS.PER_PAGE_LIMIT
                    )
                  );
                }
                dispatch(setFilters({ ...filter, page: filter.page - 1 }));
              }
              scrollToTop();
            }}
            className="page-item"
          >
            <a className="page-link">
              <i className="fi-rs-angle-double-small-left"></i>
            </a>
          </li>
        )}

        {pages.map((item, index) => {
          if (item + 1 <= maxPageLimit && item + 1 >= minPageLimit) {
            return (
              <li
                // onClick={() => updatePage(+item + 1)}
                onClick={() => {
                  if (serverFilters) {
                    updatePage(+item + 1);
                  } else {
                    dispatch(setFilters({ ...filter, page: +item + 1 }));
                  }
                  scrollToTop();
                }}
                key={`pagination-item-${index}`}
                className={
                  (serverFilters ? currentPage : filter.page) === +item + 1
                    ? "page-item active"
                    : "page-item"
                }
              >
                <a className="page-link">{+item + 1}</a>
              </li>
            );
          }
        })}

        {(serverFilters ? currentPage : filter.page) === +total_page ? null : (
          <li
            // onClick={() => onNextClick(currentPage + 1)}
            onClick={() => {
              if (serverFilters) {
                onNextClick(currentPage + 1);
              } else {
                if (filter.page >= maxPageLimit) {
                  dispatch(
                    setMaxPageLimitState(
                      maxPageLimit + GENERAL_CONSTANTS.PER_PAGE_LIMIT
                    )
                  );
                  dispatch(
                    setMinPageLimitState(
                      minPageLimit + GENERAL_CONSTANTS.PER_PAGE_LIMIT
                    )
                  );
                }
                dispatch(setFilters({ ...filter, page: filter.page + 1 }));
              }
              scrollToTop();
            }}
            className="page-item"
          >
            <a className="page-link">
              <i className="fi-rs-angle-double-small-right"></i>
            </a>
          </li>
        )}
      </ul>

      {/* {total_page <= 0 ? null : (
        <p className="text-end ml-0">
          Show {serverFilters ? currentPage : filter.page} of {pages.length}
        </p>
      )} */}
    </>
  ) : null;
};

export { Pagination };
