"use client";

import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Virtual,
} from "swiper";

import { Swiper } from "swiper/react";

import { extractProductNumber, SliderProps } from "@/utils";

import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Fragment, useState } from "react";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

const Slider = ({
  type,
  children,
  className,
  btnClassName,
  prevBtn,
  nextBtn,
  slidesPerView = 1,
  spaceBetween = 50,
  autoplay,
  pagination,
  centered = false,
  onNavigationNext,
  toRender = true,
  dataLength = slidesPerView,
  breakpoints,
}: SliderProps) => {
  const [swiper, setSwiper] = useState<any>(null);

  return (
    <>
      <Swiper
        key={type}
        onSwiper={setSwiper}
        modules={[Virtual, Navigation]}
        pagination={pagination}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        virtual={true}
        navigation={{
          prevEl: `.${prevBtn}`,
          nextEl: `.${nextBtn}`,
        }}
        preventInteractionOnTransition={true}
        breakpoints={
          breakpoints !== null
            ? {
                0: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: slidesPerView,
                },
              }
            : undefined
        }
        // onNavigationNext={(s: any) => {
        //   onNavigationNext && onNavigationNext(s, swiper);
        // }}
        onReachEnd={(s: any) => {
          onNavigationNext && onNavigationNext(s, swiper);
        }}
        centeredSlides={centered}
        watchOverflow={false}
        loop={autoplay !== undefined && dataLength >= slidesPerView * 2}
        loopPreventsSliding={false}
        autoplay={autoplay}
        className={className ? className : ""}
      >
        {children}
      </Swiper>

      <div className={`slider-arrow ${btnClassName ? btnClassName : ""}`}>
        <span className={`slider-btn slider-prev slick-arrow ${prevBtn}`}>
          <i className="fi-rs-angle-left"></i>
        </span>
        <span className={`slider-btn slider-next slick-arrow ${nextBtn}`}>
          <i className="fi-rs-angle-right"></i>
        </span>
      </div>
    </>
  );
};

export { Slider };
