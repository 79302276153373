"use client";

import { Button } from "@/components/core-components";
import { setQuickView, useAppDispatch } from "@/store";
import { BUTTON_CONSTANTS, IAddress } from "@/utils";
import Image from "next/image";
import React, { useCallback } from "react";

interface IAddressItem {
  state: boolean;
  id: number;
}

interface AddressState {
  shipping: IAddressItem;
  billing: IAddressItem;
}

type AddressKey = keyof AddressState;

interface IProps {
  title: string;
  type: AddressKey;
  addresses: IAddress[];
  address: AddressState;
  setAddress: React.Dispatch<React.SetStateAction<AddressState>>;
  setSameAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserAddressList = ({
  title,
  type,
  addresses,
  address: addressState,
  setAddress,
  setSameAddress,
}: IProps) => {
  const dispatch = useAppDispatch();

  const renderAddressItem = useCallback(
    (address: IAddress, index: number) => {
      return (
        <tr key={index}>
          <td className="border-none custome-radio p-0">
            <input
              className="form-check-input"
              type="radio"
              name={`${type}-address`}
              id={`address-${address.id}`}
              checked={address.id === addressState[type].id}
              onClick={() => {
                setSameAddress(false);
                setAddress({
                  ...addressState,
                  [type]: {
                    ...addressState[type],
                    // state: false,
                    id: Number(address.id),
                  },
                });
              }}
            />
            <label
              className="form-check-label font-lg pl-30"
              htmlFor={`address-${address.id}`}
            ></label>
          </td>
          <td className="border-none font-sm">
            <span className="text-extra-dark font-lg fw-600 mr-10">
              {`${address.first_name} ${address.last_name}`}
            </span>
            <address>{`${address.address_line_1} ${address.address_line_2} ${address.landmark}`}</address>
            <p className="font-xs mb-0">
              {`${address.city.city}, ${address.state.state}, ${address.pincode} , ${address.country.country}`}
            </p>
          </td>
          <td className="border-none">
            <Button
              text={"Edit Address"}
              type={BUTTON_CONSTANTS.BUTTON}
              className="btn btn-xs font-lg btn btn-xs no-wrap"
              onClick={() => {
                dispatch(
                  setQuickView({
                    state: true,
                    data: address.id,
                    type: "address",
                  })
                );
              }}
            />
          </td>
        </tr>
      );
    },
    [addressState[type]]
  );

  return (
    <div className="faq-item mb-20">
      <div
        className="faq-question mb-20"
        onClick={() => {
          setAddress({
            ...addressState,
            [type]: {
              ...addressState[type],
              state: !addressState[type].state,
            },
          });
        }}
      >
        <h6>{title}</h6>
        <Image
          height={24}
          width={24}
          src={
            (
              type === "shipping"
                ? addressState.shipping.state
                : addressState.billing.state
            )
              ? "/assets/imgs/theme/icons/icon-minus-small.svg"
              : "/assets/imgs/theme/icons/icon-plus-small.svg"
          }
          alt=""
        />
      </div>
      {(type === "shipping"
        ? addressState.shipping.state
        : addressState.billing.state) && (
        <>
          <div className="addresses-radio">
            <div>
              {addresses?.length > 0 ? (
                <table>
                  <tbody>{addresses.map(renderAddressItem)}</tbody>
                </table>
              ) : null}
            </div>
            <div>
              <Button
                text={"Add New Address"}
                type={BUTTON_CONSTANTS.BUTTON}
                className="btn btn-xs"
                onClick={() => {
                  dispatch(
                    setQuickView({
                      state: true,
                      data: null,
                      type: "address",
                    })
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { UserAddressList };
