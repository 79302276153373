"use client";

import { IFAQ, STATIC_CONSTANTS } from "@/utils";
import React, { useState } from "react";

import parse from "html-react-parser";
import Image from "next/image";

function FAQItem({ question, answer }: IFAQ) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item mb-50">
      <div className="faq-question" onClick={toggleAccordion}>
        <h5 className="">{question}</h5>
        <Image
          height={24}
          width={24}
          className="img-fluid"
          src={
            isOpen
              ? "/assets/imgs/theme/icons/icon-minus-small.svg"
              : "/assets/imgs/theme/icons/icon-plus-small.svg"
          }
          alt=""
        />
      </div>
      {isOpen && <div className="faq-answer pt-20">{parse(answer)}</div>}
    </div>
  );
}

const FAQ = ({ faqs }: { faqs: IFAQ[] }) => {
  return (
    <div className="page-content pt-30">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="single-header style-2 mb-30">
              <h1 className="heading-2 text-center">FAQs</h1>
            </div>
            <div className="single-page mb-lg-0 mb-sm-5">
              {faqs.map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FAQ };
