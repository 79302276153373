"use client";

import Link from "next/link";
import React from "react";
import { ICartItem, STATIC_CONSTANTS, STATUSES } from "@/utils";
import { CustomSelect, Loader, Ratings } from "@/components/core-components";
import {
  decrementCartItemsCounter,
  incrementCartItemsCounter,
  removeCartItem,
  removeProduct,
  setCartItemsCounter,
  setOffCanvas,
  updateCartItem,
  updateQuantity,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import Image from "next/image";

const OrderItems = ({
  showQuantity,
  cartItems,
}: {
  showQuantity: boolean;
  cartItems: any;
}) => {
  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const is_logged_in = logged_in_user || access_token;

  const { state } = useAppSelector((state) => state.common);

  const { cartItemsCounter } = useAppSelector((state) => state.cart);

  const dispatch = useAppDispatch();

  const renderSingleOrderItem = (cartItem: ICartItem, i: number) => {
    return (
      <article
        className={`row align-items-center hover-up article-card ${
          showQuantity ? "" : "bg-primary-2 border-none"
        }`}
        key={i}
      >
        <figure className="col-md-4 col-4 mb-0">
          <Link href="/[slug]" as={`/${cartItem.slug}`}>
            <Image
              src={cartItem.thumbnails?.[0]?.img}
              alt={cartItem.title}
              height={165}
              width={165}
            />
          </Link>
        </figure>

        <div className="col-md-8 col-8 mb-0 article-card-content">
          <h6>
            <Link href="/[slug]" as={`/${cartItem.slug}`}>
              {cartItem.title}
            </Link>
          </h6>

          <div className="product-rate-cover">
            <Ratings rating={cartItem.rating} />
          </div>

          <div className="product-price">
            {cartItem.oldPrice > 0 ? (
              <span className="old-price mr-10">
                <span className="rupee-symbol">₹</span>
                {cartItem.oldPrice}
              </span>
            ) : null}
            <span>
              <span className="rupee-symbol">₹</span>
              {cartItem.payable_amount}
            </span>
          </div>

          {showQuantity ? (
            <div className="d-flex gap-1 justify-content-between">
              <CustomSelect
                id={`order-item-${cartItem.title.replace(/\s/g, "-")}-weight`}
                options={[
                  {
                    value: cartItem.weight,
                    label: `${cartItem.weight} ${cartItem.uom}`,
                  },
                ]}
                name="weight"
                onChange={(e: any) => {}}
              />

              <div className="detail-extralink">
                <div className="detail-qty">
                  <a
                    className="qty-left"
                    onClick={() => {
                      if (is_logged_in) {
                        dispatch(
                          updateCartItem({
                            cart_id: cartItem.cart_id,
                            quantity: Number(cartItem.quantity - 1),
                          })
                        );
                      } else {
                        dispatch(
                          updateQuantity({
                            index: i,
                            quantity: Number(cartItem.quantity - 1),
                          })
                        );
                      }
                      dispatch(decrementCartItemsCounter());
                    }}
                  >
                    <i className="fi-rs-angle-small-left"></i>
                  </a>
                  <span className="qty-val">{cartItem.quantity}</span>
                  <a
                    className="qty-right"
                    onClick={() => {
                      if (is_logged_in) {
                        dispatch(
                          updateCartItem({
                            cart_id: cartItem.cart_id,
                            quantity: Number(cartItem.quantity + 1),
                          })
                        );
                      } else {
                        dispatch(
                          updateQuantity({
                            index: i,
                            quantity: Number(cartItem.quantity + 1),
                          })
                        );
                      }
                      dispatch(incrementCartItemsCounter());
                    }}
                  >
                    <i className="fi-rs-angle-small-right"></i>
                  </a>
                </div>
              </div>

              <div className="btn btn-xs bg-red text-white  d-flex align-items-center">
                <a
                  className="add w-100 text-center text-white"
                  onClick={() => {
                    dispatch(
                      setCartItemsCounter(cartItemsCounter - cartItem.quantity)
                    );
                    if (is_logged_in) {
                      dispatch(removeCartItem({ cart_id: cartItem.cart_id }));
                    } else {
                      dispatch(removeProduct({ index: i }));
                    }
                    if (cartItems?.length === 1) {
                      dispatch(
                        setOffCanvas({ state: false, component: "cart" })
                      );
                    }
                  }}
                >
                  {is_logged_in &&
                  state.status === STATUSES.LOADING &&
                  state.type === `delete-cartItem-${cartItem.cart_id}` ? (
                    <>
                      <Loader />
                      &nbsp;
                    </>
                  ) : (
                    "Delete"
                  )}
                </a>
              </div>
            </div>
          ) : (
            <div className="d-flex gap-1 justify-content-between">
              <CustomSelect
                options={STATIC_CONSTANTS.WEIGHTS}
                name="weight"
                onChange={(e: any) => {
                  // dispatch(updateWeight({ productId: cartItem.id, weight: e }));
                }}
              />

              <div className="btn btn-xs bg-white">
                <a className="add w-100 text-center text-brand no-wrap">
                  Add to Cart
                </a>
              </div>
            </div>
          )}
        </div>
      </article>
    );
  };

  const renderOrderItems = () => {
    return cartItems?.length > 0 ? cartItems?.map(renderSingleOrderItem) : null;
  };

  return renderOrderItems();
};

export { OrderItems };
