"use client";

import { CustomCheckboxProps } from "@/utils";
import { useField } from "formik";

const CustomCheckbox = ({
  filters,
  label,
  checkboxLabelClass,
  checkboxInputClass,
  checkboxErrorClass,
  onClickHandler,
  name,
  ...props
}: CustomCheckboxProps) => {
  const [field, meta] = useField(name);

  return (
    <>
      {filters.map((item, id) => (
        <div key={id}>
          <input
            {...field}
            {...props}
            type="checkbox"
            className={`form-check-input ${
              meta.touched && meta.error ? "input-error" : ""
            }`}
            checked={field.value}
            onClick={onClickHandler}
            id={name}
          />
          <label
            className="form-check-label user-select-none"
            htmlFor={name}
            style={{ textTransform: "capitalize" }}
          >
            {item.value}
          </label>
          {meta.touched && meta.error && (
            <div
              className={`${
                checkboxErrorClass ? checkboxErrorClass : "text-danger mb-2"
              }`}
            >
              {meta.error}
            </div>
          )}

          <br />
        </div>
      ))}
    </>
  );
};

export { CustomCheckbox };
