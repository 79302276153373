import React from "react";
import { CartSummary } from "../Cart";
import { formatDateTime, IOrderAddress, IOrderDetail, IPayment } from "@/utils";
import { Table, TableBody } from "@/components/core-components";

const OrderInfo = ({
  orderDetails,
  isOrderDetailPage,
  sectionclassName,
  cartItems,
  successfullPayment,
  shippingAddress,
  billingAddress,
}: {
  orderDetails: IOrderDetail;
  isOrderDetailPage: boolean;
  sectionclassName: string;
  cartItems: any[];
  successfullPayment: IPayment | undefined;
  shippingAddress: IOrderAddress | undefined;
  billingAddress: IOrderAddress | undefined;
}) => {
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="border p-md-4 p-15 border-radius cart-totals">
          <div className="row">
            {cartItems?.length === 0 ? null : isOrderDetailPage ? (
              <CartSummary
                cartItems={cartItems}
                title={"Order Summary"}
                parentClassName={"col-lg-3 col-md-3 order-summary-section"}
                shipping_cost={orderDetails?.total_shipping || 0}
              />
            ) : (
              <div className={`${sectionclassName} order-summary-section`}>
                <CartSummary
                  cartItems={cartItems}
                  shipping_cost={orderDetails?.total_shipping || 0}
                />
              </div>
            )}
            <div
              className={`${sectionclassName} ${
                isOrderDetailPage
                  ? "order-summary-section"
                  : "payment-info-section"
              }`}
            >
              {isOrderDetailPage ? (
                <>
                  <div className="heading_s1 mb-3">
                    <h5>Payment Method</h5>
                  </div>
                  <div>
                    <p>
                      {successfullPayment
                        ? successfullPayment?.payment_mode?.payment_mode
                        : "Please Retry your Payment"}
                    </p>
                  </div>
                </>
              ) : (
                <Table>
                  <TableBody>
                    <tr>
                      <td className="cart_total_label">Order ID</td>
                      <td className="cart_total_amount">
                        <span className="font-sm fw-500 text-dark">
                          {orderDetails?.invoice_code}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="cart_total_label">Ordered On</td>
                      <td className="cart_total_amount text-wrap">
                        {formatDateTime(orderDetails?.created_at)}
                      </td>
                    </tr>
                    {successfullPayment && (
                      <tr>
                        <td className="cart_total_label">Payment Method</td>
                        <td className="cart_total_amount">
                          {successfullPayment?.payment_mode?.payment_mode}
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </Table>
              )}
            </div>
            <div className={`${sectionclassName} shipping-info-section`}>
              <div className="heading_s1 mb-3">
                <h5>Shipping Address</h5>
              </div>
              {shippingAddress && (
                <div>
                  <p>
                    {shippingAddress?.name}
                    <br />
                    {shippingAddress?.address_line_1}
                    {`${shippingAddress?.address_line_2}, ${shippingAddress?.postal_code}`}
                  </p>
                  {/* <p>{`${shippingAddress?.address_line_2}, ${shippingAddress?.postal_code}`}</p> */}
                </div>
              )}
            </div>
            <div className={`${sectionclassName} billing-info-section`}>
              <div className="heading_s1 mb-3">
                <h5>Billing Address</h5>
              </div>
              {billingAddress && (
                <div>
                  <p>
                    {billingAddress?.name}
                    <br />
                    {billingAddress?.address_line_1}
                    {`${billingAddress?.address_line_2}, ${billingAddress?.postal_code}`}
                  </p>
                  {/* <p>{`${billingAddress?.address_line_2}, ${billingAddress?.postal_code}`}</p> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { OrderInfo };
