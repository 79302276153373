import React from "react";
import {
  Banner,
  BannerSlider,
  BannersList,
  BannerVideo,
  GiftHamper,
} from "../Banners";
import parse from "html-react-parser";
import Image from "next/image";
import Link from "next/link";

const BannerWidget = ({
  config,
  widgetIndex,
}: {
  config: {
    content: {
      media: string;
      alt: string;
      title: string;
      description: string;
      action: {
        text?: string;
        link: string;
      };
      small?: boolean;
      className?: string;
    }[];
    type: string;
    display_width: string;
    flow: string;
    alignment: string;
  };
  widgetIndex: number;
  pageDetails?: {
    display_name: string;
    short_summary: string;
  };
}) => {
  const checkFileType = (url: string): "video" | "image" => {
    const ext = url.split(".").pop();
    return ext === "mp4" || url.includes("youtube") ? "video" : "image";
  };

  const renderBannerContent = (item: {
    media: string;
    alt: string;
    title: string;
    description: string;
    action: {
      text?: string;
      link: string;
    };
    small?: boolean;
    className?: string;
  }) => {
    const commonProps = {
      img: checkFileType(item.media) === "image" ? item.media : "",
      alt: item.alt,
      title: item.title,
      description: item.description,
      btnText: item.action?.text,
      link: item.action?.link,
      small: item?.small || false,
      className: item?.className || "",
      videoUrl: checkFileType(item.media) === "video" ? item.media : "",
    };

    return commonProps;
  };

  const renderBanner = () => {
    if (config.content.length > 1) {
      const data = config.content.map(renderBannerContent);

      if (config.type === "banner-carousel") {
        const sliderProps = {
          data,
          className: "hero-slider-1 style-4 dot-style-1 dot-style-1-position-1",
          btnClassName: "hero-slider-1-arrow",
        };

        return config.display_width === "full-screen" ? (
          <section
            className="home-slider position-relative"
            key={`banner-full-screen-carousel-${widgetIndex}`}
          >
            <div className="home-slider-cover"></div>
            <BannerSlider
              lengthToRender={1}
              key={"full-screen-slider"}
              widgetIndex={widgetIndex}
              {...sliderProps}
              prevBtn="custom_prev_i3"
              nextBtn="custom_next_i3"
            />
          </section>
        ) : (
          <div
            className="container section-padding-12"
            key={`banner-container-carousel-${widgetIndex}`}
          >
            <div className="row flex-row-reverse">
              <div className="col-lg-12">
                <section className="home-slider position-relative">
                  <div className="home-slide-cover">
                    <BannerSlider
                      lengthToRender={0}
                      key={"container-slider"}
                      widgetIndex={widgetIndex}
                      {...sliderProps}
                      prevBtn="custom_prev_i1"
                      nextBtn="custom_next_i1"
                      pagination={{ clickable: true }}
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        );
      } else if (config.type === "banner-grid") {
        return (
          <BannersList
            banners={data}
            direction={"horizontal"}
            widgetIndex={widgetIndex}
            key={`banner-grid-${widgetIndex}`}
          />
        );
      }
    } else {
      const singleBanner = renderBannerContent(config.content[0]);

      if (
        checkFileType(singleBanner.img || singleBanner.videoUrl) === "video"
      ) {
        return (
          <section className="festive-option-3-section-1 section-padding-12">
            <BannerVideo data={singleBanner} />
          </section>
        );
      } else {
        const bannerProps = {
          src: singleBanner.img,
          alt: singleBanner.alt,
        };

        if (config.flow === "horizontal") {
          return singleBanner.btnText ? (
            <section
              className="festive-option-3-section-2 section-padding-12"
              key={`banner-${widgetIndex}`}
            >
              <GiftHamper data={singleBanner} widgetIndex={widgetIndex} />
            </section>
          ) : config.display_width === "full-screen" ? (
            <Banner
              {...bannerProps}
              widgetIndex={widgetIndex}
              key={`banner-full-screen-${widgetIndex}`}
            />
          ) : (
            <section
              className="bg-primary-2 section-padding-12"
              key={`banner-container-${widgetIndex}`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Image
                      alt={bannerProps.alt}
                      src={bannerProps.src}
                      width={1600}
                      height={600}
                      className={`w-100 h-100 border-radius-10`}
                      priority={widgetIndex <= 2}
                    />
                  </div>
                </div>
              </div>
            </section>
          );
        } else if (config.flow === "vertical") {
          return (
            <section
              className="festive-option-3-section-3 section-padding-12"
              key={`banner-vertical-${widgetIndex}`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="row mb-x">
                      <Banner
                        widgetIndex={widgetIndex}
                        children={
                          <div className="col-lg-12 text-center mt-40">
                            <h2 className="mb-30 prata-regular text-extra-dark">
                              {parse(singleBanner.title)}
                            </h2>
                            {singleBanner.btnText && (
                              <Link
                                href={singleBanner.link}
                                className="btn btn-xl prata-regular"
                              >
                                {singleBanner.btnText}
                              </Link>
                            )}
                          </div>
                        }
                        alt={singleBanner.alt}
                        src={singleBanner.img}
                        className="col-lg-12 mb-30 d-flex flex-column-reverse"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        }
      }
    }
  };

  return <>{renderBanner()}</>;
};

export { BannerWidget };
