"use client";

import { Button, CustomInput } from "@/components/core-components";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  UpdateProfileDetailsSchema,
} from "@/utils";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

const UpdateDetails = ({
  modalData = { state: false, data: null },
}: {
  modalData: { state: boolean; data: any };
}) => {
  const [otpSent, setOtpSent] = useState(0);

  const [otpTimer, SetOtpTimer] = useState<{
    seconds: number;
    minutes: number;
  }>({
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (modalData.state) {
      const interval = setInterval(() => {
        if (otpTimer.seconds > 0) {
          SetOtpTimer({
            ...otpTimer,
            seconds: otpTimer.seconds - 1,
          });
        }

        if (otpTimer.seconds === 0) {
          if (otpTimer.minutes === 0) {
            clearInterval(interval);
          } else {
            SetOtpTimer({
              seconds: 59,
              minutes: otpTimer.minutes - 1,
            });
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  });

  return (
    <div className="tab-pane fade active show">
      <div className={`${modalData.state ? "p-3" : ""} card add-address-card`}>
        <div className="card-header p-0">
          <div className="section-title style-2 mb-0">
            <h3>{`Update ${
              modalData.data.type === "email" ? "Email" : "Contact Number"
            }`}</h3>
          </div>
        </div>
        <div className={`card-body pt-0 pb-0 pl-5`}>
          <Formik
            initialValues={{
              new_value: "",
              old_value: modalData.data.value || "",
            }}
            onSubmit={(values, actions) => {}}
            validationSchema={UpdateProfileDetailsSchema({
              otpSent: otpSent > 0,
              type: modalData.data.type,
            })}
          >
            {(updateDetailsProps) => {
              return (
                <Form>
                  <div className="row mt-20">
                    <div className="col-md-12">
                      <CustomInput
                        placeholder={`Current ${
                          modalData.data.type === "email"
                            ? "Email"
                            : "Contact Number"
                        }`}
                        formikProps={updateDetailsProps}
                        label={`Current ${
                          modalData.data.type === "email"
                            ? "Email"
                            : "Contact Number"
                        }`}
                        disabled={true}
                        name={"old_value"}
                        isRequired={true}
                        type={FORM_CONSTANTS.TEXT}
                      />
                    </div>
                    <div className="col-md-12">
                      <CustomInput
                        placeholder={`Enter new ${
                          modalData.data.type === "email"
                            ? "Email"
                            : "Contact Number"
                        }`}
                        formikProps={updateDetailsProps}
                        label={`New ${
                          modalData.data.type === "email"
                            ? "Email"
                            : "Contact Number"
                        }`}
                        name={"new_value"}
                        isRequired={true}
                        type={FORM_CONSTANTS.TEXT}
                      />
                    </div>
                  </div>
                  <div className="row mt-10">
                    <div className="col-12">
                      <Button
                        className="btn btn-sm btn-fill-out submit font-weight-bold"
                        type={BUTTON_CONSTANTS.SUBMIT}
                        text={`${
                          otpSent > 0 ? "Update Details" : "Request OTP"
                        }`}
                        loading={updateDetailsProps.isSubmitting}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export { UpdateDetails };
