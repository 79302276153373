"use client";

import { Button, CustomInput } from "@/components/core-components";
import {
  setQuickView,
  updateProfileDetails,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  BUTTON_CONSTANTS,
  FORM_CONSTANTS,
  ProfileSchema,
  seperateFirstLastName,
  STATUSES,
} from "@/utils";
import { Form, Formik } from "formik";
import Link from "next/link";
import React from "react";

const ProfileForm = ({
  profileData,
}: {
  profileData: {
    id: number;
    name: string;
    email: string;
    contact_number: number;
    created_at: string;
  };
}) => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector((state) => state.common);

  const { first_name, last_name } = seperateFirstLastName(
    profileData?.name || ""
  );

  return (
    <Formik
      initialValues={{
        first_name: first_name || "",
        last_name: last_name || "",
        contact_number: profileData?.contact_number.toString() || "",
        email: profileData?.email || "",
      }}
      onSubmit={(values, actions) => {
        dispatch(updateProfileDetails({ data: values }));
      }}
      validationSchema={ProfileSchema}
    >
      {(profileProps) => (
        <Form>
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                placeholder="Enter First Name"
                formikProps={profileProps}
                label={"First Name"}
                name={"first_name"}
                isRequired={true}
                type={FORM_CONSTANTS.TEXT}
                titleCase={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                placeholder="Enter Last Name"
                formikProps={profileProps}
                label={"Last Name"}
                name={"last_name"}
                isRequired={true}
                type={FORM_CONSTANTS.TEXT}
                titleCase={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                placeholder="Enter Email"
                formikProps={profileProps}
                disabled={true}
                label={"Email"}
                // labelAddOn={
                //   <Link
                //     href="#"
                //     onClick={() => {
                //       dispatch(
                //         setQuickView({
                //           state: true,
                //           type: "change-details",
                //           data: {
                //             type: "email",
                //             value: profileProps?.values?.email,
                //           },
                //         })
                //       );
                //     }}
                //   >
                //     Change
                //   </Link>
                // }
                name={"email"}
                isRequired={true}
                type={FORM_CONSTANTS.EMAIL}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                placeholder="Enter Mobile Number"
                formikProps={profileProps}
                disabled={true}
                // labelAddOn={
                //   <Link
                //     href="#"
                //     onClick={() => {
                //       dispatch(
                //         setQuickView({
                //           state: true,
                //           type: "change-details",
                //           data: {
                //             type: "contact_number",
                //             value: profileProps?.values?.contact_number,
                //           },
                //         })
                //       );
                //     }}
                //   >
                //     Change
                //   </Link>
                // }
                label={"Mobile Number"}
                name={"contact_number"}
                isRequired={true}
                type={FORM_CONSTANTS.NUMBER}
                titleCase={true}
              />
            </div>
            <div className="col-md-12">
              <Button
                type="submit"
                className="btn btn-sm btn-fill-out submit font-weight-bold"
                text={"Save Changes"}
                loading={
                  state.status === STATUSES.LOADING &&
                  state.type === "update-profile"
                }
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { ProfileForm };
