import { notFound } from "next/navigation";
import {
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
} from "./constants";
import { apiRequest } from "./server_actions";
import {
  IDBMenu,
  IDBProduct,
  IFAQ,
  IProductCMSDetails,
  JwtPayload,
  TProductDetails,
  TReview,
} from "./types";

import parse from "html-react-parser";

export const validURL = (url: string) => {
  if (url.startsWith("_next/")) {
    return false;
  }

  return true;
};

export const constructSlug = (slugs: string[]) => {
  let finalSlug = "";
  slugs.forEach((slug, index) => {
    if (index === 0) {
      finalSlug = slug;
    } else {
      finalSlug = `${finalSlug}/${slug}`;
    }
  });
  return finalSlug;
};

export async function fetchPageData(
  slug: string[],
  is_logged_in: boolean = false
) {
  if (slug.includes("_next")) {
    return null; // Don't render anything on the server-side
  }
  try {
    const endpoint = `${
      STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL
    }/shop/pages/page/${slug.join("/")}`;

    if (is_logged_in) {
      const response = await apiRequest({
        url: endpoint,
        method: REQUEST_METHOD.GET,
        additionalHeaders: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
        tags: ["page-data"],
      }).then((res) => res.json());

      return response.result;
    } else {
      const response = await fetch(endpoint, {
        method: REQUEST_METHOD.GET,
        next: {
          tags: ["page-data"],
        },
        cache: "reload",
      }).then((res) => res.json());

      return response.result;
    }
  } catch (e) {
    console.log("====================================");
    console.log(e);
    console.log("====================================");
    // Toast(`Error in fetching page data ${e}`, TOAST_CONSTANTS.ERROR);
    return notFound();
  }
}

export async function fetchSiteData() {
  const response = await fetch(
    STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL +
      "/shop/site_management/manage/site_config/",
    {
      method: REQUEST_METHOD.GET,
      cache: "reload",
    }
  ).then((res) => res.json());
  return response.result;
}

export async function fetchMenuData(menuId: number, category?: boolean) {
  if (!menuId) return { menuItems: [], category: [] };

  const response = await fetch(
    `${
      STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL
    }/shop/menu/manage/menu/${menuId}/?category=${category ? 1 : 0}`,
    {
      cache: "reload",
    }
  ).then((res) => res.json());

  const menuItems = formatMenuData({ data: response.result });

  const categoryData =
    response.result?.category && response?.result?.category?.length > 0
      ? response.result.category.map(
          (category: {
            id: number;
            display_name: string;
            meta_slug: string;
            icon: string | null;
            icon_alt: string | null;
          }) => ({
            id: category.id,
            title: category.display_name,
            link: category.meta_slug,
            img: category.icon,
            alt: category.icon_alt,
          })
        )
      : [];

  return { menuItems: menuItems, category: categoryData || [] };
}

export async function fetchReviews(slug: string, rating: number) {
  const endpoint = `${
    STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL
  }/shop/product/manage/review/?query=${slug.split("/").splice(-1)}${
    rating ? `&rating=${rating}` : ""
  }&review_status=pending`;

  const response = await fetch(endpoint, {
    method: REQUEST_METHOD.GET,
    next: {
      tags: ["reviews"],
    },
    cache: "reload",
  }).then((res) => res.json());

  return {
    total_pages: Math.ceil(response.result.count / 10),
    data: response.result.results,
  };
}

export const formatProductForOrder = (item: IProductCMSDetails) => {
  return {
    id: item.id,
    category: item.category.display_name,
    sub_category: item.sub_category.display_name,
    slug: constructSlug([
      item.category.meta_slug,
      item.sub_category.meta_slug,
      item.meta_slug,
    ]),
    title: item.product_name,
    jain_friendly: item.product.jain_friendly,
    uom: item?.product?.uom?.uom_code,
    weights: item.product_weights
      .map((weight: any) => ({
        id: weight.id,
        value: weight.weight,
        label: weight.weight,
        uom: item?.product?.uom?.uom_code,
      }))
      .sort((a, b) => a.value - b.value),
    rating: 4,
    images: item.product_medias
      .filter((item) => item.media_type === "full")
      .map((item: any) => ({
        alt: item.alt,
        img: item.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority),
    thumbnails: item.product_medias
      .filter((item) => item.media_type === "thumbnail")
      .map((item: any) => ({
        alt: item.alt,
        img: item.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority),
  };
};

export const formatProduct = (item: IDBProduct) => {
  if (!item) {
    throw new Error("Something went wrong while fetching product");
  }

  return {
    id: item.id,
    category: item.category.display_name,
    sub_category: item.sub_category.display_name,
    slug: constructSlug([
      item.category.meta_slug,
      item.sub_category.meta_slug,
      item.meta_slug,
    ]),
    product: item.product,
    title: item.product_name,
    jain_friendly: item.product.jain_friendly,
    tastes: item.product?.taste_details?.map((item) => item.taste.taste_name),
    description: item.description,
    disclaimer: item.disclaimer,
    weights: item.product_weights
      .map((weight: any) => ({
        id: weight.id,
        value: weight.weight,
        label: weight.weight,
        uom: item?.product?.uom?.uom_code,
      }))
      .sort((a, b) => a.value - b.value),
    rating: 4,
    wishlist_added: item.wishlist_added,
    batch_id: item.product_price.batch,
    price: item.product_price ? item.product_price.selling_price : 0,
    oldPrice:
      (item.product_price ? item.product_price.mrp : 0) >
      (item.product_price ? item.product_price.selling_price : 0)
        ? item.product_price
          ? item.product_price.mrp
          : 0
        : 0,
    discount: {
      type:
        item.product_price && item.product_price.selling_discount_type
          ? item.product_price.selling_discount_type === 4
            ? "percentage"
            : "flat"
          : "",
      value: item.product_price ? item.product_price.selling_discount : 0,
    },
    product_id: item.product.id,
    product_code: item.product.product_code,
    shelf_life: item.product.expiration_days,
    net_weight: item.product.net_weight,
    uom: item?.product?.uom?.uom_code,
    selling_uom: item?.product?.selling_uom?.uom_code,
    tax: Number(
      item?.product?.product_tax?.find((item) => item.tax_type === 1)
        ?.tax_rate || 0
    ),
    cess: Number(
      item?.product?.product_tax?.find((item) => item.tax_type !== 1)
        ?.tax_rate || 0
    ),
    taxes: item.product.product_tax,
    // images: item.product_medias
    //   .map((item: any) => ({
    //     alt: item.alt,
    //     img: item.media_file,
    //   }))
    //   .sort((a: any, b: any) => a.priority - b.priority),
    images: item.product_medias
      .filter((item) => item.media_type === "full")
      .map((item: any) => ({
        alt: item.alt,
        img: item.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority),
    thumbnails: item.product_medias
      .filter((item) => item.media_type === "thumbnail")
      .map((item: any) => ({
        alt: item.alt,
        img: item.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority),
  };
};

const to2Decimal = (input: number | string) => {
  if (input.toString().includes("-")) {
    return Number(input);
  } else {
    return Number(Number(input).toFixed(2));
  }
};

export const calculatePrice = (
  netWeight: number,
  basePrice: number,
  weight: number
) => {
  return to2Decimal((basePrice * weight) / netWeight);
};

export const formatMenuData = ({ data }: { data: IDBMenu }): any => {
  if (!data) return null;

  return data.menu_items && data.menu_items.length > 0
    ? data.menu_items
        .map((menu) => {
          return {
            id: menu.id,
            menu_item_title: menu.menu_item_title,
            slug: menu.url,
            is_mega_menu: menu.is_mega_menu,
            mega_menu: menu.is_mega_menu
              ? formatMenuData({ data: menu.mega_menu })
              : null,
            priority: menu.priority,
            children: menu.children
              .map((child) => {
                return {
                  id: child.id,
                  menu_item_title: child.menu_item_title,
                  slug: child.url,
                  priority: child.priority,
                };
              })
              .sort((a, b) => a.priority - b.priority),
          };
        })
        .sort((a, b) => a.priority - b.priority)
    : [];
};

export const transformDataToBannerConfig = (input: any) => {
  const bannerContent = input.content.find(
    (item: any) => item.name === "banner_content"
  ).inner_form;

  const bannerType = input.content.find(
    (item: any) => item.name === "banner_type"
  ).value[0].value;

  const displayWidth = input.content.find(
    (item: any) => item.name === "display_width"
  ).value[0].value;

  const flow = input.content.find((item: any) => item.name === "flow").value[0]
    .value;

  const alignment = input.content.find((item: any) => item.name === "alignment")
    .value[0].value;

  const content = bannerContent.map((item: any) => ({
    media: item.find((subItem: any) => subItem.name === "media").value.preview,
    alt: item.find((subItem: any) => subItem.name === "alt").value,
    title: item.find((subItem: any) => subItem.name === "title").value,
    description: item.find((subItem: any) => subItem.name === "description")
      .value,
    className: "",
    small: false,
    action: {
      link: item.find((subItem: any) => subItem.name === "btnlink").value,
      text: item.find((subItem: any) => subItem.name === "btnText").value,
    },
  }));

  return {
    content,
    type: bannerType,
    display_width: displayWidth,
    flow: flow,
    alignment: alignment,
  };
};

export const transformDataToListingConfig = (input: any) => {
  const type = input.content.find((item: any) => item.name === "type").value[0]
    .value;

  const contentType = input.content.find(
    (item: any) => item.name === "content_type"
  ).value[0].value;

  const title = input.content.find((item: any) => item.name === "title").value;

  const largeCard =
    input.content.find((item: any) => item.name === "largeCard").value[0]
      .value === 1;

  const extraFilters =
    input.content.find((item: any) => item.name === "extraFilters").value[0]
      .value === 1;

  const filters = input.content
    .find((item: any) => item.name === "filters")
    .value.map((item: any) => item.label);

  const content = input.content
    .find((item: any) => item.name === "content")
    .value.map((item: IDBProduct | any) => {
      if (contentType === "product") {
        return formatProduct(item);
      } else if (contentType === "category") {
        return {
          title: item.display_name,
          img: item.icon,
          desc: item.short_summary,
          link: item.meta_slug,
          alt: item.icon_alt,
          hasDesc: true,
        };
      } else if (contentType === "sub-category") {
        return {
          title: item.display_name,
          img: item.icon,
          desc: item.short_summary,
          slug: item.meta_slug,
          link: constructSlug([
            item.product_category.meta_slug || "",
            item.meta_slug,
          ]),
          alt: item.icon_alt,
          hasDesc: true,
        };
      }
    });

  const fetchUrl =
    input.content.find((item: any) => item.name === "content").value?.length ===
    0
      ? `/shop/product/search/${
          input.type === "category"
            ? `?category=${input.page_id}`
            : input.type === "sub_category"
            ? `?subcategory=${input.page_id}`
            : ""
        }`
      : "";

  return {
    content: content,
    type,
    title,
    largeCard,
    extraFilters,
    filters,
    fetchUrl,
    totalPages: 0,
    count: 0,
  };
};

export const transformDataToMultipleListingConfig = (input: any) => {
  const listContent = input.content.find(
    (item: any) => item.name === "list_content"
  ).inner_form;

  const content = listContent.map((item: any) => ({
    title: item.find((subItem: any) => subItem.name === "title").value,
    content: item
      .find((subItem: any) => subItem.name === "content")
      .value.map((item: IDBProduct) => {
        return formatProduct(item);
      }),
  }));

  return {
    content,
  };
};

export const transformDataToProductDealsConfig = (input: any) => {
  const maintitle = input.content.find(
    (item: any) => item.name === "main_title"
  ).value;

  const mainDescription = input.content.find(
    (item: any) => item.name === "main_description"
  ).value;

  const dealContent = input.content.find(
    (item: any) => item.name === "deal_content"
  ).inner_form;

  const content = dealContent.map((item: any) => ({
    media: item.find((subItem: any) => subItem.name === "media").value.preview,
    title: item.find((subItem: any) => subItem.name === "title").value,
    description: item.find((subItem: any) => subItem.name === "description")
      .value,
    action: {
      link: item.find((subItem: any) => subItem.name === "btnlink").value,
      text: item.find((subItem: any) => subItem.name === "btnText").value,
    },
  }));

  return {
    content,
    main_title: maintitle,
    main_description: mainDescription,
  };
};

export const getPaymentModeId = (payment_mode: string) => {
  switch (payment_mode) {
    case "netbanking":
      return 16;
    case "upi":
      return 15;
    case "card":
      return 14;
    case "wallet":
      return 19;
    default:
      return 0;
  }
};

export function isTokenValid(token: any): boolean {
  const currentTime = Math.floor(Date.now() / 1000);

  if (token.exp < currentTime) {
    return false;
  }

  if (token.iat > currentTime) {
    return false;
  }

  return true;
}

export function generateOrganizationStucturedData() {
  const test = {
    "@context": "https://schema.org",
    "@type": "Organization",
    image: "https://www.example.com/example_image.jpg",
    url: "https://www.example.com",
    sameAs: [
      "https://example.net/profile/example1234",
      "https://example.org/example1234",
    ],
    logo: "https://www.example.com/images/logo.png",
    name: "Example Corporation",
    description:
      "The example corporation is well-known for producing high-quality widgets",
    email: "contact@example.com",
    telephone: "+47-99-999-9999",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Rue Improbable 99",
      addressLocality: "Paris",
      addressCountry: "FR",
      addressRegion: "Ile-de-France",
      postalCode: "75001",
    },
    vatID: "FR12345678901",
    iso6523Code: "0199:724500PMK2A2M1SQQ228",
  };

  return {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Avarya Retail Pvt Ltd",
    alternateName: "Avarya",
    url: "https://www.avarya.in",
    logo: "https://avaryaallimages.s3.ap-south-1.amazonaws.com/site-settings/icon/avarya-brand-logo.png",
    sameAs: [
      "https://www.facebook.com/avaryaretail",
      "https://www.instagram.com/avaryaretail",
      "https://www.youtube.com/@avaryaretail",
    ],
  };
}

export function generateWebsiteStructuredData() {
  return {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Avarya Retail Pvt Ltd",
    url: STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL,
    potentialAction: {
      "@type": "SearchAction",
      target: `${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/search?query={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };
}

export function generateLocalBusinessStructureData() {
  return {
    "@context": "https://schema.org",
    "@type": "ConvenienceStore",
    name: "Avarya Retail Pvt Ltd",
    image:
      "https://avaryaallimages.s3.ap-south-1.amazonaws.com/site-settings/icon/avarya-brand-logo.png",
    id: STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL,
    url: STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL,
    telephone: "+919152414320",
    priceRange: "₹₹₹",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Shop No 5 to 9, Avighna 9,",
      addressLocality: "Lalbaug",
      postalCode: "400012",
      addressCountry: "IN",
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "09:00",
      closes: "21:00",
    },
    sameAs: [
      "https://www.facebook.com/avaryaretail",
      "https://www.instagram.com/avaryaretail",
      "https://www.youtube.com/@avaryaretail",
    ],
    menu: "https://menu.avarya.in/avarya_menu/menu_np/common_menu_np.pdf",
  };
}

export function generateBreadcrumbStructuredData(
  type: "Static" | "Product" | "Category" | "Sub Category",
  slug?: string[]
): any {
  const baseUrl = STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL;

  // Convert slug to breadcrumb parts
  const breadcrumbParts = slug?.map((part, index) => {
    const name = part
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return {
      "@type": "ListItem",
      position: index + 2, // Start from 2 since "Home" is at position 1
      name: name,
      item: `${baseUrl}/${slug.slice(0, index + 1).join("/")}`,
    };
  });

  // Add Home breadcrumb at the beginning
  const breadcrumbData = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    name: `${type} Breadcrumb`,
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: baseUrl,
      },
      ...(breadcrumbParts || []),
    ],
  };

  return breadcrumbData;
}

export function extractContent(html: string): string {
  // Replace <br> tags with line breaks
  const content = html.replace(/<br\s*\/?>/gi, "\n");

  // Remove any remaining HTML tags
  const textContent = content.replace(/<\/?[^>]+(>|$)/g, "");

  return textContent.trim();
}

function findBestAndWorstRatings(reviews: TReview[]) {
  if (reviews.length === 0) {
    return {
      bestRating: null,
      worstRating: null,
    };
  }

  let bestRating = reviews[0].rating;
  let worstRating = reviews[0].rating;

  for (const review of reviews) {
    if (review.rating > bestRating) {
      bestRating = review.rating;
    }
    if (review.rating < worstRating) {
      worstRating = review.rating;
    }
  }

  return {
    bestRating,
    worstRating,
  };
}

export function generateFAQStructuredData(faqs: IFAQ[]): object {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    name: `FAQs`,
    mainEntity: faqs.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: extractContent(faq.answer),
      },
    })),
  };
}

export function generateProductStructuredData(
  productDetails: TProductDetails,
  reviews: TReview[]
) {
  const productSchema: any = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: productDetails.title,
    image: productDetails.images.map((item) => item.img),
    description: extractContent(productDetails.description),
    brand: {
      "@type": "Brand",
      name: "Avarya",
    },
    sku: productDetails.product.product_code.toString(),
    offers: {
      "@type": "Offer",
      url: `${STATIC_CONSTANTS.ENV_CONSTANTS.SITE_URL}/${productDetails?.slug}`,
      priceCurrency: "INR",
      price: (productDetails.selling_uom === "pcs"
        ? productDetails.price
        : calculatePrice(
            1000,
            productDetails.price,
            productDetails.weights?.[0]?.value
          )
      ).toString(),
      availability: "https://schema.org/InStock",
      itemCondition: "https://schema.org/NewCondition",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: productDetails.rating.toString(),
      bestRating: "5",
      worstRating: "1",
      ratingCount: "580",
      reviewCount: "580",
      // ratingCount: reviews.length.toString(),
      // reviewCount: reviews.length.toString(),
    },
  };

  if (reviews?.length > 0) {
    const reviewSchema = reviews?.map((review) => ({
      "@type": "Review",
      reviewBody: review.review_comment,
      reviewRating: {
        "@type": "Rating",
        bestRating: "5",
        ratingValue: review.rating.toString(),
        worstRating: "1",
      },
      datePublished: review.review_date.split("T")[0],
      author: {
        "@type": "Person",
        name: review.customer_details.name,
      },
    }));

    productSchema["review"] = reviewSchema;
  }

  return productSchema;
}

export const constructStructuredData = ({
  slug,
  type,
  productDetails,
  reviews,
  products,
  faqs = [],
}: {
  slug?: string[];
  type: "Static" | "Product" | "Category" | "Sub Category";
  productDetails?: TProductDetails;
  reviews?: TReview[];
  products?: TProductDetails[];
  faqs?: IFAQ[];
}) => {
  const jsonLd = [
    generateWebsiteStructuredData(),
    generateOrganizationStucturedData(),
    generateLocalBusinessStructureData(),
    ...(slug && slug?.length > 0
      ? [generateBreadcrumbStructuredData(type, slug)]
      : []),
    ...(faqs?.length > 0 ? [generateFAQStructuredData(faqs)] : []),
    ...(productDetails && reviews
      ? [generateProductStructuredData(productDetails, reviews)]
      : []),
    ...((type === "Category" || type === "Sub Category") &&
    products &&
    products?.length > 0
      ? products?.map((item) => generateProductStructuredData(item, []))
      : []),
  ];

  return jsonLd;

  // .map((data, index) => (
  //   <Script
  //     key={index}
  //     type="application/ld+json"
  //     dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
  //   />
  // ))
};
