"use client";

import {
  OrderFilters,
  ProductHorizontalListing,
  Table,
  TableBody,
  TableHeader,
} from "@/components";
import {
  capitalizeFirstLetter,
  displayValue,
  formatDateTime,
  IOrderListing,
  STATIC_CONSTANTS,
  usePagination,
} from "@/utils";
import Link from "next/link";

const OrderListing = ({
  orders,
  total_pages,
}: {
  orders: IOrderListing[];
  total_pages: number;
}) => {
  return (
    <div className="tab-pane fade active show">
      <div className="card order-history">
        <div className="card-header p-0 pb-15">
          <div className="mb-0 shop-product-fillter d-flex justify-content-between align-items-center">
            <div className="section-title style-2 mb-0">
              <h3>Your Orders</h3>
            </div>
            {/* {orders?.length > 0 && <OrderFilters />} */}
          </div>
        </div>
        {orders?.length > 0 ? (
          <div className="card-body p-0">
            <div className="table-responsive pb-30 overflow-y-hidden">
              <Table
                paginationClassName="pagination-area mt-15 mb-sm-5 mb-lg-0"
                pagination
                total_page={total_pages}
              >
                <TableHeader
                  cols={[
                    { col: "Order ID", className: "bg-grey-9" },
                    // { col: "Items", className: "bg-grey-9" },
                    { col: "Date", className: "bg-grey-9" },
                    { col: "Order Status", className: "bg-grey-9" },
                    { col: "Payment Status", className: "bg-grey-9" },
                    { col: "Total", className: "bg-grey-9" },
                    { col: "Actions", className: "bg-grey-9" },
                  ]}
                />

                <TableBody>
                  {orders.length > 0 ? (
                    orders.map((item, index) => (
                      <tr key={`order-item-${index}`}>
                        <td width={"5%"}>{item.invoice_code}</td>
                        {/* <td width={"50%"}>items</td> */}
                        <td>{formatDateTime(item.created_at)}</td>
                        <td>{capitalizeFirstLetter(item.order_status)}</td>
                        <td>{capitalizeFirstLetter(item.payment_status)}</td>
                        <td>
                          <span className="rupee-symbol">₹</span>
                          {displayValue(item.payable_amount)}
                        </td>
                        <td className="d-flex">
                          <Link
                            href={`/account/orders/${item.id}`}
                            className="btn-small d-block"
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>No Orders</tr>
                  )}
                </TableBody>
              </Table>
            </div>
            {/* <ProductHorizontalListing
            data={STATIC_CONSTANTS.PRODUCTS}
            title="Suggestions"
            parentClassName="row"
            slidesPerView={6}
            spaceBetween={30}
            prevBtn={"custom_prev_1"}
            nextBtn={"custom_next_1"}
            btnClassName="slider-arrow-2 carausel-6-columns-arrow"
            sliderClassName="col-12"
            sliderId="row related-products position-relative"
          /> */}
          </div>
        ) : (
          "No Orders"
        )}
      </div>
    </div>
  );
};

export { OrderListing };
