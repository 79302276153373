import { ITestimonial, STATIC_CONSTANTS } from "@/utils";
import Image from "next/image";
import React from "react";

import parse from "html-react-parser";

import user from "../../../public/assets/imgs/theme/icons/icon-user.svg";

const Testimonials = ({ testimonials }: { testimonials: ITestimonial[] }) => {
  return (
    <section className="pt-30">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <div className="row mb-x">
              <div className="row mb-30">
                <div className="col-lg-12">
                  <h1 className="heading-2 text-center">Testimonials</h1>
                </div>
              </div>
              <div className="row">
                {testimonials.map((testimonial, index) => (
                  <div key={index} className="col-lg-4 col-md-6 mb-24">
                    <div className="testimonial-card featured-card bg-primary-2 border-none text-center">
                      <Image
                        height={50}
                        width={50}
                        className="img-fluid quotes"
                        src={"/assets/imgs/theme/icons/icon-quote.png"}
                        alt="quote"
                      />
                      <Image
                        height={100}
                        width={100}
                        className="border-radius-5"
                        src={user}
                        alt={testimonial.name}
                      />
                      <div className="font-lg line-height-2 text-justify">
                        {parse(testimonial.review)}
                      </div>
                      <h4 className="mb-5">
                        {`${testimonial.title} ${testimonial.name}`}
                      </h4>
                      {/* <span>{testimonial.position}</span> */}
                      {/* <div className="social-network mt-20">
                        <a href={testimonial.socialLinks.facebook}>
                          <img
                            src="/assets/imgs/theme/icons/icon-facebook-brand.svg"
                            alt="facebook"
                          />
                        </a>
                        <a href={testimonial.socialLinks.twitter}>
                          <img
                            src="/assets/imgs/theme/icons/icon-twitter-brand.svg"
                            alt="twitter"
                          />
                        </a>
                        <a href={testimonial.socialLinks.instagram}>
                          <img
                            src="/assets/imgs/theme/icons/icon-instagram-brand.svg"
                            alt="instagram"
                          />
                        </a>
                        <a href={testimonial.socialLinks.youtube}>
                          <img
                            src="/assets/imgs/theme/icons/icon-youtube-brand.svg"
                            alt="youtube"
                          />
                        </a>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Testimonials };
