import React from "react";

const ReviewModal = ({
  visible,
  setVisible,
}: {
  visible: {
    state: boolean;
    content: {
      media_url: string;
      media_type: string;
    };
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      state: boolean;
      content: {
        media_url: string;
        media_type: string;
      };
    }>
  >;
}) => {
  if (!visible.state) return null;

  const handleCloseClick = (e: any) => {
    e.stopPropagation();
    setVisible({ state: false, content: { media_url: "", media_type: "" } });
  };

  return (
    <div className="review-media-modal modalOverlay" onClick={handleCloseClick}>
      <div className="modalContent" onClick={(e) => e.stopPropagation()}>
        <button className="closeButton" onClick={handleCloseClick}>
          &times;
        </button>
        {visible.content.media_type === "image" && (
          <img
            src={visible.content.media_url}
            alt="Selected"
            className="modal-image"
          />
        )}
        {visible.content.media_type === "video" && (
          <video controls className="modal-video">
            <source src={visible.content.media_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  );
};

export { ReviewModal };
