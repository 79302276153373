import Link from "next/link";
import parse from "html-react-parser";
import React from "react";
import { BannerItemProps } from "@/utils";
import Image from "next/image";

const BannerItem = ({
  banner,
  length,
  index,
  widgetIndex,
  direction
}: {
  banner: BannerItemProps;
  length: number;
  index: number;
    widgetIndex: number;
  direction: string
}) => {
  return (
    // ${index >= 2 ? "col-md-6" : "d-md-none d-lg-flex"}

    <div
      className={`${
        direction == "vertical"
          ? "col-12"
          : `col-lg-${length >= 2 ? 4 : 6} ${
              index >= 2 ? "d-md-none d-lg-inline" : "col-md-6"
            }`
      }
        `}
    >
      <div
        className='banner-img mb-sm-0 wow animate__animated animate__fadeInUp'
        data-wow-delay='.4s'
      >
        <Image
          width={banner.width || 500}
          height={banner.height || 400}
          className='w-100 h-100'
          src={banner?.img || "/assets/images/banners/banner-1.jpg"}
          alt={banner?.title || "banner"}
          priority={widgetIndex <= 2}
        />
        {/* <div className="banner-text">
          <h4
            className={`${banner.className ? banner.className : ""} ${
              length <= 2 && !banner.small ? "fs-1" : "fs-2"
            }`}
          >
            {parse(banner.title)}
          </h4>

          <Link
            href={banner.link || "/"}
            className={`btn btn-${length <= 2 ? "md" : "xs"}`}
          >
            {banner.btnText}&nbsp;
            <i className={`${banner?.icon || "fi-rs-arrow-small-right"}`}></i>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export { BannerItem };
