"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";
import { strip_html_tags } from "@/utils";
import { useRouter } from "next/navigation";

const CategoryItem = ({
  item,
  index,
  hasDesc = false,
  widgetIndex,
}: {
  item: any;
  index: number;
  hasDesc: boolean;
  widgetIndex: number;
}) => {
  const router = useRouter();

  return (
    <div
      className={`${
        widgetIndex ? "category-listing-item" : "compact-category-item"
      }`}
      key={`category-listing-item-${index}`}
      onClick={() => router.push(`/${item.link}`)}
    >
      <figure className="img-hover-scale overflow-hidden">
        <Link href={item.link || "/"}>
          <Image
            src={item?.img}
            alt={item.alt}
            width={140}
            height={140}
            className="img-fluid w-100 h-100"
            priority={widgetIndex <= 2}
          />
        </Link>
      </figure>
      <h6 className={`${widgetIndex ? "mb-10" : ""}`}>
        <Link href={`/${item.link}`}>{item.title}</Link>
      </h6>
      {hasDesc ? (
        <p className="font-xxs category-item-description">
          {strip_html_tags(item.desc)}
        </p>
      ) : null}
    </div>
  );
};

export { CategoryItem };
