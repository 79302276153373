"use client";

import { STATIC_CONSTANTS } from "@/utils";
import { BannerSlider } from "./Banners";
import {
  CategoryHeader,
  CategoryHorizontalListing,
  ListingWithFilters,
  ProductHamperDeal,
} from "./Listing";

const Category = ({ slugs }: { slugs: string[] }) => {
  return (
    <>
      {/* <CategoryHeader /> */}
      <div className="container pb-30">
        <div className="row flex-row-reverse">
          <div className="col-lg-12">
            <section className="home-slider position-relative">
              <div className="home-slide-cover mt-40">
                <BannerSlider
                  lengthToRender={1}
                  key={"category-slider"}
                  data={STATIC_CONSTANTS.CATEGORY_CAROUSEL}
                  prevBtn="custom_prev_i1"
                  nextBtn="custom_next_i1"
                  className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1"
                  btnClassName="hero-slider-1-arrow"
                  pagination={{
                    clickable: true,
                  }}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
      <ProductHamperDeal
        data={STATIC_CONSTANTS.PRODUCT_HAMPER_DEAL}
        widgetIndex={0}
      />
      <CategoryHorizontalListing
        lengthToRender={1}
        data={STATIC_CONSTANTS.CATEGORIES}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        title="Sub Categories"
        key={"sub-category-slider"}
        parentClassName="popular-categories section-padding-12 subcategory-carousel mt-0"
        slideClassName="card-1"
        sliderClassName="carausel-8-columns-cover position-relative"
        sliderId="carausel-5-columns"
        slidesPerView={5}
        spaceBetween={30}
        prevBtn={"custom_prev_ct1"}
        nextBtn={"custom_next_ct1"}
        btnClassName="slider-arrow-2 flex-right carausel-10-columns-arrow"
        hasDesc={true}
      />

      <ListingWithFilters
        title="Products"
        filters={["All", "Popular", "Trending"]}
        extraFilters={true}
      />

      <CategoryHorizontalListing
        lengthToRender={1}
        data={STATIC_CONSTANTS.CATEGORIES}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        title="Other Categories"
        key={"sub-category-slider"}
        parentClassName="popular-categories section-padding-12 subcategory-carousel mt-0"
        slideClassName="card-1"
        sliderClassName="carausel-8-columns-cover position-relative"
        sliderId="carausel-5-columns"
        slidesPerView={5}
        spaceBetween={30}
        prevBtn={"custom_prev_ct1"}
        nextBtn={"custom_next_ct1"}
        btnClassName="slider-arrow-2 flex-right carausel-10-columns-arrow"
        hasDesc={true}
      />
    </>
  );
};

export { Category };
