import {
  REQUEST_METHOD,
  STATUSES,
  GetCookie,
  STATIC_CONSTANTS,
  apiRequestClient,
  REQUEST_CONTENT_TYPE,
  API_ROUTES,
} from "@/utils";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { RootState } from "../store";
import {
  addToWishlist,
  resetPendingItemsToAdd,
  setWishlistCounter,
} from "./wishlistSlice";
import { resetCartState, setCartItemsCounter } from "./cartSlice";
import { setCommonState, setControllerState } from "./commonSlice";

const initialState = {
  auth_state: STATUSES.IDLE as string,
  logged_in_user: null as null | any,
  customer_details: null as null | any,
  access_token: null as null | any,
  refresh_token: null as null | any,
  register_details: "",
  verification_pending: false,
};

export const setTokens = createAsyncThunk(
  "auth/setTokens",
  async (
    {
      data,
    }: {
      data: {
        access_token: string;
        refresh_token: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const access_token = await GetCookie({
        name: STATIC_CONSTANTS.TOKEN_CONSTANTS.ACCESS_TOKEN,
      });

      const refresh_token = await GetCookie({
        name: STATIC_CONSTANTS.TOKEN_CONSTANTS.REFRESH_TOKEN,
      });

      return {
        access_token,
        refresh_token,
      };
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  "auth/fetchUserDetails",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiRequestClient({
        url: "/api/refresh",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data: {
          token: await GetCookie({
            name: STATIC_CONSTANTS.TOKEN_CONSTANTS.REFRESH_TOKEN,
          }),
        },
      });

      dispatch(setCartItemsCounter(response.result.cart_items_count));

      dispatch(setWishlistCounter(response.result.wishlist_items_count));

      return {
        access_token: response.result.access_token,
        refresh_token: response.result.refresh_token,
        customer_details: response.result.customer_details,
      };
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (
    {
      data,
      router,
      onSuccess,
    }: {
      data: {
        email?: string;
        contact_number?: number;
      };
      router: AppRouterInstance;
      onSuccess?: () => void;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      common: { controller: abortController },
    } = getState() as RootState;

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    try {
      const response = await apiRequestClient({
        url: "/api/auth/login",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
        showToast: false,
        signal: controller.signal,
      });

      // Toast(
      //   `Your OTP is ${
      //     response.result.email_otp || response.result.mobile_otp
      //   }`,
      //   TOAST_CONSTANTS.SUCCESS
      // );

      onSuccess && onSuccess();

      if (!Array.isArray(response.result) && !response.result.email) {
        dispatch(setVerificationPending(true));
      }

      return response;
    } catch (e: any) {
      const error = JSON.parse(e);

      if (error.error.status === 417) {
        dispatch(setRegisterDetails(data.email || data.contact_number));
        router.replace("/account/register");
      }

      rejectWithValue(e.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch("/api/auth/logout/", {
        method: REQUEST_METHOD.GET,
      }).then((res) => res.json());

      if (response) {
        dispatch(resetCartState());
        dispatch(setCartItemsCounter(0));
        dispatch(setWishlistCounter(0));
      }
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (
    {
      data,
      router,
    }: {
      data: {
        email?: string;
        email_otp?: number;
        contact_number?: number;
        mobile_otp?: number;
      };
      router: AppRouterInstance;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      wishlist: { pendingItemsToAdd },
      cart: { cartItems },
      common: { controller: abortController },
    } = getState() as RootState;

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "verify-otp",
      })
    );

    try {
      const response = await apiRequestClient({
        url: "/api/auth/otp/",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
        signal: controller.signal,
      });

      dispatch(setVerificationPending(false));

      // const response = await fetch("/api/auth/otp/", {
      //   method: REQUEST_METHOD.POST,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // }).then((res) => res.json());

      dispatch(setCartItemsCounter(response.result.cart_items_count));

      dispatch(setWishlistCounter(response.result.wishlist_items_count));

      if (cartItems.length > 0) {
        try {
          const response = await apiRequestClient({
            url: "/api/cart/",
            method: REQUEST_METHOD.PUT,
            contentType: REQUEST_CONTENT_TYPE.APP_JSON,
            data: {
              cart_items: cartItems.map((item) => ({
                product_id: item.id,
                quantity: item.quantity,
                weight_id: item.weight_id,
              })),
            },
          });
        } catch (e: any) {
          rejectWithValue(e.message);
        }
      }

      try {
        if (pendingItemsToAdd.length > 0) {
          pendingItemsToAdd.forEach((item) => {
            dispatch(addToWishlist({ product_id: item }));
          });
        }
      } catch (e: any) {
        rejectWithValue(e.message);
      } finally {
        dispatch(resetPendingItemsToAdd());
      }

      router.back();

      return response;
    } catch (e: any) {
      rejectWithValue(e.message);
    } finally {
      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "verify-otp",
        })
      );
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (
    {
      data,
      router,
    }: {
      data: {
        name: string;
        contact_number: string;
        country_code: string;
        // whatsapp_number: string;
        email: string;
      };
      router: AppRouterInstance;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await apiRequestClient({
        url: "/api/auth/register",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
      });

      if (response) {
        dispatch(
          loginUser({
            data: {
              contact_number: response.result.contact_number,
            },
            router,
          })
        );
      }

      dispatch(setVerificationPending(false));

      // const response = await fetch("/api/auth/register", {
      //   method: REQUEST_METHOD.POST,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // }).then((res) => res.json());

      return response;
    } catch (e: any) {
      rejectWithValue(e.message);
    }
  }
);

export const updateProfileDetails = createAsyncThunk(
  "auth/updateProfileDetails",
  async (
    {
      data,
    }: {
      data: {
        first_name: string;
        last_name: string;
        contact_number: string;
        email: string;
      };
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "update-profile",
      })
    );
    try {
      const response = await apiRequestClient({
        url: "/api/account/profile",
        method: REQUEST_METHOD.PUT,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
      });

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "update-profile",
        })
      );

      return response;
    } catch (e: any) {
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "update-profile",
        })
      );
      rejectWithValue(e.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRegisterDetails: (state, action) => {
      state.register_details = action.payload;
    },
    setVerificationPending: (state, action) => {
      state.verification_pending = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setTokens.fulfilled, (state, action) => {
        state.access_token = action.payload?.access_token;
        state.refresh_token = action.payload?.refresh_token;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.access_token = null;
        state.refresh_token = null;
        state.customer_details = null;
        state.logged_in_user = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action: any) => {
        state.access_token = action.payload.access_token;
        state.refresh_token = action.payload.refresh_token;
        state.customer_details = action.payload.customer_details;
        state.logged_in_user = action.payload.customer_details;
      })

      .addCase(registerUser.fulfilled, (state, action) => {
        if (action.payload) {
          action.meta.arg.router.replace(API_ROUTES.LOGIN);
          state.customer_details = action.payload.result;
        }
        state.auth_state = STATUSES.IDLE;
      })

      .addMatcher(isAnyOf(registerUser.pending, loginUser.pending), (state) => {
        state.auth_state = STATUSES.LOADING;
      })
      .addMatcher(
        isAnyOf(registerUser.rejected, loginUser.rejected),
        (state) => {
          state.auth_state = STATUSES.ERROR;
        }
      )
      .addMatcher(isAnyOf(verifyOtp.fulfilled), (state, action: any) => {
        if (action.payload) {
          state.logged_in_user = action.payload.result.customer_details;
          state.auth_state = STATUSES.IDLE;
        }
      })
      .addMatcher(
        isAnyOf(
          registerUser.fulfilled,
          verifyOtp.fulfilled,
          loginUser.fulfilled
        ),
        (state, action: any) => {
          state.auth_state = STATUSES.IDLE;
        }
      );
  },
});

export const { setRegisterDetails, setVerificationPending } = authSlice.actions;

export default authSlice.reducer;
